import { StiggClient } from "@stigg/react-sdk";
import { getConfiguration } from "../../utils/getConfiguration";
import {
  CustomerResourcesQuery,
  CustomerResourcesQueryVariables,
  CustomerResourceFilter,
  SubscriptionStatus,
} from "../../generated/apiTypes";
import { initApolloClient } from "../../utils/apollo/apolloClient";
import { FETCH_CUSTOMER_SUBSCRIPTIONS_RESOURCES } from "../../utils/apollo/queries";

export const SITE_FREE_PLAN_ID = "plan-site-starter";

export type Site = {
  id: string;
  subscriptionId: string;
  planName: string;
};

export async function loadSites(
  searchTerm: string | undefined
): Promise<Site[]> {
  const client = await initApolloClient();
  const { customerId } = getConfiguration();
  const paging = { first: 10 };
  const filter: CustomerResourceFilter = {
    customer: {
      refId: {
        eq: customerId,
      },
    },
    resourceId: {
      iLike: `%${searchTerm}%`,
    },
    subscriptions: {
      status: { in: [SubscriptionStatus.Active, SubscriptionStatus.InTrial] },
    },
  };
  const res = await client.query<
    CustomerResourcesQuery,
    CustomerResourcesQueryVariables
  >({
    query: FETCH_CUSTOMER_SUBSCRIPTIONS_RESOURCES,
    fetchPolicy: "network-only",
    variables: {
      paging,
      filter,
    },
  });

  return res.data.customerResources.edges.map((x) => {
    const resource = x.node;
    return {
      id: resource.resourceId,
      subscriptionId: resource.subscriptions[0].subscriptionId,
      planName: resource.subscriptions[0].plan.displayName,
    };
  });
}

export async function loadSite(
  siteId: string,
  stigg: StiggClient
): Promise<Site | undefined> {
  try {
    const subscriptions = await stigg.getActiveSubscriptions({
      resourceId: siteId,
    });

    if (subscriptions.length === 0) {
      return undefined;
    }

    return {
      id: siteId,
      subscriptionId: subscriptions[0].id,
      planName: subscriptions[0].plan.displayName,
    };
  } catch (error: any) {
    // if site does not exist, it throws an error
    console.log(`got ${error.message}`);
    return undefined;
  }
}
