import { Site } from "../sitesLoader";
import React from "react";
import { StiggContext } from "../../../StiggContext";
import { CustomerPortalWrapper } from "../../customerPortal/CustomerPortalWrapper";
import { Container } from "@mui/material";

type SitePagesTabProps = {
  site: Site;
};

export const SiteCustomerPortalTab: React.FC<SitePagesTabProps> = ({
  site,
}) => {
  return (
    <StiggContext withCustomer>
      <Container maxWidth="lg">
        <CustomerPortalWrapper siteId={site.id} />
      </Container>
    </StiggContext>
  );
};
