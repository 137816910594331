import {
  Paywall as StiggPaywall,
  SubscribeIntentionType,
  OnPlanSelectedCallbackFn,
  BillableFeature,
} from "@stigg/react-sdk";

import { Plan, BillingPeriod, BillingModel } from "@stigg/js-client-sdk";

import axios from "../../utils/axios";
import { getConfiguration } from "../../utils/getConfiguration";
import { STEPS } from "./Paywall";
import {
  ConfirmationDialogProps,
  useConfirmationDialog,
} from "../../hooks/useConfirmationDialog";
import { useCountryCode } from "../../hooks/useCountryCode";
import Loader from "../Loader";
import React from "react";

export const CANCEL_SCHEDULED_UPDATES_DIALOG_PROPS: Omit<
  ConfirmationDialogProps,
  "callback"
> = {
  title: "Cancel scheduled subscription update",
  subTitle:
    "Are you sure that you want to cancel the scheduled update to your subscription?",
  confirmText: "Yes",
  cancelText: "No",
};

export function InlinePaywall({
  siteId,
  setTrialSubscription,
  setProcessingUpgrade,
  setCurrentBillingPeriod,
  setSelectedPlan,
  setCurrentStep,
  setBillableFeatures,
  showRecommendedPlan,
  recommendedPlanId,
  showAllPlans,
  onSuccessProvision,
  onUpgradeClick,
}: {
  siteId?: string;
  setCurrentStep?: (step: string) => void;
  setTrialSubscription?: (isTrial: boolean) => void;
  setProcessingUpgrade?: (processingUpgrade: boolean) => void;
  setCurrentBillingPeriod?: (billingPeriod: BillingPeriod) => void;
  setSelectedPlan?: (plan: Plan) => void;
  setBillableFeatures?: (
    billableFeatures: BillableFeature[] | undefined
  ) => void;
  showRecommendedPlan?: boolean;
  showAllPlans?: boolean;
  recommendedPlanId?: string | null;
  onSuccessProvision?: () => void;
  onUpgradeClick?: () => void;
}) {
  const { dialog, setDialogProps, setIsConfirmationDialogOpened } =
    useConfirmationDialog();
  const { countryCode, isLoadingCountryCode } = useCountryCode();
  const productId = siteId ? "product-site" : "product-revvenu";

  const onCancelScheduledUpdatesClick = async (subscriptionId: string) => {
    if (setProcessingUpgrade && setTrialSubscription) {
      setProcessingUpgrade(true);
      setTrialSubscription(false);
    }

    await axios.post<{} | null>("/api/cancel-subscription-scheduled-updates", {
      subscriptionId,
    });

    if (setProcessingUpgrade) {
      setProcessingUpgrade(false);
    }
    if (onSuccessProvision) {
      onSuccessProvision();
    }
  };

  const onStartTrial = async (
    plan: Plan,
    currentBillingPeriod: BillingPeriod
  ) => {
    if (setTrialSubscription && setProcessingUpgrade) {
      setTrialSubscription(true);
      setProcessingUpgrade(true);
    }
    const { customerId } = getConfiguration();
    const billableFeatures = plan.pricePoints
      .filter(
        (pricePoint) =>
          pricePoint.billingPeriod === currentBillingPeriod &&
          pricePoint.pricingModel === BillingModel.PerUnit &&
          pricePoint.feature
      )
      .map((pricePoint) => ({
        featureId: pricePoint.feature?.featureId,
        quantity: 5,
      }));

    await axios.post("/api/create-subscription", {
      planId: plan.id,
      customerId,
      resourceId: siteId,
      billingPeriod: currentBillingPeriod,
      billableFeatures,
    });

    if (setCurrentStep && setProcessingUpgrade) {
      setCurrentStep(STEPS.postCheckout);
      setProcessingUpgrade(false);
    }
    if (onSuccessProvision) {
      onSuccessProvision();
    }
  };

  const onPlanSelected: OnPlanSelectedCallbackFn = ({
    plan,
    customer,
    subscription,
    selectedBillingPeriod,
    intentionType,
    billableFeatures,
  }) => {
    if (!customer) {
      return;
    }
    if (
      intentionType === SubscribeIntentionType.CANCEL_SCHEDULED_UPDATES &&
      subscription?.id
    ) {
      setDialogProps({
        ...CANCEL_SCHEDULED_UPDATES_DIALOG_PROPS,
        callback: () => onCancelScheduledUpdatesClick(subscription.id),
      });

      setIsConfirmationDialogOpened(true);
      return;
    }
    if (setCurrentBillingPeriod) {
      setCurrentBillingPeriod(selectedBillingPeriod);
    }
    if (setSelectedPlan) {
      setSelectedPlan(plan);
    }
    if (setBillableFeatures) {
      setBillableFeatures(billableFeatures);
    }
    if (intentionType === SubscribeIntentionType.START_TRIAL) {
      return onStartTrial(plan, selectedBillingPeriod);
    }
    if (intentionType !== SubscribeIntentionType.REQUEST_CUSTOM_PLAN_ACCESS) {
      if (onUpgradeClick) {
        onUpgradeClick();
      }

      if (setCurrentStep) {
        setCurrentStep(STEPS.checkout);
      }
      return;
    }
  };

  return (
    <>
      {!isLoadingCountryCode ? (
        <StiggPaywall
          key={countryCode}
          billingCountryCode={countryCode}
          productId={productId}
          resourceId={siteId}
          onPlanSelected={(args) => {
            return onPlanSelected(args);
          }}
          highlightedPlanId={
            showRecommendedPlan && recommendedPlanId
              ? recommendedPlanId
              : undefined
          }
          showOnlyEligiblePlans={!showAllPlans}
        />
      ) : (
        <Loader />
      )}

      {dialog}
    </>
  );
}
