import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { getConfiguration } from "../getConfiguration";
import axios from "../axios";

const getAuthLink = (
  apiKey: string | undefined,
  dynamicData: { customerToken?: string | null }
) => {
  return new ApolloLink((operation, forward) => {
    const headers: Record<string, string | undefined> = {
      "X-API-KEY": apiKey,
      source: "demo-app",
    };

    // customer may be changed overtime, so fetching it before every query
    const { customerToken } = dynamicData;
    if (customerToken) {
      headers["X-CUSTOMER-KEY"] = customerToken;
    }

    operation.setContext({ headers });
    return forward(operation);
  });
};

function getApolloLink(baseUri: string | undefined) {
  return new HttpLink({
    uri: `${baseUri}/graphql`,
    fetch,
  });
}

export const initApolloClient = async () => {
  const { serverSdkKey } = getConfiguration();

  const baseUrl = await axios
    .get("/api/get-base-url")
    .then((response) => response.data.baseUrl);

  const authLink = getAuthLink(serverSdkKey || undefined, {});

  const httpLink = getApolloLink(baseUrl);

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    name: "demo-app",
  });
};
