import {
  createContext,
  ReactNode,
  useEffect,
  useReducer,
  useState,
} from "react";
import { initializeStiggClient } from "../stigg";
import { JWTContextType, ActionMap, AuthState, AuthUser } from "../types/auth";
import axios from "../utils/axios";
import pureAxios from "axios";
import { isValidToken, setSession } from "../utils/jwt";

const INITIALIZE = "INITIALIZE";
const INITIALIZE_CLIENT_GEO_DATA = "INITIALIZE_CLIENT_GEO_DATA";
const INVALID_CONFIGURATION = "INVALID_CONFIGURATION";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [INITIALIZE_CLIENT_GEO_DATA]: {
    ip?: string | null;
    countryCode?: string | null;
  };
  [INVALID_CONFIGURATION]: {
    invalidConfiguration: boolean;
  };
  [SIGN_IN]: {
    user: AuthUser;
  };
  [SIGN_OUT]: undefined;
};

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  clientGeoData: null,
  isLoadingClientGeoData: true,
  invalidConfiguration: false,
};

const JWTReducer = (
  state: AuthState,
  action: ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>]
) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        invalidConfiguration: false,
      };

    case INITIALIZE_CLIENT_GEO_DATA: {
      return {
        ...state,
        isLoadingClientGeoData: false,
        clientGeoData: {
          ip: action.payload.ip,
          countryCode: action.payload.countryCode,
        },
      };
    }

    case INVALID_CONFIGURATION:
      return {
        ...state,
        invalidConfiguration: true,
      };
    case "SIGN_IN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case "SIGN_OUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const [keyIsLoaded, setKeyIsLoaded] = useState(false);

  const search = window.location.search;

  useEffect(() => {
    const initialize = async () => {
      try {
        await initializeStiggClient();
        setKeyIsLoaded(true);
      } catch (error: any) {
        console.error(`Failed to initialize Stigg Client - ${error.message}`);
        dispatch({
          type: INVALID_CONFIGURATION,
          payload: {
            invalidConfiguration: true,
          },
        });
      }
    };

    void initialize();
  }, [search]);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const response = await axios.get("/api/auth/my-account");
          const { user } = response.data;

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };
    if (keyIsLoaded) {
      initialize();
    }
  }, [keyIsLoaded]);

  const signIn = async (email: string, password: string) => {
    const response = await axios.post("/api/auth/sign-in", {
      email,
      password,
    });
    const { accessToken, user } = response.data;

    setSession(accessToken);
    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });
  };

  useEffect(() => {
    const initClientGeoData = async () => {
      let clientGeoData: { ip?: string | null; countryCode?: string | null } =
        {};

      try {
        clientGeoData = (
          await pureAxios.get(
            "https://rgdei6glxaa3nns7l3ax5gocie0rxwbz.lambda-url.us-east-2.on.aws/"
          )
        )?.data;
      } catch (error) {
        console.log(error);
      } finally {
        console.log(`Fetched client geo data`, clientGeoData);

        dispatch({
          type: "INITIALIZE_CLIENT_GEO_DATA",
          payload: {
            ip: clientGeoData?.ip,
            countryCode: clientGeoData?.countryCode,
          },
        });
      }
    };

    initClientGeoData();
  }, [state.isAuthenticated]);

  const signOut = async () => {
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
