import Loader from "../../../components/Loader";
import {
  Button,
  Card as MuiCard,
  CardActionArea,
  CardActions,
  CardContent as MuiCardContent,
  Chip as MuiChip,
  Grid,
  Typography as MuiTypography,
} from "@mui/material";
import { Trash2 } from "react-feather";
import Paywall from "../../../components/paywall/Paywall";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Add as AddIcon } from "@mui/icons-material";
import getStiggClient from "../../../stigg";
import { getConfiguration } from "../../../utils/getConfiguration";
import axios from "../../../utils/axios";
import styled from "styled-components/macro";
import { spacing, SpacingProps } from "@mui/system";
import range from "lodash/range";
import { Site } from "../sitesLoader";
import { OptionsDropdown } from "../../../components/OptionsDropdown";

const FEATURE_PAGES_ID = "feature-site-pages";
const PAGES_FIXTURE = [
  { name: "Labor day promotion", description: "" },
  { name: "Product update digest", description: "" },
  { name: "Scheduled maintenance", description: "" },
  { name: "Privacy policy update", description: "" },
  { name: "Strategic partnership announcement", description: "" },
  { name: "See you'all in SaaStr", description: "" },
  { name: "Christmas promotion", description: "" },
  { name: "Upcoming pricing change", description: "" },
  { name: "Black Friday promotion", description: "" },
];

const generatePages = (count: number) =>
  range(0, count).map(
    (_, index) => PAGES_FIXTURE[index % PAGES_FIXTURE.length]
  );

interface TypographyProps extends SpacingProps {
  component?: string;
}

const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const Card = styled(MuiCard)(spacing);

const StyledAddPageCard = styled(Card)`
  height: 260px;
  background-color: #56a151;
  color: #ffffff;
`;

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
  min-height: 215px;
`;

const Chip = styled(styled(MuiChip)(spacing))<{
  color?: string;
  height?: number;
  width?: number;
}>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height || 20}px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;

type PageProps = {
  title: string;
  description: string;
  chip: JSX.Element;
  removePage: () => void;
};

const Page: React.FC<PageProps> = ({
  title,
  description,
  chip,
  removePage,
}) => {
  const [isRemoving, setIsRemoving] = useState(false);
  return (
    <Card sx={{ minHeight: 260 }}>
      {isRemoving ? (
        <MuiCardContent>
          <Loader />
        </MuiCardContent>
      ) : (
        <>
          <CardContent>
            <Grid container wrap="nowrap" justifyContent="space-between">
              <Grid item container flexDirection="column">
                <Grid item>
                  <Typography gutterBottom variant="h5" component="h2">
                    {title}
                  </Typography>
                </Grid>

                <Grid item>{chip}</Grid>
              </Grid>
              <Grid item>
                <OptionsDropdown
                  options={[
                    {
                      icon: Trash2,
                      text: "Delete",
                      onClick: () => {
                        setIsRemoving(true);
                        removePage();
                      },
                    },
                  ]}
                />
              </Grid>
            </Grid>

            <Typography mb={4} color="textSecondary" component="p">
              {description}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" color="primary">
              View page
            </Button>
          </CardActions>
        </>
      )}
    </Card>
  );
};

const AddPageIcon = styled(AddIcon)`
  font-size: 80px;
`;

const AddPage = ({
  onAddPage,
  showUpgradeChip,
  usageLimit,
  isDisabled,
}: {
  onAddPage: () => void;
  showUpgradeChip: boolean;
  usageLimit?: number;
  isDisabled: boolean;
}) => {
  const isReachedLimit = showUpgradeChip && !!usageLimit;
  return (
    <StyledAddPageCard sx={{ minHeight: 250 }}>
      <CardActionArea
        sx={{ height: "100%" }}
        disabled={isDisabled}
        onClick={onAddPage}
        data-testid={
          isReachedLimit
            ? "button-upgrade-to-create-page"
            : "button-create-page"
        }
      >
        <MuiCardContent>
          <Grid alignItems="center" justifyContent="center" textAlign="center">
            {isDisabled ? (
              <Loader />
            ) : (
              <>
                <AddPageIcon />
                <Typography variant="h5" component="h2" textAlign="center">
                  Create a new page
                </Typography>
                {isReachedLimit && (
                  <>
                    <Grid item pt={"32px"} pb={"16px"}>
                      <Typography color="#FFFFFF">
                        You've reached your plan limit of {usageLimit}{" "}
                        {usageLimit > 1 ? "pages " : "page "}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Chip
                        label="UPGRADE PLAN"
                        color="warning"
                        height={28}
                        mb={4}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </MuiCardContent>
      </CardActionArea>
    </StyledAddPageCard>
  );
};

const SITES_FALLBACK = {
  hasAccess: true,
  usageLimit: 10,
};

type SitePagesTabProps = {
  site: Site;
};

export const SitePagesTab: React.FC<SitePagesTabProps> = ({ site }) => {
  const [pageNum, setPageNum] = useState(0);
  const pages = useMemo(() => generatePages(pageNum), [pageNum]);
  const [paywallIsOpen, setPaywallIsOpen] = useState(false);
  const [showUpgradeChip, setShowUpgradeChip] = useState(false);
  const [usageLimit, setUsageLimit] = useState<number | undefined>(0);
  const [isAddingPage, setIsAddingPage] = useState(false);
  const [isRemovingPage, setIsRemovingPage] = useState(false);
  const stigg = getStiggClient();

  const reloadEntitlement = useCallback(async () => {
    const entitlement = stigg.getMeteredEntitlement({
      featureId: FEATURE_PAGES_ID,
      resourceId: site.id,
      options: {
        requestedUsage: 1,
        fallback: SITES_FALLBACK,
      },
    });
    setPageNum(entitlement.currentUsage);
    setUsageLimit(entitlement.usageLimit);
    setShowUpgradeChip(!entitlement.hasAccess);
  }, [stigg, site.id]);

  useEffect(() => {
    const getFeature = async () => {
      await reloadEntitlement();

      // open paywall for checkout success message
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get("checkoutSuccess")) {
        setPaywallIsOpen(true);
      }
    };
    void getFeature();
  }, [reloadEntitlement]);

  const removePage = async () => {
    if (isAddingPage || isRemovingPage) {
      return;
    }
    setIsRemovingPage(true);
    const { customerId } = getConfiguration();
    await axios.post("/api/decrement", {
      featureId: FEATURE_PAGES_ID,
      resourceId: site.id,
      customerId,
      value: 1,
    });
    await stigg.refresh();
    await reloadEntitlement();
    setIsRemovingPage(false);
  };

  const addPage = async () => {
    if (isAddingPage || isRemovingPage) {
      return;
    }

    setIsAddingPage(true);
    if (showUpgradeChip && !!usageLimit) {
      setPaywallIsOpen(true);
    } else {
      const { customerId } = getConfiguration();
      await axios.post("/api/increment", {
        featureId: FEATURE_PAGES_ID,
        resourceId: site.id,
        customerId,
        value: 1,
      });
      await stigg.refresh();
      await reloadEntitlement();
    }
    setIsAddingPage(false);
  };

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12} md={4} lg={6} xl={3}>
          <AddPage
            onAddPage={addPage}
            showUpgradeChip={showUpgradeChip}
            usageLimit={usageLimit}
            isDisabled={isAddingPage}
          />
        </Grid>

        {pages.map(({ name, description }, i) => {
          return (
            <Grid item xs={12} lg={6} xl={3} key={`${i}:${pages.length}`}>
              <Page
                title={name}
                description={description}
                chip={<Chip label="Active" color="success" mb={4} />}
                removePage={removePage}
              />
            </Grid>
          );
        })}
      </Grid>

      <Paywall
        siteId={site.id}
        paywallIsOpen={paywallIsOpen}
        setPaywallIsOpen={setPaywallIsOpen}
        onUpgradeSuccess={() => {
          window.location.href = window.location.pathname;
        }}
        redirectAfterExit={false}
        paywallTitle="Upgrade your plan"
        paywallSubtitle="You've reached your pages limit"
        showAllPlans
      />
    </>
  );
};
