import React, { useEffect, useState } from "react";
import {
  BillableFeature,
  CustomerPortal as StiggCustomerPortal,
  Plan,
  PricingType,
  useStiggContext,
} from "@stigg/react-sdk";
import Paywall from "../../components/paywall/Paywall";
import {
  CANCEL_SCHEDULED_UPDATES_DIALOG_PROPS,
  InlinePaywall,
} from "../../components/paywall/InlinePaywall";
import axios from "../../utils/axios";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";
import { CheckoutDialog } from "../../components/paywall/InlineCheckout";
import { BillingPeriod } from "@stigg/js-client-sdk";

type CustomerPortalProps = {
  siteId?: string;
};

export function CustomerPortalWrapper({ siteId }: CustomerPortalProps) {
  const { dialog, setDialogProps, setIsConfirmationDialogOpened } =
    useConfirmationDialog();
  const [paywallIsOpen, setPaywallIsOpen] = useState(false);
  const { refreshData, stigg, customerPortalUpdatedAt } = useStiggContext();

  useEffect(() => {
    // open paywall for checkout success message
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("checkoutSuccess")) {
      setPaywallIsOpen(true);
    }
  }, []);

  const onCancelScheduledUpdatesClick = async (subscriptionId: string) => {
    await axios.post<{} | null>("/api/cancel-subscription-scheduled-updates", {
      subscriptionId,
    });

    // Refresh page
    void refreshData();
  };

  const [showCheckout, setShowCheckout] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [currentBillingPeriod, setCurrentBillingPeriod] = useState(
    BillingPeriod.Annually
  );
  const [billableFeatures, setBillableFeatures] = useState<
    BillableFeature[] | undefined
  >();

  const onManageSubscription = () => {
    setShowCheckout(true);
  };

  useEffect(() => {
    stigg
      .getActiveSubscriptions({ resourceId: siteId })
      .then((subscriptions) => {
        const currentSubscription = subscriptions[0];
        if (currentSubscription.pricingType === PricingType.Paid) {
          setSelectedPlan(currentSubscription?.plan);
        }
      });
  }, [stigg, siteId, customerPortalUpdatedAt]);

  return (
    <>
      <CheckoutDialog
        siteId={siteId}
        isOpen={showCheckout && !!selectedPlan}
        onClose={() => {
          setShowCheckout(false);
        }}
        planId={selectedPlan?.id}
        preferredBillingPeriod={currentBillingPeriod}
        billableFeatures={billableFeatures}
        onSuccessProvision={() => {
          setShowCheckout(false);
          refreshData();
        }}
        onChangePlan={() => {
          setShowCheckout(false);
        }}
      />
      <StiggCustomerPortal
        resourceId={siteId}
        onCancelScheduledUpdates={(subscription) => {
          setDialogProps({
            ...CANCEL_SCHEDULED_UPDATES_DIALOG_PROPS,
            callback: () =>
              onCancelScheduledUpdatesClick(subscription.subscriptionId),
          });

          setIsConfirmationDialogOpened(true);
        }}
        onManageSubscription={onManageSubscription}
        paywallComponent={
          <InlinePaywall
            siteId={siteId}
            showAllPlans
            onSuccessProvision={() => {
              refreshData();
            }}
            setCurrentBillingPeriod={setCurrentBillingPeriod}
            setSelectedPlan={setSelectedPlan}
            setBillableFeatures={setBillableFeatures}
            onUpgradeClick={() => {
              setShowCheckout(true);
            }}
          />
        }
      />

      {dialog}
      <Paywall
        paywallIsOpen={paywallIsOpen}
        setPaywallIsOpen={(isOpen) => {
          setPaywallIsOpen(isOpen);
        }}
        onUpgradeSuccess={() => {
          refreshData();
        }}
        redirectAfterExit={false}
        paywallTitle="Choose a plan"
        paywallSubtitle="Choose the right plan for your needs"
        showAllPlans
        showCancelPlan
        showRecommendedPlan={false}
      />
    </>
  );
}
