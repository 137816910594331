/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faChartLine,
  faBell,
  faHandHoldingUsd,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";
import { Paywall } from "@stigg/react-sdk";
import {
  faRocketchat,
  faFacebookSquare,
  faInstagramSquare,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Fab, Box } from "@mui/material";

import Logo from "./images/Rev Logo - green.svg";
import Mockrocket from "./images/mockrocket-capture.png";
import ServicesIcon1 from "./images/services-icon1.png";
import ServicesIcon2 from "./images/services-icon2.png";
import ServicesIcon3 from "./images/services-icon3.png";
import LinkIcon from "./images/link.svg";
import MapIcon from "./images/map-2.svg";
import CommentIcon from "./images/comment.svg";
import { Style } from "./css/style";
import { StiggContext } from "../../StiggContext";
import { useCountryCode } from "../../hooks/useCountryCode";
import Loader from "../../components/Loader";

export const LandingPage = () => {
  const { countryCode, isLoadingCountryCode } = useCountryCode();

  const handleNavbarClick = (e: any) => {
    e.preventDefault();
    const full_url = e?.target?.href;
    const parts = full_url.split("#");
    const trgt = parts[1];
    const target_top = !!trgt
      ? // @ts-ignore
        document.querySelector("#" + trgt).offsetTop
      : 0;
    window.scrollTo({
      top: target_top,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Style>
        <header className="header">
          <nav className="navbar navbar-default navbar-fixed-top opaque">
            <div className="container">
              <nav className="navbar navbar-expand-lg">
                <a href="/">
                  <img src={Logo} alt="" />
                </a>

                <div className="navbar-collapse collapse" id="navbarCollapse">
                  <ul className="navbar-nav ml-auto">
                    <li>
                      <a href="#home" onClick={handleNavbarClick}>
                        Home
                      </a>
                    </li>

                    <li>
                      <a href="#pricing" onClick={handleNavbarClick}>
                        Pricing
                      </a>
                    </li>

                    <li className="discover-link">
                      <a href="/" className="external discover-btn">
                        Get Started
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </nav>
        </header>

        <section className="home-section" id="home">
          <div className="container">
            <div className="row">
              <div className="col-md-6 padding-top-60">
                <h1>All you need to power your online store.</h1>

                <p>
                  Start using Revve.nu today and track all your data in one
                  central location. There are no limits to how you can look at
                  all your data.
                </p>

                <a href="/" className="btn-white scrool">
                  Get started
                </a>
              </div>

              <div className="col-md-6">
                <img
                  src={Mockrocket}
                  className="width-100 hero-images"
                  alt="pic"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="section-white section-top-border" id="about">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2 className="section-title">
                  Integrated solution for your business
                </h2>

                <p className="section-subtitle">
                  Communicate with flexible tools that go where your team goes.
                </p>
              </div>
            </div>
          </div>

          <div className="services-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <div className="main-services">
                    <img src={ServicesIcon2} alt="pic" />

                    <h3>Templates&nbsp;</h3>

                    <p>Create eye-popping re-usable templates with ease</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="main-services">
                    <img src={ServicesIcon1} alt="pic" />

                    <h3>Campaigns</h3>

                    <p>Schedule multi-channel marketing campaigns</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="main-services">
                    <img src={ServicesIcon3} alt="pic" />

                    <h3>Users</h3>

                    <p>Delegate permissions to additional team members</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Style>

      <section
        className="section-white section-top-border"
        id="pricing"
        style={{ marginBottom: 70 }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center padding-bottom-40">
              <h2
                className="section-title"
                style={{
                  marginBottom: 30,
                  color: "#0b3704",
                  fontSize: 36,
                  fontFamily: "Lato, sans-serif",
                }}
              >
                Pricing for every business, at any stage
              </h2>
            </div>
          </div>
        </div>

        <div className="pricing-wrapper">
          <div className="container">
            <StiggContext withCustomer={false}>
              {!isLoadingCountryCode ? (
                <Paywall
                  billingCountryCode={countryCode}
                  onPlanSelected={() => {}}
                  highlightedPlanId="plan-revvenu-essentials"
                  textOverrides={{
                    planCTAButton: {
                      upgrade: "Get started",
                    },
                  }}
                />
              ) : (
                <Loader />
              )}
            </StiggContext>
          </div>
        </div>
      </section>

      <Style>
        <section className="section-grey section-bottom-border" id="features">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center padding-bottom-10">
                <h2 className="section-title">Amazing Features</h2>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div
                  className="feature-box wow fadeIn"
                  data-wow-delay="0.25s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.25s",
                    animationName: "fadeIn",
                  }}
                >
                  <FontAwesomeIcon icon={faTachometerAlt} />

                  <h4>Powerful Dashboard</h4>
                </div>
              </div>

              <div className="col-md-4">
                <div
                  className="feature-box wow fadeIn"
                  data-wow-delay="0.5s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.5s",
                    animationName: "fadeIn",
                  }}
                >
                  <FontAwesomeIcon icon={faChartLine} />

                  <h4>User Friendly</h4>
                </div>
              </div>

              <div className="col-md-4">
                <div
                  className="feature-box wow fadeIn"
                  data-wow-delay="0.75s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.75s",
                    animationName: "fadeIn",
                  }}
                >
                  <FontAwesomeIcon icon={faBell} />

                  <h4>Smart Notifications</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div
                  className="feature-box wow fadeIn"
                  data-wow-delay="1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "1s",
                    animationName: "fadeIn",
                  }}
                >
                  <FontAwesomeIcon icon={faHandHoldingUsd} />

                  <h4>Cost Control</h4>
                </div>
              </div>

              <div className="col-md-4">
                <div
                  className="feature-box wow fadeIn"
                  data-wow-delay="1.25s"
                  style={{
                    visibility: "visible",
                    animationDelay: "1.25s",
                    animationName: "fadeIn",
                  }}
                >
                  <FontAwesomeIcon icon={faRocket} />

                  <h4>Unique Features</h4>
                </div>
              </div>

              <div className="col-md-4">
                <div
                  className="feature-box wow fadeIn"
                  data-wow-delay="1.5s"
                  style={{
                    visibility: "visible",
                    animationDelay: "1.5s",
                    animationName: "fadeIn",
                  }}
                >
                  <FontAwesomeIcon icon={faRocketchat} />

                  <h4>24/7 Support</h4>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-blue" id="features">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center padding-bottom-10">
                <h3 className="section-title white-text">
                  Ready to boost your sales?
                </h3>

                <a href="/" className="btn-white scrool">
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </section>

        <div className="footer">
          <div className="container-fluid px-0">
            <div className="row no-gutters mx-0">
              <div className="col-md-4 footer-white-box text-center">
                <img src={MapIcon} alt="MapIcon" />

                <i className="pe-7s-map-2"></i>

                <h5>Get In Touch</h5>

                <p>235 W 22nd St, New York, NY 10011, USA</p>

                <p>
                  <a href="mailto:contact@youremail.com">hello@revve.nu</a>
                </p>

                <p>(212) 555 3295</p>
              </div>

              <div className="col-md-4 footer-dark-grey-box text-center">
                <img src={CommentIcon} alt="CommentIcon" />

                <h5>Social Media</h5>

                <ul className="footer_social">
                  <li>
                    <a href="#footer">
                      <Box mr={8}>
                        <Fab>
                          <FontAwesomeIcon icon={faTwitter} />
                        </Fab>
                      </Box>
                    </a>
                  </li>

                  <li>
                    <a href="#footer">
                      <Box mr={8}>
                        <Fab>
                          <FontAwesomeIcon icon={faInstagramSquare} />
                        </Fab>
                      </Box>
                    </a>
                  </li>

                  <li>
                    <a href="#footer">
                      <Box>
                        <Fab>
                          <FontAwesomeIcon icon={faFacebookSquare} />
                        </Fab>
                      </Box>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-md-4 footer-grey-box text-center">
                <img src={LinkIcon} alt="LinkIcon" />

                <h5>Useful Links</h5>

                <a href="#footer" className="footer-links">
                  Team
                </a>

                <a href="#footer" className="footer-links">
                  Terms
                </a>

                <a href="#footer" className="footer-links">
                  Privacy policy
                </a>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 footer-bottom text-center">
                <p>
                  Copyright © {new Date().getFullYear()}{" "}
                  <strong>Revve.nu</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Style>
    </>
  );
};
