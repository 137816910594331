import { Grid, Link, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BillingPeriod, BillingModel, Customer } from "@stigg/js-client-sdk";
import getStiggClient from "../stigg";
import { diffInDays } from "../utils/trialUtils";
import { Timer } from "@mui/icons-material";
import Paywall from "./paywall/Paywall";

function UpgradeBanner() {
  const [paywallIsOpen, setPaywallIsOpen] = useState(false);

  const [customer, setCustomer] = useState<Customer | undefined>();

  useEffect(() => {
    const getCustomer = async () => {
      const stigg = getStiggClient();
      setCustomer(await stigg.getCustomer());
    };
    getCustomer();
  }, []);
  const trialSubscriptions = customer?.getActiveTrials();
  if (!trialSubscriptions?.length) {
    return null;
  }
  const trialSubscription = trialSubscriptions[0];
  const daysLeft = diffInDays(trialSubscription.trialEndDate);

  // we currently choose the first price point,
  // a more correct logic in the future should be to select the price point with the lowest price
  const pricePoint = trialSubscription.plan.pricePoints[0];

  let priceString: string | undefined = undefined;
  if (pricePoint) {
    const priceBillingPeriod = `${
      pricePoint.billingPeriod === BillingPeriod.Monthly ? "month" : "year"
    }`;
    if (pricePoint.pricingModel === BillingModel.UsageBased) {
      priceString = `$${pricePoint.amount} / ${
        pricePoint.feature?.units || ""
      }`;
    } else if (pricePoint.pricingModel === BillingModel.PerUnit) {
      priceString = `$${pricePoint.amount} / ${
        pricePoint.feature?.units || ""
      } / ${priceBillingPeriod}`;
    } else {
      priceString = `$${pricePoint.amount} / ${priceBillingPeriod}`;
    }
  }

  return (
    <>
      <Paywall
        paywallIsOpen={paywallIsOpen}
        setPaywallIsOpen={setPaywallIsOpen}
        onUpgradeSuccess={() => {
          window.location.href = window.location.pathname;
        }}
        redirectAfterExit={true}
        paywallTitle="Upgrade now"
        paywallSubtitle="Don't lose access to premium features when your trial ends"
        showAllPlans
      />

      <Grid
        color={"#FFFFFF"}
        bgcolor={"#FF9800"}
        container
        height={43}
        rowSpacing={2}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid item pr={2} mt={1}>
          <Timer />
        </Grid>
        <Grid item mr={1}>
          <Typography
            sx={{ fontWeight: "bold" }}
          >{`You have ${daysLeft} days left to try the ${trialSubscription.plan.displayName} plan. `}</Typography>
        </Grid>
        <Grid item>
          <Link
            sx={{ cursor: "pointer", color: "#FFFFFF" }}
            underline="always"
            onClick={() => {
              setPaywallIsOpen(true);
            }}
          >
            Upgrade&nbsp;
          </Link>
        </Grid>
        {priceString && (
          <Grid item>
            <Typography>{`any time for as low as ${priceString}`}</Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default UpgradeBanner;
