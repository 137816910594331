import { gql } from "@apollo/client";

export const FETCH_CUSTOMER_SUBSCRIPTIONS_RESOURCES = gql`
  query CustomerResources(
    $paging: CursorPaging
    $filter: CustomerResourceFilter
  ) {
    customerResources(paging: $paging, filter: $filter) {
      edges {
        node {
          ...CustomerResourceFragment
        }
      }
    }
  }
  fragment CustomerResourceFragment on CustomerResource {
    resourceId
    subscriptions {
      subscriptionId
      plan {
        displayName
        product {
          displayName
        }
      }
    }
  }
`;
