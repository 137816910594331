import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Button,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  TextField,
  Typography as MuiTypography,
} from "@mui/material";
import { Box, spacing, SpacingProps } from "@mui/system";
import axios from "../../utils/axios";
import { getConfiguration } from "../../utils/getConfiguration";
import SitePreview from "./SitePreview";
import { CreateSiteDialog } from "./CreateSiteDialog";
import { loadSites, Site, SITE_FREE_PLAN_ID } from "./sitesLoader";

const Divider = styled(MuiDivider)(spacing);

interface TypographyProps extends SpacingProps {
  component?: string;
}

const Typography = styled(MuiTypography)<TypographyProps>(spacing);

function Sites() {
  const [sites, setSites] = useState<Site[]>([]);
  const [isLoadingSites, setIsLoadingSites] = useState(true);
  const [isAddingSite, setIsAddingSite] = useState(false);
  const [isRemovingSite, setIsRemovingSite] = useState(false);
  const [isAddingSiteDialogOpen, setIsAddingSiteDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const triggerLoadSites = useCallback(async () => {
    const newSites = await loadSites(searchTerm);
    setSites(newSites);
    setIsLoadingSites(false);
  }, [searchTerm]);

  useEffect(() => {
    void triggerLoadSites();
  }, [triggerLoadSites]);

  const removeSite = async (subscriptionId: string) => {
    if (isAddingSite || isRemovingSite) {
      return;
    }
    setIsRemovingSite(true);
    await axios.post("/api/cancel", {
      subscriptionIdToCancel: subscriptionId,
      isImmediate: true,
    });
    await triggerLoadSites();
    setIsRemovingSite(false);
  };

  const addSite = async (siteId: string) => {
    if (isAddingSite || isRemovingSite) {
      return;
    }

    setIsAddingSite(true);

    const { customerId } = getConfiguration();
    await axios.post("/api/create-subscription", {
      planId: SITE_FREE_PLAN_ID,
      customerId,
      resourceId: siteId,
    });

    await triggerLoadSites();

    setIsAddingSite(false);
  };
  if (isLoadingSites) {
    return (
      <Box display="flex" justifyContent="center" my={6}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <React.Fragment>
      <Helmet title="Sites" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Sites
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsAddingSiteDialogOpen(true)}
            data-testid="button-create-site"
          >
            Create new site
          </Button>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid
        container
        flexDirection="column"
        display="flex"
        alignContent="start"
      >
        <TextField
          value={searchTerm}
          placeholder="Search by site ID"
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
          sx={{ width: 250 }}
        />
      </Grid>

      <Grid container spacing={6} my={6}>
        {sites.map(({ id, subscriptionId, planName }, i) => {
          return (
            <Grid item key={`${i}:${sites.length}`}>
              <SitePreview
                siteId={id}
                planName={planName}
                removeSite={() => removeSite(subscriptionId)}
              />
            </Grid>
          );
        })}
      </Grid>

      <CreateSiteDialog
        isOpen={isAddingSiteDialogOpen}
        closeDialog={() => setIsAddingSiteDialogOpen(false)}
        onAddSite={addSite}
        existingSites={sites}
      />
    </React.Fragment>
  );
}

export default Sites;
