import React, { useState } from "react";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import {
  Container,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { CustomerPortalWrapper } from "./CustomerPortalWrapper";

const Divider = styled(MuiDivider)(spacing);

enum Tabs {
  Plans = "Plans & billing",
  Notifications = "Notifications",
}

export function CustomerPortalPage() {
  const [value, setValue] = useState(Tabs.Plans);

  const handleChange = (event: React.SyntheticEvent, newValue: Tabs) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="lg">
      <Helmet title="Account settings" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" mb={2}>
            Manage your account
          </Typography>
        </Grid>
      </Grid>

      <TabContext value={value}>
        <TabList onChange={handleChange}>
          {Object.values(Tabs).map((x) => (
            <Tab label={x} value={x} key={x} disabled={x !== Tabs.Plans} />
          ))}
        </TabList>
        <Divider mb={7} />
        <TabPanel value={Tabs.Plans}>
          <CustomerPortalWrapper />
        </TabPanel>
        <TabPanel value={Tabs.Notifications} />
      </TabContext>
    </Container>
  );
}
