import moment from "moment";

export const diffInDays = (endDate?: Date | null): number => {
  if (!endDate) {
    return 0;
  }

  // + 1 intended to include today's date
  return moment(endDate).diff(moment(new Date()), "days", false) + 1;
};
