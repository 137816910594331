import {
  BillableFeature,
  Checkout as StiggCheckout,
  CheckoutStatePlan,
  useStiggContext,
  CheckoutTheme,
} from "@stigg/react-sdk";
import { StiggContext } from "../../StiggContext";
import { OnCheckoutCompletedParams } from "@stigg/react-sdk/dist/components/checkout/CheckoutContainer";
import { STEPS } from "./Paywall";
import { BillingPeriod } from "@stigg/js-client-sdk";
import { DeepPartial } from "@stigg/react-sdk/dist/types";
import {
  Dialog,
  DialogTitle,
  IconButton as MuiIconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import { Close as CloseIcon } from "@mui/icons-material";
import "@stigg/react-sdk/dist/styles.css";
import styled from "styled-components";
import { useCountryCode } from "../../hooks/useCountryCode";
import Loader from "../Loader";

const StyledStiggCheckout = styled.div`
  .stigg-checkout-layout {
    border-color: transparent;
    padding: 64px 16px 16px 16px;
    min-height: 600px;
  }
`;

export const CheckoutDialog = ({
  isOpen,
  onClose,
  planId,
  preferredBillingPeriod,
  billableFeatures,
  onSuccessProvision,
  onChangePlan,
  siteId,
}: {
  isOpen: boolean;
  onClose: () => void;
  planId?: string;
  preferredBillingPeriod?: BillingPeriod;
  billableFeatures?: BillableFeature[];
  onSuccessProvision?: () => void;
  onChangePlan?: () => void;
  siteId?: string;
}) => {
  const { countryCode, isLoadingCountryCode } = useCountryCode();

  const checkoutComponent = (
    <>
      {planId && (
        <>
          {!isLoadingCountryCode ? (
            <InlineCheckout
              siteId={siteId}
              planId={planId}
              countryCode={countryCode}
              preferredBillingPeriod={preferredBillingPeriod}
              billableFeatures={billableFeatures}
              onSuccessProvision={onSuccessProvision}
              onChangePlan={onChangePlan}
            />
          ) : (
            <Loader />
          )}
        </>
      )}
    </>
  );
  return (
    <>
      {planId && (
        <Dialog
          open={isOpen}
          fullWidth={true}
          PaperProps={{
            sx: {
              minWidth: 900,
            },
          }}
        >
          <DialogTitle
            sx={{
              color: "#001E6c",
              display: "flex",
              justifyContent: "end",
              position: "absolute",
              right: 0,
              padding: 0,
              zIndex: 1,
            }}
          >
            <MuiIconButton onClick={() => onClose()} sx={{ margin: "8px" }}>
              <CloseIcon />
            </MuiIconButton>
          </DialogTitle>
          <Box justifyContent="center" alignItems="center" height="100%">
            <StyledStiggCheckout>
              <StiggContext>{checkoutComponent}</StiggContext>
            </StyledStiggCheckout>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export const InlineCheckout = ({
  planId,
  countryCode,
  preferredBillingPeriod,
  billableFeatures,
  theme,
  setCurrentStep,
  onSuccessProvision,
  onChangePlan,
  siteId,
}: {
  planId: string;
  siteId?: string;
  countryCode?: string;
  preferredBillingPeriod?: BillingPeriod;
  billableFeatures?: BillableFeature[];
  theme?: DeepPartial<CheckoutTheme>;
  setCurrentStep?: (step: string) => void;
  onSuccessProvision?: () => void;
  onChangePlan?: (params: {
    currentPlan: CheckoutStatePlan | undefined;
  }) => void;
}) => {
  const { refreshData } = useStiggContext();

  const onCheckoutCompleted = async (params: OnCheckoutCompletedParams) => {
    if (params.success) {
      if (setCurrentStep) {
        await setCurrentStep(STEPS.postCheckout);
      }
      if (onSuccessProvision) {
        onSuccessProvision();
      }

      await refreshData();
    } else {
      console.log("Checkout failed", params.error);
    }
  };

  return (
    <StiggCheckout
      resourceId={siteId}
      planId={planId}
      billingCountryCode={countryCode}
      preferredBillingPeriod={preferredBillingPeriod}
      billableFeatures={billableFeatures}
      theme={theme}
      onCheckoutCompleted={onCheckoutCompleted}
      onChangePlan={onChangePlan}
    />
  );
};
