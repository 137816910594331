import React, { useState } from "react";
import styled from "styled-components/macro";
import {
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { Site } from "../sitesLoader";
import { Helmet } from "react-helmet-async";
import { TabContext, TabList } from "@mui/lab";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import { SitePagesTab } from "./SitePagesTab";
import { SiteCustomerPortalTab } from "./SiteCustomerPortalTab";

const Divider = styled(MuiDivider)(spacing);

interface TypographyProps extends SpacingProps {
  component?: string;
}

const Typography = styled(MuiTypography)<TypographyProps>(spacing);

type SingleSiteInternalProps = {
  site: Site;
};

enum Tabs {
  PAGES = "PAGES",
  CUSTOMER_PORTAL = "CUSTOMER_PORTAL",
}

const TabsList = [
  { title: "Pages", key: Tabs.PAGES },
  { title: "Plans & billing", key: Tabs.CUSTOMER_PORTAL },
];

const SiteContent: React.FC<SingleSiteInternalProps> = ({ site }) => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(Tabs.PAGES);
  const handleTabChange = (event: React.SyntheticEvent, newValue: Tabs) => {
    setCurrentTab(newValue);
  };

  return (
    <React.Fragment>
      <Helmet title="Sites" />
      <Grid mb={6}>
        <Link
          typography="h3"
          onClick={() => navigate("/sites")}
          mr={2}
          style={{ cursor: "pointer" }}
        >
          Sites
        </Link>
        <Typography variant="h3" gutterBottom display="inline" mr={2}>
          /
        </Typography>
        <Typography variant="h3" gutterBottom display="inline" mr={2}>
          {site.id}
        </Typography>
      </Grid>

      <TabContext value={currentTab}>
        <TabList onChange={handleTabChange}>
          {TabsList.map(({ title, key }) => (
            <Tab
              label={title}
              value={key}
              key={key}
              data-testid={`navigation-site-tab-${key.toLowerCase()}`}
            />
          ))}
        </TabList>
        <Divider mb={7} />
        <TabPanel value={Tabs.PAGES}>
          <SitePagesTab site={site} />
        </TabPanel>
        <TabPanel value={Tabs.CUSTOMER_PORTAL}>
          <SiteCustomerPortalTab site={site} />
        </TabPanel>
      </TabContext>
    </React.Fragment>
  );
};

export default SiteContent;
