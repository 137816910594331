import React, { useEffect, useState } from "react";
import getStiggClient from "../../../stigg";
import Loader from "../../../components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { loadSite, Site } from "../sitesLoader";
import SiteContent from "./SiteContent";
import { Button, Grid, Typography as MuiTypography } from "@mui/material";
import { SkipBack } from "react-feather";
import styled from "styled-components/macro";
import { spacing, TypographyProps } from "@mui/system";

const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const SingleSite: React.FC = () => {
  const { siteId } = useParams();
  const navigate = useNavigate();
  const [site, setSite] = useState<Site | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const stigg = getStiggClient();

  useEffect(() => {
    const fetchSite = async () => {
      if (!siteId) {
        return;
      }

      setIsLoading(true);
      try {
        await stigg.setResource(siteId);
      } catch (e) {}
      const site = await loadSite(siteId, stigg);
      setSite(site);
      setIsLoading(false);
    };

    void fetchSite();

    return () => {
      void stigg.clearResource();
    };
  }, [siteId, stigg]);

  if (isLoading) {
    return <Loader />;
  }

  if (!site) {
    return (
      <Grid textAlign="center" mt={"20%"}>
        <Typography gutterBottom variant="h2" mb={8}>
          Site not found
        </Typography>
        <Button onClick={() => navigate("/sites")}>
          <SkipBack /> Back to sites
        </Button>
      </Grid>
    );
  }

  return <SiteContent site={site} />;
};

export default SingleSite;
