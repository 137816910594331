import { useState, useEffect, Dispatch, SetStateAction } from "react";

export const LOCAL_STORAGE_USER_ID_KEY = "revvenu-user-id";

function getStorageValue(key: string, defaultValue: string) {
  const saved = localStorage.getItem(key);
  return saved ? JSON.parse(saved) : defaultValue;
}

export const useLocalStorage = (
  key: string,
  defaultValue: string
): [string, Dispatch<SetStateAction<string>>] => {
  const [value, setValue] = useState<string>(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
