import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Card as MuiCard,
  CardActionArea,
  CardActions,
  CardContent as MuiCardContent,
  Chip as MuiChip,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  Typography as MuiTypography,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { Box, spacing, SpacingProps } from "@mui/system";
import range from "lodash/range";
import Paywall from "../../components/paywall/Paywall";
import getStiggClient from "../../stigg";
import axios from "../../utils/axios";
import { getConfiguration } from "../../utils/getConfiguration";
import { OptionsDropdown } from "../../components/OptionsDropdown";
import { Trash2 } from "react-feather";
import Loader from "../../components/Loader";

const FEATURE_NAME = "feature-01-templates";
const STORES_FIXTURE = [
  { name: "Labor day promotion", description: "" },
  { name: "Product update digest", description: "" },
  { name: "Scheduled maintenance", description: "" },
  { name: "Privacy policy update", description: "" },
  { name: "Strategic partnership announcement", description: "" },
  { name: "See you'all in SaaStr", description: "" },
  { name: "Christmas promotion", description: "" },
  { name: "Upcoming pricing change", description: "" },
  { name: "Black Friday promotion", description: "" },
];

const generateStores = (count: number) =>
  range(0, count).map(
    (_, index) => STORES_FIXTURE[index % STORES_FIXTURE.length]
  );

const Card = styled(MuiCard)(spacing);

const StyledAddCard = styled(Card)`
  height: 260px;
  background-color: #56a151;
  color: #ffffff;
`;

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
  min-height: 215px;
`;

const Divider = styled(MuiDivider)(spacing);

interface TypographyProps extends SpacingProps {
  component?: string;
}

const Typography = styled(MuiTypography)<TypographyProps>(spacing);

export const Chip = styled(styled(MuiChip)(spacing))<{
  color?: string;
  height?: number;
  width?: number;
}>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height || 20}px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;

type StoreProps = {
  title: string;
  description: string;
  chip: JSX.Element;
  removeStore: () => void;
};

const Store: React.FC<StoreProps> = ({
  title,
  description,
  chip,
  removeStore,
}) => {
  const [isRemoving, setIsRemoving] = useState(false);
  return (
    <Card sx={{ minHeight: 260 }}>
      {isRemoving ? (
        <MuiCardContent>
          <Loader />
        </MuiCardContent>
      ) : (
        <>
          <CardContent>
            <Grid container wrap="nowrap" justifyContent="space-between">
              <Grid item container flexDirection="column">
                <Grid item>
                  <Typography gutterBottom variant="h5" component="h2">
                    {title}
                  </Typography>
                </Grid>

                <Grid item>{chip}</Grid>
              </Grid>
              <Grid item>
                <OptionsDropdown
                  options={[
                    {
                      icon: Trash2,
                      text: "Delete",
                      onClick: () => {
                        setIsRemoving(true);
                        removeStore();
                      },
                    },
                  ]}
                />
              </Grid>
            </Grid>

            <Typography mb={4} color="textSecondary" component="p">
              {description}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" color="primary">
              View template
            </Button>
          </CardActions>
        </>
      )}
    </Card>
  );
};

const AddStoreIcon = styled(AddIcon)`
  font-size: 80px;
`;

const AddStore = ({
  onAddStore,
  showUpgradeChip,
  usageLimit,
  isDisabled = false,
}: {
  onAddStore: () => void;
  showUpgradeChip: boolean;
  usageLimit?: number;
  isDisabled?: boolean;
}) => {
  return (
    <StyledAddCard sx={{ minHeight: 250 }} onClick={onAddStore}>
      <CardActionArea sx={{ height: "100%" }} disabled={isDisabled}>
        <MuiCardContent>
          <Grid alignItems="center" justifyContent="center" textAlign="center">
            {isDisabled ? (
              <Loader />
            ) : (
              <>
                <AddStoreIcon />
                <Typography variant="h5" component="h2" textAlign="center">
                  Create a new template
                </Typography>
                {showUpgradeChip && usageLimit && (
                  <>
                    <Grid item pt={"32px"} pb={"16px"}>
                      <Typography color="#FFFFFF">
                        You've reached your plan limit of {usageLimit}{" "}
                        {usageLimit > 1 ? "templates " : "template "}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Chip
                        label="UPGRADE PLAN"
                        color="warning"
                        onClick={onAddStore}
                        height={28}
                        mb={4}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </MuiCardContent>
      </CardActionArea>
    </StyledAddCard>
  );
};

const STORES_FALLBACK = {
  hasAccess: true,
  usageLimit: 10,
};

function Stores() {
  const [storeNum, setStoreNum] = useState(0);

  const stores = useMemo(() => generateStores(storeNum), [storeNum]);
  const [paywallIsOpen, setPaywallIsOpen] = useState(false);
  const [showUpgradeChip, setShowUpgradeChip] = useState(false);
  const [isLoadingStores, setIsLoadingStores] = useState(true);
  const [usageLimit, setUsageLimit] = useState<number | undefined>(0);
  const [isAddingStore, setIsAddingStore] = useState(false);
  const [isRemovingStore, setIsRemovingStore] = useState(false);
  const stigg = getStiggClient();

  useEffect(() => {
    const getFeature = async () => {
      const entitlement = stigg.getMeteredEntitlement({
        featureId: FEATURE_NAME,
        options: {
          requestedUsage: 1,
          fallback: STORES_FALLBACK,
        },
      });
      setStoreNum(entitlement.currentUsage);
      setUsageLimit(entitlement.usageLimit);
      if (!entitlement.hasAccess) {
        setShowUpgradeChip(true);
      }
      setIsLoadingStores(false);

      // open paywall for checkout success message
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get("checkoutSuccess")) {
        setPaywallIsOpen(true);
      }
    };
    getFeature();
  }, [stigg]);

  const removeStore = async () => {
    if (isAddingStore || isRemovingStore) {
      return;
    }
    setIsRemovingStore(true);
    const { customerId } = getConfiguration();
    await axios.post("/api/decrement", {
      featureId: FEATURE_NAME,
      customerId,
      value: 1,
    });
    await stigg.refresh();
    const entitlement = stigg.getMeteredEntitlement({
      featureId: FEATURE_NAME,
      options: {
        requestedUsage: 1,
        fallback: STORES_FALLBACK,
      },
    });
    setStoreNum(entitlement.currentUsage);
    if (entitlement.hasAccess) {
      setShowUpgradeChip(false);
    }
    setIsRemovingStore(false);
  };

  const addStore = async () => {
    if (isAddingStore || isRemovingStore) {
      return;
    }

    setIsAddingStore(true);
    const entitlement = stigg.getMeteredEntitlement({
      featureId: FEATURE_NAME,
      options: {
        requestedUsage: 1,
        fallback: STORES_FALLBACK,
      },
    });

    if (entitlement.hasAccess) {
      const { customerId } = getConfiguration();
      await axios.post("/api/increment", {
        featureId: FEATURE_NAME,
        customerId,
        value: 1,
      });
      await stigg.refresh();
      const updatedEntitlement = stigg.getMeteredEntitlement({
        featureId: FEATURE_NAME,
        options: { fallback: STORES_FALLBACK },
      });
      setUsageLimit(updatedEntitlement?.usageLimit || 0);
      if (!updatedEntitlement.hasAccess) {
        setShowUpgradeChip(true);
      }
      setStoreNum(storeNum + 1);
    } else {
      setPaywallIsOpen(true);
    }
    setIsAddingStore(false);
  };
  if (isLoadingStores) {
    return (
      <Box display="flex" justifyContent="center" my={6}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <React.Fragment>
      <Helmet title="Templates" />
      <Paywall
        paywallIsOpen={paywallIsOpen}
        setPaywallIsOpen={setPaywallIsOpen}
        onUpgradeSuccess={() => {
          window.location.href = window.location.pathname;
        }}
        redirectAfterExit={false}
        paywallTitle="Upgrade your plan"
        paywallSubtitle="You've reached your template limit"
        showAllPlans
      />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Templates
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} md={4} lg={6} xl={3}>
          <AddStore
            onAddStore={addStore}
            showUpgradeChip={showUpgradeChip}
            usageLimit={usageLimit}
            isDisabled={isAddingStore}
          />
        </Grid>

        {stores.map(({ name, description }, i) => {
          return (
            <Grid item xs={12} lg={6} xl={3} key={`${i}:${stores.length}`}>
              <Store
                title={name}
                description={description}
                chip={<Chip label="Active" color="success" mb={4} />}
                removeStore={removeStore}
              />
            </Grid>
          );
        })}
      </Grid>
    </React.Fragment>
  );
}

export default Stores;
