import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "styled-components/macro";
import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import "./i18n";
import createTheme from "./theme";
import { AuthProvider } from "./contexts/JWTContext";
import { ThemeProvider as ThemeContextProvider } from "./contexts/ThemeContext";
import AppRouter from "./pages/AppRouter";

function App() {
  return (
    <ThemeContextProvider>
      <HelmetProvider>
        <Helmet titleTemplate="%s | Revvenu" defaultTitle="Revvenu" />
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={createTheme()}>
            <ThemeProvider theme={createTheme()}>
              <AuthProvider>
                <AppRouter />
              </AuthProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </HelmetProvider>
    </ThemeContextProvider>
  );
}

export default App;
