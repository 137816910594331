import React, { useEffect, useState } from "react";
import { StiggProvider } from "@stigg/react-sdk";
import axios from "./utils/axios";
import { getConfiguration } from "./utils/getConfiguration";

export const StiggContext = ({
  children,
  withCustomer = true,
}: {
  children: React.ReactNode;
  withCustomer?: boolean;
}) => {
  const [config, setConfig] = useState<{
    clientSdkKey: string;
    baseUrl: string;
    customerId: string;
    customerToken?: string;
  } | null>(null);

  useEffect(() => {
    const loadConfig = async () => {
      const { serverSdkKey, clientSdkKey, customerId, signingToken } =
        getConfiguration();

      if (clientSdkKey && serverSdkKey && signingToken) {
        const [baseUrl, customerToken] = await Promise.all([
          axios
            .get("/api/get-base-url")
            .then((response) => response.data.baseUrl),
          axios
            .get("/api/user")
            .then((response) => response.data.customerToken),
        ]);
        setConfig({
          clientSdkKey,
          baseUrl,
          customerId,
          customerToken,
        });
      }
    };

    void loadConfig();
  }, []);

  if (!config) {
    return null;
  }

  return (
    <StiggProvider
      baseUri={config.baseUrl}
      apiKey={config.clientSdkKey}
      customerId={withCustomer ? config.customerId : undefined}
      customerToken={withCustomer ? config.customerToken : undefined}
      theme={{ layout: { switchBottomSpacing: "30px" } }}
    >
      {children}
    </StiggProvider>
  );
};
