export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ConnectionCursor: any;
  DateTime: any;
  JSON: any;
};

/** DenyReason of get access policy */
export enum AccessDeniedReason {
  CustomerNotFound = "CustomerNotFound",
  CustomerResourceNotFound = "CustomerResourceNotFound",
  FeatureNotFound = "FeatureNotFound",
  NoActiveSubscription = "NoActiveSubscription",
  NoFeatureEntitlementInSubscription = "NoFeatureEntitlementInSubscription",
  RequestedUsageExceedingLimit = "RequestedUsageExceedingLimit",
  Unknown = "Unknown",
}

export type Account = {
  __typename?: "Account";
  accountEmailDomain?: Maybe<Scalars["String"]>;
  accountStatus?: Maybe<AccountStatus>;
  displayName: Scalars["String"];
  id: Scalars["String"];
  samlEnabled?: Maybe<Scalars["Boolean"]>;
  subscriptionBillingAnchor?: Maybe<BillingAnchor>;
  subscriptionProrationBehavior?: Maybe<ProrationBehavior>;
  timezone?: Maybe<Scalars["String"]>;
};

export type AccountNotFoundError = {
  __typename?: "AccountNotFoundError";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
};

export enum AccountStatus {
  Active = "ACTIVE",
  Blocked = "BLOCKED",
}

export type AddCompatibleAddonsToPlanInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type AdditionalMetaDataChange = {
  __typename?: "AdditionalMetaDataChange";
  after?: Maybe<Scalars["JSON"]>;
  before?: Maybe<Scalars["JSON"]>;
  changeType?: Maybe<ChangeType>;
};

export type Addon = {
  __typename?: "Addon";
  additionalMetaData?: Maybe<Scalars["JSON"]>;
  billingId?: Maybe<Scalars["String"]>;
  billingLinkUrl?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  displayName: Scalars["String"];
  draftDetails?: Maybe<PackageDraftDetails>;
  draftSummary?: Maybe<PackageDraftSummary>;
  entitlements?: Maybe<Array<PackageEntitlement>>;
  environment?: Maybe<Environment>;
  environmentId?: Maybe<Scalars["String"]>;
  hiddenFromWidgets?: Maybe<Array<WidgetType>>;
  id: Scalars["String"];
  isLatest?: Maybe<Scalars["Boolean"]>;
  prices?: Maybe<Array<Price>>;
  pricingType?: Maybe<PricingType>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars["String"]>;
  refId: Scalars["String"];
  status: PackageStatus;
  syncStates?: Maybe<Array<SyncState>>;
  type?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  versionNumber: Scalars["Int"];
};

export type AddonPricesArgs = {
  filter?: InputMaybe<PriceFilter>;
  sorting?: InputMaybe<Array<PriceSort>>;
};

export type AddonAggregateGroupBy = {
  __typename?: "AddonAggregateGroupBy";
  billingId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  isLatest?: Maybe<Scalars["Boolean"]>;
  pricingType?: Maybe<PricingType>;
  productId?: Maybe<Scalars["String"]>;
  refId?: Maybe<Scalars["String"]>;
  status?: Maybe<PackageStatus>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  versionNumber?: Maybe<Scalars["Int"]>;
};

export type AddonAvgAggregate = {
  __typename?: "AddonAvgAggregate";
  versionNumber?: Maybe<Scalars["Float"]>;
};

export type AddonConnection = {
  __typename?: "AddonConnection";
  /** Array of edges. */
  edges: Array<AddonEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type AddonCountAggregate = {
  __typename?: "AddonCountAggregate";
  billingId?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["Int"]>;
  displayName?: Maybe<Scalars["Int"]>;
  environmentId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  isLatest?: Maybe<Scalars["Int"]>;
  pricingType?: Maybe<Scalars["Int"]>;
  productId?: Maybe<Scalars["Int"]>;
  refId?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["Int"]>;
  versionNumber?: Maybe<Scalars["Int"]>;
};

export type AddonCreateInput = {
  additionalMetaData?: InputMaybe<Scalars["JSON"]>;
  billingId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  displayName: Scalars["String"];
  environmentId?: InputMaybe<Scalars["String"]>;
  hiddenFromWidgets?: InputMaybe<Array<WidgetType>>;
  productId: Scalars["String"];
  refId?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<PackageStatus>;
};

export type AddonDeleteResponse = {
  __typename?: "AddonDeleteResponse";
  additionalMetaData?: Maybe<Scalars["JSON"]>;
  billingId?: Maybe<Scalars["String"]>;
  billingLinkUrl?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  draftDetails?: Maybe<PackageDraftDetails>;
  draftSummary?: Maybe<PackageDraftSummary>;
  entitlements?: Maybe<Array<PackageEntitlement>>;
  environmentId?: Maybe<Scalars["String"]>;
  hiddenFromWidgets?: Maybe<Array<WidgetType>>;
  id?: Maybe<Scalars["String"]>;
  isLatest?: Maybe<Scalars["Boolean"]>;
  prices?: Maybe<Array<Price>>;
  pricingType?: Maybe<PricingType>;
  productId?: Maybe<Scalars["String"]>;
  refId?: Maybe<Scalars["String"]>;
  status?: Maybe<PackageStatus>;
  syncStates?: Maybe<Array<SyncState>>;
  type?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  versionNumber?: Maybe<Scalars["Int"]>;
};

export type AddonEdge = {
  __typename?: "AddonEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the Addon */
  node: Addon;
};

export type AddonFilter = {
  and?: InputMaybe<Array<AddonFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  isLatest?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<AddonFilter>>;
  pricingType?: InputMaybe<PricingTypeFilterComparison>;
  productId?: InputMaybe<StringFieldComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<PackageStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  versionNumber?: InputMaybe<IntFieldComparison>;
};

export type AddonMaxAggregate = {
  __typename?: "AddonMaxAggregate";
  billingId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  pricingType?: Maybe<PricingType>;
  productId?: Maybe<Scalars["String"]>;
  refId?: Maybe<Scalars["String"]>;
  status?: Maybe<PackageStatus>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  versionNumber?: Maybe<Scalars["Int"]>;
};

export type AddonMinAggregate = {
  __typename?: "AddonMinAggregate";
  billingId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  pricingType?: Maybe<PricingType>;
  productId?: Maybe<Scalars["String"]>;
  refId?: Maybe<Scalars["String"]>;
  status?: Maybe<PackageStatus>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  versionNumber?: Maybe<Scalars["Int"]>;
};

export type AddonSort = {
  direction: SortDirection;
  field: AddonSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum AddonSortFields {
  BillingId = "billingId",
  CreatedAt = "createdAt",
  Description = "description",
  DisplayName = "displayName",
  EnvironmentId = "environmentId",
  Id = "id",
  IsLatest = "isLatest",
  PricingType = "pricingType",
  ProductId = "productId",
  RefId = "refId",
  Status = "status",
  UpdatedAt = "updatedAt",
  VersionNumber = "versionNumber",
}

export type AddonSumAggregate = {
  __typename?: "AddonSumAggregate";
  versionNumber?: Maybe<Scalars["Float"]>;
};

export type AddonUpdateInput = {
  additionalMetaData?: InputMaybe<Scalars["JSON"]>;
  billingId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  displayName: Scalars["String"];
  hiddenFromWidgets?: InputMaybe<Array<WidgetType>>;
  id: Scalars["String"];
  status?: InputMaybe<PackageStatus>;
};

export type Address = {
  addressLine1?: InputMaybe<Scalars["String"]>;
  addressLine2?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  postalCode?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
};

/** Alignment */
export enum Alignment {
  Center = "CENTER",
  Left = "LEFT",
  Right = "RIGHT",
}

export type ApiKey = {
  __typename?: "ApiKey";
  id: Scalars["String"];
  keyType: ApiKeyType;
  token: Scalars["String"];
};

export type ApiKeyFilter = {
  and?: InputMaybe<Array<ApiKeyFilter>>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ApiKeyFilter>>;
};

export type ApiKeySort = {
  direction: SortDirection;
  field: ApiKeySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ApiKeySortFields {
  Id = "id",
}

export enum ApiKeyType {
  Client = "CLIENT",
  Server = "SERVER",
}

export type ArchiveCouponInput = {
  environmentId?: InputMaybe<Scalars["String"]>;
  refId: Scalars["String"];
};

export type ArchivePlanInput = {
  environmentId?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
};

export type AsyncTaskResult = {
  __typename?: "AsyncTaskResult";
  taskId: Scalars["String"];
};

export type AttachCustomerPaymentMethodInput = {
  customerId?: InputMaybe<Scalars["String"]>;
  environmentId?: InputMaybe<Scalars["String"]>;
  paymentMethodId: Scalars["String"];
  refId?: InputMaybe<Scalars["String"]>;
  vendorIdentifier: VendorIdentifier;
};

export type BaseError = {
  __typename?: "BaseError";
  code: Scalars["String"];
};

export type BasePlanChange = {
  __typename?: "BasePlanChange";
  after?: Maybe<Addon>;
  before?: Maybe<Addon>;
  changeType?: Maybe<ChangeType>;
};

export enum BillingAnchor {
  StartOfTheMonth = "START_OF_THE_MONTH",
  SubscriptionStart = "SUBSCRIPTION_START",
}

/** Billing model. */
export enum BillingModel {
  FlatFee = "FLAT_FEE",
  PerUnit = "PER_UNIT",
  UsageBased = "USAGE_BASED",
}

export type BillingModelFilterComparison = {
  eq?: InputMaybe<BillingModel>;
  gt?: InputMaybe<BillingModel>;
  gte?: InputMaybe<BillingModel>;
  iLike?: InputMaybe<BillingModel>;
  in?: InputMaybe<Array<BillingModel>>;
  is?: InputMaybe<Scalars["Boolean"]>;
  isNot?: InputMaybe<Scalars["Boolean"]>;
  like?: InputMaybe<BillingModel>;
  lt?: InputMaybe<BillingModel>;
  lte?: InputMaybe<BillingModel>;
  neq?: InputMaybe<BillingModel>;
  notILike?: InputMaybe<BillingModel>;
  notIn?: InputMaybe<Array<BillingModel>>;
  notLike?: InputMaybe<BillingModel>;
};

/** Billing period. */
export enum BillingPeriod {
  Annually = "ANNUALLY",
  Monthly = "MONTHLY",
}

export type BillingPeriodChangeVariables = {
  __typename?: "BillingPeriodChangeVariables";
  billingPeriod?: Maybe<BillingPeriod>;
};

export type BillingPeriodFilterComparison = {
  eq?: InputMaybe<BillingPeriod>;
  gt?: InputMaybe<BillingPeriod>;
  gte?: InputMaybe<BillingPeriod>;
  iLike?: InputMaybe<BillingPeriod>;
  in?: InputMaybe<Array<BillingPeriod>>;
  is?: InputMaybe<Scalars["Boolean"]>;
  isNot?: InputMaybe<Scalars["Boolean"]>;
  like?: InputMaybe<BillingPeriod>;
  lt?: InputMaybe<BillingPeriod>;
  lte?: InputMaybe<BillingPeriod>;
  neq?: InputMaybe<BillingPeriod>;
  notILike?: InputMaybe<BillingPeriod>;
  notIn?: InputMaybe<Array<BillingPeriod>>;
  notLike?: InputMaybe<BillingPeriod>;
};

export type BooleanFieldComparison = {
  is?: InputMaybe<Scalars["Boolean"]>;
  isNot?: InputMaybe<Scalars["Boolean"]>;
};

export type CannotDeleteCustomerError = {
  __typename?: "CannotDeleteCustomerError";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
  refId: Scalars["String"];
};

export type CannotDeleteFeatureError = {
  __typename?: "CannotDeleteFeatureError";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
  refId: Scalars["String"];
};

export enum ChangeType {
  Added = "ADDED",
  Deleted = "DELETED",
  Modified = "MODIFIED",
  Reordered = "REORDERED",
}

export type Checkout = {
  __typename?: "Checkout";
  checkoutBillingId: Scalars["String"];
  checkoutUrl: Scalars["String"];
  id: Scalars["String"];
};

export type CheckoutOptions = {
  allowPromoCodes?: InputMaybe<Scalars["Boolean"]>;
  allowTaxIdCollection?: InputMaybe<Scalars["Boolean"]>;
  cancelUrl: Scalars["String"];
  collectBillingAddress?: InputMaybe<Scalars["Boolean"]>;
  collectPhoneNumber?: InputMaybe<Scalars["Boolean"]>;
  referenceId?: InputMaybe<Scalars["String"]>;
  successUrl: Scalars["String"];
};

export type ClearCustomerPersistentCacheInput = {
  customerId: Scalars["String"];
  environmentId?: InputMaybe<Scalars["String"]>;
  resourceId?: InputMaybe<Scalars["String"]>;
};

export type Coupon = {
  __typename?: "Coupon";
  additionalMetaData?: Maybe<Scalars["JSON"]>;
  billingId?: Maybe<Scalars["String"]>;
  billingLinkUrl?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  customers?: Maybe<Array<Customer>>;
  description?: Maybe<Scalars["String"]>;
  discountValue: Scalars["Float"];
  environment?: Maybe<Environment>;
  environmentId: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
  refId: Scalars["String"];
  status: CouponStatus;
  syncStates?: Maybe<Array<SyncState>>;
  type: CouponType;
  updatedAt: Scalars["DateTime"];
};

export type CouponCustomersArgs = {
  filter?: InputMaybe<CustomerFilter>;
  sorting?: InputMaybe<Array<CustomerSort>>;
};

export type CouponAggregateGroupBy = {
  __typename?: "CouponAggregateGroupBy";
  billingId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  refId?: Maybe<Scalars["String"]>;
  status?: Maybe<CouponStatus>;
  type?: Maybe<CouponType>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CouponAvgAggregate = {
  __typename?: "CouponAvgAggregate";
  id?: Maybe<Scalars["Float"]>;
};

export type CouponConnection = {
  __typename?: "CouponConnection";
  /** Array of edges. */
  edges: Array<CouponEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type CouponCountAggregate = {
  __typename?: "CouponCountAggregate";
  billingId?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["Int"]>;
  environmentId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["Int"]>;
  refId?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["Int"]>;
};

export type CouponEdge = {
  __typename?: "CouponEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the Coupon */
  node: Coupon;
};

export type CouponFilter = {
  and?: InputMaybe<Array<CouponFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  customers?: InputMaybe<CouponFilterCustomerFilter>;
  description?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CouponFilter>>;
  refId?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<CouponStatusFilterComparison>;
  type?: InputMaybe<CouponTypeFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CouponFilterCustomerFilter = {
  and?: InputMaybe<Array<CouponFilterCustomerFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  crmHubspotCompanyId?: InputMaybe<StringFieldComparison>;
  crmHubspotCompanyUrl?: InputMaybe<StringFieldComparison>;
  crmId?: InputMaybe<StringFieldComparison>;
  customerId?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CouponFilterCustomerFilter>>;
  refId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CouponMaxAggregate = {
  __typename?: "CouponMaxAggregate";
  billingId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  refId?: Maybe<Scalars["String"]>;
  status?: Maybe<CouponStatus>;
  type?: Maybe<CouponType>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CouponMinAggregate = {
  __typename?: "CouponMinAggregate";
  billingId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  refId?: Maybe<Scalars["String"]>;
  status?: Maybe<CouponStatus>;
  type?: Maybe<CouponType>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CouponSort = {
  direction: SortDirection;
  field: CouponSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CouponSortFields {
  BillingId = "billingId",
  CreatedAt = "createdAt",
  Description = "description",
  EnvironmentId = "environmentId",
  Id = "id",
  Name = "name",
  RefId = "refId",
  Status = "status",
  Type = "type",
  UpdatedAt = "updatedAt",
}

/** The status of the coupon */
export enum CouponStatus {
  Active = "ACTIVE",
  Archived = "ARCHIVED",
}

export type CouponStatusFilterComparison = {
  eq?: InputMaybe<CouponStatus>;
  gt?: InputMaybe<CouponStatus>;
  gte?: InputMaybe<CouponStatus>;
  iLike?: InputMaybe<CouponStatus>;
  in?: InputMaybe<Array<CouponStatus>>;
  is?: InputMaybe<Scalars["Boolean"]>;
  isNot?: InputMaybe<Scalars["Boolean"]>;
  like?: InputMaybe<CouponStatus>;
  lt?: InputMaybe<CouponStatus>;
  lte?: InputMaybe<CouponStatus>;
  neq?: InputMaybe<CouponStatus>;
  notILike?: InputMaybe<CouponStatus>;
  notIn?: InputMaybe<Array<CouponStatus>>;
  notLike?: InputMaybe<CouponStatus>;
};

export type CouponSumAggregate = {
  __typename?: "CouponSumAggregate";
  id?: Maybe<Scalars["Float"]>;
};

/** The type of the coupon */
export enum CouponType {
  Fixed = "FIXED",
  Percentage = "PERCENTAGE",
}

export type CouponTypeFilterComparison = {
  eq?: InputMaybe<CouponType>;
  gt?: InputMaybe<CouponType>;
  gte?: InputMaybe<CouponType>;
  iLike?: InputMaybe<CouponType>;
  in?: InputMaybe<Array<CouponType>>;
  is?: InputMaybe<Scalars["Boolean"]>;
  isNot?: InputMaybe<Scalars["Boolean"]>;
  like?: InputMaybe<CouponType>;
  lt?: InputMaybe<CouponType>;
  lte?: InputMaybe<CouponType>;
  neq?: InputMaybe<CouponType>;
  notILike?: InputMaybe<CouponType>;
  notIn?: InputMaybe<Array<CouponType>>;
  notLike?: InputMaybe<CouponType>;
};

export type CreateCouponInput = {
  additionalMetaData?: InputMaybe<Scalars["JSON"]>;
  description?: InputMaybe<Scalars["String"]>;
  discountValue: Scalars["Float"];
  environmentId?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  refId: Scalars["String"];
  type: CouponType;
};

export type CreateEnvironment = {
  color?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  hardenClientAccessEnabled?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  provisionStatus?: InputMaybe<EnvironmentProvisionStatus>;
  slug?: InputMaybe<Scalars["String"]>;
};

export type CreateEnvironmentOptions = {
  createDefaultProduct?: InputMaybe<Scalars["Boolean"]>;
};

export type CreateExperimentInput = {
  controlGroupName: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
  environmentId?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  productId: Scalars["String"];
  productSettings?: InputMaybe<ProductSettingsInput>;
  variantGroupName: Scalars["String"];
  variantPercentage: Scalars["Float"];
};

export type CreateHook = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  endpoint?: InputMaybe<Scalars["String"]>;
  environmentId?: InputMaybe<Scalars["String"]>;
  eventLogTypes?: InputMaybe<Array<EventLogType>>;
  id?: InputMaybe<Scalars["String"]>;
  secretKey?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<HookStatus>;
};

export type CreateIntegrationInput = {
  environmentId: Scalars["String"];
  hubspotCredentials?: InputMaybe<HubspotCredentialsInput>;
  stripeCredentials?: InputMaybe<StripeCredentialsInput>;
  vendorIdentifier: VendorIdentifier;
  zuoraCredentials?: InputMaybe<ZuoraCredentialsInput>;
};

export type CreateManyPackageEntitlementsInput = {
  /** Array of records to create */
  packageEntitlements: Array<PackageEntitlementInput>;
};

export type CreateManyPromotionalEntitlementsInput = {
  /** Array of records to create */
  promotionalEntitlements: Array<PromotionalEntitlementInput>;
};

export type CreateOneEnvironmentInput = {
  environment: CreateEnvironment;
  options?: InputMaybe<CreateEnvironmentOptions>;
};

export type CreateOneFeatureInput = {
  /** The record to create */
  feature: FeatureInput;
};

export type CreateOneHookInput = {
  /** The record to create */
  hook: CreateHook;
};

export type CreateOneIntegrationInput = {
  /** The record to create */
  integration: CreateIntegrationInput;
};

export type CreateOneProductInput = {
  /** The record to create */
  product: ProductCreateInput;
};

export type Credentials =
  | HubspotCredentials
  | StripeCredentials
  | ZuoraCredentials;

/** Currency */
export enum Currency {
  Aed = "AED",
  All = "ALL",
  Amd = "AMD",
  Ang = "ANG",
  Aud = "AUD",
  Awg = "AWG",
  Azn = "AZN",
  Bam = "BAM",
  Bbd = "BBD",
  Bdt = "BDT",
  Bgn = "BGN",
  Bif = "BIF",
  Bmd = "BMD",
  Bnd = "BND",
  Bsd = "BSD",
  Bwp = "BWP",
  Byn = "BYN",
  Bzd = "BZD",
  Cad = "CAD",
  Cdf = "CDF",
  Chf = "CHF",
  Clp = "CLP",
  Cny = "CNY",
  Czk = "CZK",
  Djf = "DJF",
  Dkk = "DKK",
  Dop = "DOP",
  Dzd = "DZD",
  Egp = "EGP",
  Etb = "ETB",
  Eur = "EUR",
  Fjd = "FJD",
  Gbp = "GBP",
  Gel = "GEL",
  Gip = "GIP",
  Gmd = "GMD",
  Gnf = "GNF",
  Gyd = "GYD",
  Hkd = "HKD",
  Hrk = "HRK",
  Htg = "HTG",
  Idr = "IDR",
  Ils = "ILS",
  Inr = "INR",
  Isk = "ISK",
  Jmd = "JMD",
  Jpy = "JPY",
  Kes = "KES",
  Kgs = "KGS",
  Khr = "KHR",
  Kmf = "KMF",
  Krw = "KRW",
  Kyd = "KYD",
  Kzt = "KZT",
  Lbp = "LBP",
  Lkr = "LKR",
  Lrd = "LRD",
  Lsl = "LSL",
  Mad = "MAD",
  Mdl = "MDL",
  Mga = "MGA",
  Mkd = "MKD",
  Mmk = "MMK",
  Mnt = "MNT",
  Mop = "MOP",
  Mro = "MRO",
  Mvr = "MVR",
  Mwk = "MWK",
  Mxn = "MXN",
  Myr = "MYR",
  Mzn = "MZN",
  Nad = "NAD",
  Ngn = "NGN",
  Nok = "NOK",
  Npr = "NPR",
  Nzd = "NZD",
  Pgk = "PGK",
  Php = "PHP",
  Pkr = "PKR",
  Pln = "PLN",
  Pyg = "PYG",
  Qar = "QAR",
  Ron = "RON",
  Rsd = "RSD",
  Rub = "RUB",
  Rwf = "RWF",
  Sar = "SAR",
  Sbd = "SBD",
  Scr = "SCR",
  Sek = "SEK",
  Sgd = "SGD",
  Sle = "SLE",
  Sll = "SLL",
  Sos = "SOS",
  Szl = "SZL",
  Thb = "THB",
  Tjs = "TJS",
  Top = "TOP",
  Try = "TRY",
  Ttd = "TTD",
  Tzs = "TZS",
  Uah = "UAH",
  Ugx = "UGX",
  Usd = "USD",
  Uzs = "UZS",
  Vnd = "VND",
  Vuv = "VUV",
  Wst = "WST",
  Xaf = "XAF",
  Xcd = "XCD",
  Xof = "XOF",
  Xpf = "XPF",
  Yer = "YER",
  Zar = "ZAR",
  Zmw = "ZMW",
}

export type CursorPaging = {
  /** Paginate after opaque cursor */
  after?: InputMaybe<Scalars["ConnectionCursor"]>;
  /** Paginate before opaque cursor */
  before?: InputMaybe<Scalars["ConnectionCursor"]>;
  /** Paginate first */
  first?: InputMaybe<Scalars["Int"]>;
  /** Paginate last */
  last?: InputMaybe<Scalars["Int"]>;
};

export type Customer = {
  __typename?: "Customer";
  additionalMetaData?: Maybe<Scalars["JSON"]>;
  billingCurrency?: Maybe<Currency>;
  billingId?: Maybe<Scalars["String"]>;
  billingLinkUrl?: Maybe<Scalars["String"]>;
  coupon?: Maybe<Coupon>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  crmHubspotCompanyId?: Maybe<Scalars["String"]>;
  crmHubspotCompanyUrl?: Maybe<Scalars["String"]>;
  crmId?: Maybe<Scalars["String"]>;
  customerId: Scalars["String"];
  defaultPaymentExpirationMonth?: Maybe<Scalars["Int"]>;
  defaultPaymentExpirationYear?: Maybe<Scalars["Int"]>;
  defaultPaymentMethodId?: Maybe<Scalars["String"]>;
  defaultPaymentMethodLast4Digits?: Maybe<Scalars["String"]>;
  defaultPaymentMethodType?: Maybe<PaymentMethodType>;
  eligibleForTrial?: Maybe<Array<EligibleForTrial>>;
  email?: Maybe<Scalars["String"]>;
  environment?: Maybe<Environment>;
  environmentId: Scalars["String"];
  excludeFromExperiment?: Maybe<Scalars["Boolean"]>;
  experiment?: Maybe<Experiment>;
  experimentInfo?: Maybe<ExperimentInfo>;
  hasActiveResource: Scalars["Boolean"];
  hasActiveSubscription: Scalars["Boolean"];
  hasPaymentMethod: Scalars["Boolean"];
  id: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  promotionalEntitlements: Array<PromotionalEntitlement>;
  /** @deprecated Renamed to customerId */
  refId: Scalars["String"];
  subscriptions?: Maybe<Array<CustomerSubscription>>;
  syncStates?: Maybe<Array<SyncState>>;
  totalActiveSubscription: Scalars["Float"];
  trialedPlans?: Maybe<Array<TrialedPlan>>;
  updatedAt: Scalars["DateTime"];
};

export type CustomerPromotionalEntitlementsArgs = {
  filter?: InputMaybe<PromotionalEntitlementFilter>;
  sorting?: InputMaybe<Array<PromotionalEntitlementSort>>;
};

export type CustomerSubscriptionsArgs = {
  filter?: InputMaybe<CustomerSubscriptionFilter>;
  sorting?: InputMaybe<Array<CustomerSubscriptionSort>>;
};

export type CustomerAggregateGroupBy = {
  __typename?: "CustomerAggregateGroupBy";
  billingId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  crmHubspotCompanyId?: Maybe<Scalars["String"]>;
  crmHubspotCompanyUrl?: Maybe<Scalars["String"]>;
  crmId?: Maybe<Scalars["String"]>;
  customerId?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  refId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CustomerBillingInfo = {
  billingAddress?: InputMaybe<Address>;
  currency?: InputMaybe<Currency>;
  customerName?: InputMaybe<Scalars["String"]>;
  invoiceCustomFields?: InputMaybe<Scalars["JSON"]>;
  language?: InputMaybe<Scalars["String"]>;
  paymentMethodId?: InputMaybe<Scalars["String"]>;
  shippingAddress?: InputMaybe<Address>;
  taxIds?: InputMaybe<Array<TaxExempt>>;
  timezone?: InputMaybe<Scalars["String"]>;
};

export type CustomerConnection = {
  __typename?: "CustomerConnection";
  /** Array of edges. */
  edges: Array<CustomerEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type CustomerCountAggregate = {
  __typename?: "CustomerCountAggregate";
  billingId?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["Int"]>;
  crmHubspotCompanyId?: Maybe<Scalars["Int"]>;
  crmHubspotCompanyUrl?: Maybe<Scalars["Int"]>;
  crmId?: Maybe<Scalars["Int"]>;
  customerId?: Maybe<Scalars["Int"]>;
  email?: Maybe<Scalars["Int"]>;
  environmentId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["Int"]>;
  refId?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["Int"]>;
};

export type CustomerEdge = {
  __typename?: "CustomerEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the Customer */
  node: Customer;
};

export type CustomerFilter = {
  and?: InputMaybe<Array<CustomerFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  crmHubspotCompanyId?: InputMaybe<StringFieldComparison>;
  crmHubspotCompanyUrl?: InputMaybe<StringFieldComparison>;
  crmId?: InputMaybe<StringFieldComparison>;
  customerId?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CustomerFilter>>;
  promotionalEntitlements?: InputMaybe<CustomerFilterPromotionalEntitlementFilter>;
  refId?: InputMaybe<StringFieldComparison>;
  subscriptions?: InputMaybe<CustomerFilterCustomerSubscriptionFilter>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CustomerFilterCustomerSubscriptionFilter = {
  and?: InputMaybe<Array<CustomerFilterCustomerSubscriptionFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  cancelReason?: InputMaybe<SubscriptionCancelReasonFilterComparison>;
  cancellationDate?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  crmId?: InputMaybe<StringFieldComparison>;
  crmLinkUrl?: InputMaybe<StringFieldComparison>;
  effectiveEndDate?: InputMaybe<DateFieldComparison>;
  endDate?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  oldBillingId?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CustomerFilterCustomerSubscriptionFilter>>;
  pricingType?: InputMaybe<PricingTypeFilterComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<DateFieldComparison>;
  status?: InputMaybe<SubscriptionStatusFilterComparison>;
  subscriptionId?: InputMaybe<StringFieldComparison>;
  trialEndDate?: InputMaybe<DateFieldComparison>;
};

export type CustomerFilterPromotionalEntitlementFilter = {
  and?: InputMaybe<Array<CustomerFilterPromotionalEntitlementFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CustomerFilterPromotionalEntitlementFilter>>;
  status?: InputMaybe<PromotionalEntitlementStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CustomerInput = {
  additionalMetaData?: InputMaybe<Scalars["JSON"]>;
  billingId?: InputMaybe<Scalars["String"]>;
  billingInformation?: InputMaybe<CustomerBillingInfo>;
  couponRefId?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  crmId?: InputMaybe<Scalars["String"]>;
  customerId?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  environmentId?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  refId?: InputMaybe<Scalars["String"]>;
  shouldSyncFree?: InputMaybe<Scalars["Boolean"]>;
};

export type CustomerMaxAggregate = {
  __typename?: "CustomerMaxAggregate";
  billingId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  crmHubspotCompanyId?: Maybe<Scalars["String"]>;
  crmHubspotCompanyUrl?: Maybe<Scalars["String"]>;
  crmId?: Maybe<Scalars["String"]>;
  customerId?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  refId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CustomerMinAggregate = {
  __typename?: "CustomerMinAggregate";
  billingId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  crmHubspotCompanyId?: Maybe<Scalars["String"]>;
  crmHubspotCompanyUrl?: Maybe<Scalars["String"]>;
  crmId?: Maybe<Scalars["String"]>;
  customerId?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  refId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CustomerNoBillingId = {
  __typename?: "CustomerNoBillingId";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
  refId: Scalars["String"];
};

export type CustomerNotFoundError = {
  __typename?: "CustomerNotFoundError";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
  refId: Scalars["String"];
};

export type CustomerPortal = {
  __typename?: "CustomerPortal";
  billingInformation: CustomerPortalBillingInformation;
  billingPortalUrl?: Maybe<Scalars["String"]>;
  canUpgradeSubscription: Scalars["Boolean"];
  configuration?: Maybe<CustomerPortalConfiguration>;
  entitlements: Array<Entitlement>;
  promotionalEntitlements: Array<CustomerPortalPromotionalEntitlement>;
  resource?: Maybe<CustomerResource>;
  showWatermark: Scalars["Boolean"];
  subscriptions: Array<CustomerPortalSubscription>;
};

export type CustomerPortalAddon = {
  __typename?: "CustomerPortalAddon";
  addonId: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  displayName: Scalars["String"];
  quantity: Scalars["Int"];
};

export type CustomerPortalBillingInformation = {
  __typename?: "CustomerPortalBillingInformation";
  defaultPaymentExpirationMonth?: Maybe<Scalars["Int"]>;
  defaultPaymentExpirationYear?: Maybe<Scalars["Int"]>;
  defaultPaymentMethodId?: Maybe<Scalars["String"]>;
  defaultPaymentMethodLast4Digits?: Maybe<Scalars["String"]>;
  defaultPaymentMethodType?: Maybe<PaymentMethodType>;
  email?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type CustomerPortalColorsPalette = {
  __typename?: "CustomerPortalColorsPalette";
  backgroundColor?: Maybe<Scalars["String"]>;
  borderColor?: Maybe<Scalars["String"]>;
  currentPlanBackground?: Maybe<Scalars["String"]>;
  iconsColor?: Maybe<Scalars["String"]>;
  paywallBackgroundColor?: Maybe<Scalars["String"]>;
  primary?: Maybe<Scalars["String"]>;
  textColor?: Maybe<Scalars["String"]>;
};

export type CustomerPortalColorsPaletteInput = {
  backgroundColor?: InputMaybe<Scalars["String"]>;
  borderColor?: InputMaybe<Scalars["String"]>;
  currentPlanBackground?: InputMaybe<Scalars["String"]>;
  iconsColor?: InputMaybe<Scalars["String"]>;
  paywallBackgroundColor?: InputMaybe<Scalars["String"]>;
  primary?: InputMaybe<Scalars["String"]>;
  textColor?: InputMaybe<Scalars["String"]>;
};

export type CustomerPortalConfiguration = {
  __typename?: "CustomerPortalConfiguration";
  customCss?: Maybe<Scalars["String"]>;
  palette?: Maybe<CustomerPortalColorsPalette>;
  typography?: Maybe<TypographyConfiguration>;
};

export type CustomerPortalConfigurationInput = {
  customCss?: InputMaybe<Scalars["String"]>;
  palette?: InputMaybe<CustomerPortalColorsPaletteInput>;
  typography?: InputMaybe<TypographyConfigurationInput>;
};

export type CustomerPortalInput = {
  customerId: Scalars["String"];
  resourceId?: InputMaybe<Scalars["String"]>;
};

export type CustomerPortalPricingFeature = {
  __typename?: "CustomerPortalPricingFeature";
  description?: Maybe<Scalars["String"]>;
  displayName: Scalars["String"];
  featureType: FeatureType;
  featureUnits?: Maybe<Scalars["String"]>;
  featureUnitsPlural?: Maybe<Scalars["String"]>;
  meterType?: Maybe<MeterType>;
};

export type CustomerPortalPromotionalEntitlement = {
  __typename?: "CustomerPortalPromotionalEntitlement";
  description?: Maybe<Scalars["String"]>;
  displayName: Scalars["String"];
  endDate?: Maybe<Scalars["DateTime"]>;
  hasUnlimitedUsage?: Maybe<Scalars["Boolean"]>;
  period: PromotionalEntitlementPeriod;
  startDate: Scalars["DateTime"];
  usageLimit?: Maybe<Scalars["Float"]>;
};

export type CustomerPortalSubscription = {
  __typename?: "CustomerPortalSubscription";
  addons: Array<CustomerPortalAddon>;
  billingPeriodRange?: Maybe<DateRange>;
  planName: Scalars["String"];
  pricing: CustomerPortalSubscriptionPricing;
  scheduledUpdates?: Maybe<Array<SubscriptionScheduledUpdate>>;
  status: SubscriptionStatus;
  subscriptionId: Scalars["String"];
  totalPrice?: Maybe<CustomerSubscriptionTotalPrice>;
  trialRemainingDays?: Maybe<Scalars["Int"]>;
};

export type CustomerPortalSubscriptionPricing = {
  __typename?: "CustomerPortalSubscriptionPricing";
  billingCountryCode?: Maybe<Scalars["String"]>;
  billingModel?: Maybe<BillingModel>;
  billingPeriod?: Maybe<BillingPeriod>;
  feature?: Maybe<CustomerPortalPricingFeature>;
  price?: Maybe<Money>;
  pricingType: PricingType;
  unitQuantity?: Maybe<Scalars["Int"]>;
  usageBasedEstimatedBill?: Maybe<Scalars["Float"]>;
};

export type CustomerResource = {
  __typename?: "CustomerResource";
  createdAt: Scalars["DateTime"];
  customer: Customer;
  environmentId: Scalars["String"];
  resourceId: Scalars["String"];
  subscriptions: Array<CustomerSubscription>;
};

export type CustomerResourceSubscriptionsArgs = {
  filter?: InputMaybe<CustomerSubscriptionFilter>;
  sorting?: InputMaybe<Array<CustomerSubscriptionSort>>;
};

export type CustomerResourceAggregateGroupBy = {
  __typename?: "CustomerResourceAggregateGroupBy";
  createdAt?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  resourceId?: Maybe<Scalars["String"]>;
};

export type CustomerResourceConnection = {
  __typename?: "CustomerResourceConnection";
  /** Array of edges. */
  edges: Array<CustomerResourceEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type CustomerResourceCountAggregate = {
  __typename?: "CustomerResourceCountAggregate";
  createdAt?: Maybe<Scalars["Int"]>;
  environmentId?: Maybe<Scalars["Int"]>;
  resourceId?: Maybe<Scalars["Int"]>;
};

export type CustomerResourceEdge = {
  __typename?: "CustomerResourceEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the CustomerResource */
  node: CustomerResource;
};

export type CustomerResourceFilter = {
  and?: InputMaybe<Array<CustomerResourceFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  customer?: InputMaybe<CustomerResourceFilterCustomerFilter>;
  environmentId?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CustomerResourceFilter>>;
  resourceId?: InputMaybe<StringFieldComparison>;
  subscriptions?: InputMaybe<CustomerResourceFilterCustomerSubscriptionFilter>;
};

export type CustomerResourceFilterCustomerFilter = {
  and?: InputMaybe<Array<CustomerResourceFilterCustomerFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  crmHubspotCompanyId?: InputMaybe<StringFieldComparison>;
  crmHubspotCompanyUrl?: InputMaybe<StringFieldComparison>;
  crmId?: InputMaybe<StringFieldComparison>;
  customerId?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CustomerResourceFilterCustomerFilter>>;
  refId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CustomerResourceFilterCustomerSubscriptionFilter = {
  and?: InputMaybe<Array<CustomerResourceFilterCustomerSubscriptionFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  cancelReason?: InputMaybe<SubscriptionCancelReasonFilterComparison>;
  cancellationDate?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  crmId?: InputMaybe<StringFieldComparison>;
  crmLinkUrl?: InputMaybe<StringFieldComparison>;
  effectiveEndDate?: InputMaybe<DateFieldComparison>;
  endDate?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  oldBillingId?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CustomerResourceFilterCustomerSubscriptionFilter>>;
  pricingType?: InputMaybe<PricingTypeFilterComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<DateFieldComparison>;
  status?: InputMaybe<SubscriptionStatusFilterComparison>;
  subscriptionId?: InputMaybe<StringFieldComparison>;
  trialEndDate?: InputMaybe<DateFieldComparison>;
};

export type CustomerResourceMaxAggregate = {
  __typename?: "CustomerResourceMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  resourceId?: Maybe<Scalars["String"]>;
};

export type CustomerResourceMinAggregate = {
  __typename?: "CustomerResourceMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  resourceId?: Maybe<Scalars["String"]>;
};

export type CustomerResourceSort = {
  direction: SortDirection;
  field: CustomerResourceSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CustomerResourceSortFields {
  CreatedAt = "createdAt",
  EnvironmentId = "environmentId",
  ResourceId = "resourceId",
}

export type CustomerSort = {
  direction: SortDirection;
  field: CustomerSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CustomerSortFields {
  BillingId = "billingId",
  CreatedAt = "createdAt",
  CrmHubspotCompanyId = "crmHubspotCompanyId",
  CrmHubspotCompanyUrl = "crmHubspotCompanyUrl",
  CrmId = "crmId",
  CustomerId = "customerId",
  Email = "email",
  EnvironmentId = "environmentId",
  Id = "id",
  Name = "name",
  RefId = "refId",
  UpdatedAt = "updatedAt",
}

export type CustomerSubscription = {
  __typename?: "CustomerSubscription";
  additionalMetaData?: Maybe<Scalars["JSON"]>;
  addons?: Maybe<Array<SubscriptionAddon>>;
  billingId?: Maybe<Scalars["String"]>;
  billingLinkUrl?: Maybe<Scalars["String"]>;
  cancelReason?: Maybe<SubscriptionCancelReason>;
  cancellationDate?: Maybe<Scalars["DateTime"]>;
  coupon?: Maybe<SubscriptionCoupon>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  crmId?: Maybe<Scalars["String"]>;
  crmLinkUrl?: Maybe<Scalars["String"]>;
  currentBillingPeriodEnd?: Maybe<Scalars["DateTime"]>;
  customer: Customer;
  effectiveEndDate?: Maybe<Scalars["DateTime"]>;
  endDate?: Maybe<Scalars["DateTime"]>;
  environment: Environment;
  environmentId: Scalars["String"];
  experiment?: Maybe<Experiment>;
  experimentInfo?: Maybe<ExperimentInfo>;
  id: Scalars["String"];
  isCustomPriceSubscription?: Maybe<Scalars["Boolean"]>;
  oldBillingId?: Maybe<Scalars["String"]>;
  outdatedPricePackages?: Maybe<Array<Scalars["String"]>>;
  plan: Plan;
  prices?: Maybe<Array<SubscriptionPrice>>;
  pricingType: PricingType;
  /** @deprecated Renamed to subscriptionId */
  refId: Scalars["String"];
  resource?: Maybe<CustomerResource>;
  resourceId?: Maybe<Scalars["String"]>;
  scheduledUpdates?: Maybe<Array<SubscriptionScheduledUpdate>>;
  startDate: Scalars["DateTime"];
  status: SubscriptionStatus;
  subscriptionEntitlements?: Maybe<Array<SubscriptionEntitlement>>;
  subscriptionId: Scalars["String"];
  syncStates?: Maybe<Array<SyncState>>;
  totalPrice?: Maybe<CustomerSubscriptionTotalPrice>;
  trialEndDate?: Maybe<Scalars["DateTime"]>;
  wasInTrial?: Maybe<Scalars["Boolean"]>;
};

export type CustomerSubscriptionAddonsArgs = {
  filter?: InputMaybe<SubscriptionAddonFilter>;
  sorting?: InputMaybe<Array<SubscriptionAddonSort>>;
};

export type CustomerSubscriptionPricesArgs = {
  filter?: InputMaybe<SubscriptionPriceFilter>;
  sorting?: InputMaybe<Array<SubscriptionPriceSort>>;
};

export type CustomerSubscriptionSubscriptionEntitlementsArgs = {
  filter?: InputMaybe<SubscriptionEntitlementFilter>;
  sorting?: InputMaybe<Array<SubscriptionEntitlementSort>>;
};

export type CustomerSubscriptionAggregateGroupBy = {
  __typename?: "CustomerSubscriptionAggregateGroupBy";
  billingId?: Maybe<Scalars["String"]>;
  cancelReason?: Maybe<SubscriptionCancelReason>;
  cancellationDate?: Maybe<Scalars["DateTime"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  crmId?: Maybe<Scalars["String"]>;
  crmLinkUrl?: Maybe<Scalars["String"]>;
  effectiveEndDate?: Maybe<Scalars["DateTime"]>;
  endDate?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  oldBillingId?: Maybe<Scalars["String"]>;
  pricingType?: Maybe<PricingType>;
  refId?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["DateTime"]>;
  status?: Maybe<SubscriptionStatus>;
  subscriptionId?: Maybe<Scalars["String"]>;
  trialEndDate?: Maybe<Scalars["DateTime"]>;
};

export type CustomerSubscriptionConnection = {
  __typename?: "CustomerSubscriptionConnection";
  /** Array of edges. */
  edges: Array<CustomerSubscriptionEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type CustomerSubscriptionCountAggregate = {
  __typename?: "CustomerSubscriptionCountAggregate";
  billingId?: Maybe<Scalars["Int"]>;
  cancelReason?: Maybe<Scalars["Int"]>;
  cancellationDate?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["Int"]>;
  crmId?: Maybe<Scalars["Int"]>;
  crmLinkUrl?: Maybe<Scalars["Int"]>;
  effectiveEndDate?: Maybe<Scalars["Int"]>;
  endDate?: Maybe<Scalars["Int"]>;
  environmentId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  oldBillingId?: Maybe<Scalars["Int"]>;
  pricingType?: Maybe<Scalars["Int"]>;
  refId?: Maybe<Scalars["Int"]>;
  startDate?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["Int"]>;
  subscriptionId?: Maybe<Scalars["Int"]>;
  trialEndDate?: Maybe<Scalars["Int"]>;
};

export type CustomerSubscriptionEdge = {
  __typename?: "CustomerSubscriptionEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the CustomerSubscription */
  node: CustomerSubscription;
};

export type CustomerSubscriptionFilter = {
  addons?: InputMaybe<CustomerSubscriptionFilterSubscriptionAddonFilter>;
  and?: InputMaybe<Array<CustomerSubscriptionFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  cancelReason?: InputMaybe<SubscriptionCancelReasonFilterComparison>;
  cancellationDate?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  crmId?: InputMaybe<StringFieldComparison>;
  crmLinkUrl?: InputMaybe<StringFieldComparison>;
  customer?: InputMaybe<CustomerSubscriptionFilterCustomerFilter>;
  effectiveEndDate?: InputMaybe<DateFieldComparison>;
  endDate?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  oldBillingId?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CustomerSubscriptionFilter>>;
  prices?: InputMaybe<CustomerSubscriptionFilterSubscriptionPriceFilter>;
  pricingType?: InputMaybe<PricingTypeFilterComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<DateFieldComparison>;
  status?: InputMaybe<SubscriptionStatusFilterComparison>;
  subscriptionEntitlements?: InputMaybe<CustomerSubscriptionFilterSubscriptionEntitlementFilter>;
  subscriptionId?: InputMaybe<StringFieldComparison>;
  trialEndDate?: InputMaybe<DateFieldComparison>;
};

export type CustomerSubscriptionFilterCustomerFilter = {
  and?: InputMaybe<Array<CustomerSubscriptionFilterCustomerFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  crmHubspotCompanyId?: InputMaybe<StringFieldComparison>;
  crmHubspotCompanyUrl?: InputMaybe<StringFieldComparison>;
  crmId?: InputMaybe<StringFieldComparison>;
  customerId?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CustomerSubscriptionFilterCustomerFilter>>;
  refId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CustomerSubscriptionFilterSubscriptionAddonFilter = {
  and?: InputMaybe<Array<CustomerSubscriptionFilterSubscriptionAddonFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CustomerSubscriptionFilterSubscriptionAddonFilter>>;
  quantity?: InputMaybe<NumberFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CustomerSubscriptionFilterSubscriptionEntitlementFilter = {
  and?: InputMaybe<
    Array<CustomerSubscriptionFilterSubscriptionEntitlementFilter>
  >;
  createdAt?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<
    Array<CustomerSubscriptionFilterSubscriptionEntitlementFilter>
  >;
  subscriptionId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CustomerSubscriptionFilterSubscriptionPriceFilter = {
  and?: InputMaybe<Array<CustomerSubscriptionFilterSubscriptionPriceFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CustomerSubscriptionFilterSubscriptionPriceFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  usageLimit?: InputMaybe<NumberFieldComparison>;
};

export type CustomerSubscriptionMaxAggregate = {
  __typename?: "CustomerSubscriptionMaxAggregate";
  billingId?: Maybe<Scalars["String"]>;
  cancelReason?: Maybe<SubscriptionCancelReason>;
  cancellationDate?: Maybe<Scalars["DateTime"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  crmId?: Maybe<Scalars["String"]>;
  crmLinkUrl?: Maybe<Scalars["String"]>;
  effectiveEndDate?: Maybe<Scalars["DateTime"]>;
  endDate?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  oldBillingId?: Maybe<Scalars["String"]>;
  pricingType?: Maybe<PricingType>;
  refId?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["DateTime"]>;
  status?: Maybe<SubscriptionStatus>;
  subscriptionId?: Maybe<Scalars["String"]>;
  trialEndDate?: Maybe<Scalars["DateTime"]>;
};

export type CustomerSubscriptionMinAggregate = {
  __typename?: "CustomerSubscriptionMinAggregate";
  billingId?: Maybe<Scalars["String"]>;
  cancelReason?: Maybe<SubscriptionCancelReason>;
  cancellationDate?: Maybe<Scalars["DateTime"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  crmId?: Maybe<Scalars["String"]>;
  crmLinkUrl?: Maybe<Scalars["String"]>;
  effectiveEndDate?: Maybe<Scalars["DateTime"]>;
  endDate?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  oldBillingId?: Maybe<Scalars["String"]>;
  pricingType?: Maybe<PricingType>;
  refId?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["DateTime"]>;
  status?: Maybe<SubscriptionStatus>;
  subscriptionId?: Maybe<Scalars["String"]>;
  trialEndDate?: Maybe<Scalars["DateTime"]>;
};

export type CustomerSubscriptionSort = {
  direction: SortDirection;
  field: CustomerSubscriptionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CustomerSubscriptionSortFields {
  BillingId = "billingId",
  CancelReason = "cancelReason",
  CancellationDate = "cancellationDate",
  CreatedAt = "createdAt",
  CrmId = "crmId",
  CrmLinkUrl = "crmLinkUrl",
  EffectiveEndDate = "effectiveEndDate",
  EndDate = "endDate",
  EnvironmentId = "environmentId",
  Id = "id",
  OldBillingId = "oldBillingId",
  PricingType = "pricingType",
  RefId = "refId",
  StartDate = "startDate",
  Status = "status",
  SubscriptionId = "subscriptionId",
  TrialEndDate = "trialEndDate",
}

export type CustomerSubscriptionTotalPrice = {
  __typename?: "CustomerSubscriptionTotalPrice";
  addonsTotal: Money;
  subTotal: Money;
  total: Money;
};

export type DateFieldComparison = {
  between?: InputMaybe<DateFieldComparisonBetween>;
  eq?: InputMaybe<Scalars["DateTime"]>;
  gt?: InputMaybe<Scalars["DateTime"]>;
  gte?: InputMaybe<Scalars["DateTime"]>;
  in?: InputMaybe<Array<Scalars["DateTime"]>>;
  is?: InputMaybe<Scalars["Boolean"]>;
  isNot?: InputMaybe<Scalars["Boolean"]>;
  lt?: InputMaybe<Scalars["DateTime"]>;
  lte?: InputMaybe<Scalars["DateTime"]>;
  neq?: InputMaybe<Scalars["DateTime"]>;
  notBetween?: InputMaybe<DateFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars["DateTime"]>>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars["DateTime"];
  upper: Scalars["DateTime"];
};

export type DateRange = {
  __typename?: "DateRange";
  end?: Maybe<Scalars["DateTime"]>;
  start?: Maybe<Scalars["DateTime"]>;
};

export type DefaultTrialConfig = {
  __typename?: "DefaultTrialConfig";
  duration: Scalars["Float"];
  units: TrialPeriodUnits;
};

export type DefaultTrialConfigChange = {
  __typename?: "DefaultTrialConfigChange";
  after?: Maybe<DefaultTrialConfig>;
  before?: Maybe<DefaultTrialConfig>;
  changeType?: Maybe<ChangeType>;
};

export type DefaultTrialConfigInputDto = {
  duration?: InputMaybe<Scalars["Float"]>;
  units?: InputMaybe<TrialPeriodUnits>;
};

export type DeleteCustomerInput = {
  environmentId?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
};

export type DeleteFeatureInput = {
  environmentId?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
};

export type DeleteOneAddonInput = {
  /** The id of the record to delete. */
  id: Scalars["String"];
};

export type DeleteOneEnvironmentInput = {
  /** The id of the record to delete. */
  id: Scalars["String"];
};

export type DeleteOneHookInput = {
  /** The id of the record to delete. */
  id: Scalars["String"];
};

export type DeleteOneIntegrationInput = {
  /** The id of the record to delete. */
  id: Scalars["String"];
};

export type DeleteOnePackageEntitlementInput = {
  /** The id of the record to delete. */
  id: Scalars["String"];
};

export type DeleteOnePriceInput = {
  /** The id of the record to delete. */
  id: Scalars["String"];
};

export type DeleteOneProductInput = {
  /** The id of the record to delete. */
  id: Scalars["String"];
};

export type DeleteOnePromotionalEntitlementInput = {
  /** The id of the record to delete. */
  id: Scalars["String"];
};

export enum Department {
  CeoOrFounder = "CEO_OR_FOUNDER",
  Engineering = "ENGINEERING",
  Growth = "GROWTH",
  Marketing = "MARKETING",
  Monetization = "MONETIZATION",
  Other = "OTHER",
  Product = "PRODUCT",
}

export type DiscardPackageDraftInput = {
  environmentId: Scalars["String"];
  refId: Scalars["String"];
};

/** The type of the discount duration */
export enum DiscountDurationType {
  Forever = "FOREVER",
  Once = "ONCE",
  Repeating = "REPEATING",
}

/** The type of the discount */
export enum DiscountType {
  Fixed = "FIXED",
  Percentage = "PERCENTAGE",
}

export type DowngradeChangeVariables = {
  __typename?: "DowngradeChangeVariables";
  addonRefIds?: Maybe<Scalars["String"]>;
  billingPeriod?: Maybe<BillingPeriod>;
  downgradePlanRefId: Scalars["String"];
};

export type DuplicatedEntityNotAllowedError = {
  __typename?: "DuplicatedEntityNotAllowedError";
  code: Scalars["String"];
  entityName: Scalars["String"];
  identifier: Scalars["String"];
  isValidationError: Scalars["Boolean"];
};

export type EditAllowedOnDraftPackageOnlyError = {
  __typename?: "EditAllowedOnDraftPackageOnlyError";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
};

export type EligibleForTrial = {
  __typename?: "EligibleForTrial";
  eligible: Scalars["Boolean"];
  productId?: Maybe<Scalars["String"]>;
  productRefId?: Maybe<Scalars["String"]>;
};

export type Entitlement = {
  __typename?: "Entitlement";
  accessDeniedReason?: Maybe<AccessDeniedReason>;
  currentUsage?: Maybe<Scalars["Float"]>;
  customerId?: Maybe<Scalars["String"]>;
  displayNameOverride?: Maybe<Scalars["String"]>;
  entitlementUpdatedAt?: Maybe<Scalars["DateTime"]>;
  feature?: Maybe<EntitlementFeature>;
  hasUnlimitedUsage: Scalars["Boolean"];
  hiddenFromWidgets?: Maybe<Array<WidgetType>>;
  isGranted: Scalars["Boolean"];
  nextResetDate?: Maybe<Scalars["DateTime"]>;
  requestedUsage?: Maybe<Scalars["Float"]>;
  resetPeriod?: Maybe<EntitlementResetPeriod>;
  resetPeriodConfiguration?: Maybe<ResetPeriodConfiguration>;
  resourceId?: Maybe<Scalars["String"]>;
  usageLimit?: Maybe<Scalars["Float"]>;
  usageUpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type EntitlementCheckRequested = {
  customerId: Scalars["String"];
  entitlementCheckResult: EntitlementCheckResult;
  environmentId?: InputMaybe<Scalars["String"]>;
  featureId: Scalars["String"];
  requestedUsage?: InputMaybe<Scalars["Float"]>;
  resourceId?: InputMaybe<Scalars["String"]>;
};

export type EntitlementCheckResult = {
  accessDeniedReason?: InputMaybe<AccessDeniedReason>;
  currentUsage?: InputMaybe<Scalars["Float"]>;
  hasAccess: Scalars["Boolean"];
  hasUnlimitedUsage?: InputMaybe<Scalars["Boolean"]>;
  monthlyResetPeriodConfiguration?: InputMaybe<MonthlyResetPeriodConfigInput>;
  nextResetDate?: InputMaybe<Scalars["DateTime"]>;
  requestedUsage?: InputMaybe<Scalars["Float"]>;
  resetPeriod?: InputMaybe<EntitlementResetPeriod>;
  usageLimit?: InputMaybe<Scalars["Float"]>;
  weeklyResetPeriodConfiguration?: InputMaybe<WeeklyResetPeriodConfigInput>;
};

export type EntitlementFeature = {
  __typename?: "EntitlementFeature";
  additionalMetaData?: Maybe<Scalars["JSON"]>;
  description?: Maybe<Scalars["String"]>;
  displayName: Scalars["String"];
  featureStatus: FeatureStatus;
  featureType: FeatureType;
  featureUnits?: Maybe<Scalars["String"]>;
  featureUnitsPlural?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  meterType?: Maybe<MeterType>;
  refId: Scalars["String"];
};

export type EntitlementOptions = {
  requestedUsage?: InputMaybe<Scalars["Float"]>;
  shouldTrack?: InputMaybe<Scalars["Boolean"]>;
};

/** Entitlement reset period. */
export enum EntitlementResetPeriod {
  Day = "DAY",
  Hour = "HOUR",
  Month = "MONTH",
  Week = "WEEK",
}

export type EntitlementSummary = {
  __typename?: "EntitlementSummary";
  addonQuantity?: Maybe<Scalars["Float"]>;
  featurePackageEntitlement?: Maybe<PackageEntitlement>;
  featurePromotionalEntitlement?: Maybe<PromotionalEntitlement>;
  isEffectiveEntitlement: Scalars["Boolean"];
  plan?: Maybe<Plan>;
  priceEntitlement?: Maybe<PriceEntitlement>;
  subscription?: Maybe<CustomerSubscription>;
};

export type EntitlementWithSummary = {
  __typename?: "EntitlementWithSummary";
  accessDeniedReason?: Maybe<AccessDeniedReason>;
  currentUsage?: Maybe<Scalars["Float"]>;
  customerId?: Maybe<Scalars["String"]>;
  displayNameOverride?: Maybe<Scalars["String"]>;
  entitlementUpdatedAt?: Maybe<Scalars["DateTime"]>;
  feature?: Maybe<EntitlementFeature>;
  hasUnlimitedUsage: Scalars["Boolean"];
  hiddenFromWidgets?: Maybe<Array<WidgetType>>;
  isGranted: Scalars["Boolean"];
  nextResetDate?: Maybe<Scalars["DateTime"]>;
  requestedUsage?: Maybe<Scalars["Float"]>;
  resetPeriod?: Maybe<EntitlementResetPeriod>;
  resetPeriodConfiguration?: Maybe<ResetPeriodConfiguration>;
  resourceId?: Maybe<Scalars["String"]>;
  summaries?: Maybe<Array<EntitlementSummary>>;
  usageLimit?: Maybe<Scalars["Float"]>;
  usageUpdatedAt?: Maybe<Scalars["DateTime"]>;
};

export type EntitlementsUpdated = {
  __typename?: "EntitlementsUpdated";
  accountId: Scalars["String"];
  customerId: Scalars["String"];
  entitlements: Array<Entitlement>;
  environmentId: Scalars["String"];
  resourceId?: Maybe<Scalars["String"]>;
};

export enum EntitySelectionMode {
  BlackList = "BLACK_LIST",
  WhiteList = "WHITE_LIST",
}

export type Environment = {
  __typename?: "Environment";
  account?: Maybe<Account>;
  apiKeys: Array<ApiKey>;
  color?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  displayName: Scalars["String"];
  hardenClientAccessEnabled: Scalars["Boolean"];
  id: Scalars["String"];
  isSandbox: Scalars["Boolean"];
  provisionStatus?: Maybe<EnvironmentProvisionStatus>;
  signingToken: Scalars["String"];
  slug: Scalars["String"];
};

export type EnvironmentApiKeysArgs = {
  filter?: InputMaybe<ApiKeyFilter>;
  sorting?: InputMaybe<Array<ApiKeySort>>;
};

export type EnvironmentAggregateGroupBy = {
  __typename?: "EnvironmentAggregateGroupBy";
  createdAt?: Maybe<Scalars["DateTime"]>;
  displayName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
};

export type EnvironmentConnection = {
  __typename?: "EnvironmentConnection";
  /** Array of edges. */
  edges: Array<EnvironmentEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type EnvironmentCountAggregate = {
  __typename?: "EnvironmentCountAggregate";
  createdAt?: Maybe<Scalars["Int"]>;
  displayName?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["Int"]>;
};

export type EnvironmentDeleteResponse = {
  __typename?: "EnvironmentDeleteResponse";
  color?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  hardenClientAccessEnabled?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["String"]>;
  isSandbox?: Maybe<Scalars["Boolean"]>;
  provisionStatus?: Maybe<EnvironmentProvisionStatus>;
  signingToken?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
};

export type EnvironmentEdge = {
  __typename?: "EnvironmentEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the Environment */
  node: Environment;
};

export type EnvironmentFilter = {
  and?: InputMaybe<Array<EnvironmentFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<EnvironmentFilter>>;
  slug?: InputMaybe<StringFieldComparison>;
};

export type EnvironmentInput = {
  color?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  hardenClientAccessEnabled?: InputMaybe<Scalars["Boolean"]>;
  provisionStatus?: InputMaybe<EnvironmentProvisionStatus>;
};

export type EnvironmentMaxAggregate = {
  __typename?: "EnvironmentMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  displayName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
};

export type EnvironmentMinAggregate = {
  __typename?: "EnvironmentMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  displayName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
};

export type EnvironmentMissingError = {
  __typename?: "EnvironmentMissingError";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
};

/** EnvironmentProvisionStatus. */
export enum EnvironmentProvisionStatus {
  Done = "DONE",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
  NotProvisioned = "NOT_PROVISIONED",
}

export type EnvironmentSort = {
  direction: SortDirection;
  field: EnvironmentSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum EnvironmentSortFields {
  CreatedAt = "createdAt",
  DisplayName = "displayName",
  Id = "id",
  Slug = "slug",
}

/** error codes */
export enum ErrorCode {
  AccountNotFoundError = "AccountNotFoundError",
  AddonHasToHavePriceError = "AddonHasToHavePriceError",
  AddonNotFound = "AddonNotFound",
  ArchivedCouponCantBeApplied = "ArchivedCouponCantBeApplied",
  AuthCustomerMismatch = "AuthCustomerMismatch",
  BadUserInput = "BadUserInput",
  BillingPeriodMissingError = "BillingPeriodMissingError",
  CannotDeleteCustomerError = "CannotDeleteCustomerError",
  CannotDeleteFeatureError = "CannotDeleteFeatureError",
  CannotDeleteProductError = "CannotDeleteProductError",
  CannotEditPackageInNonDraftMode = "CannotEditPackageInNonDraftMode",
  CheckoutIsNotSupported = "CheckoutIsNotSupported",
  CheckoutOptionsMissing = "CheckoutOptionsMissing",
  CouponNotFound = "CouponNotFound",
  CustomerAlreadyHaveCustomerCoupon = "CustomerAlreadyHaveCustomerCoupon",
  CustomerAlreadyUsesCoupon = "CustomerAlreadyUsesCoupon",
  CustomerHasNoPaymentMethod = "CustomerHasNoPaymentMethod",
  CustomerNoBillingId = "CustomerNoBillingId",
  CustomerNotFound = "CustomerNotFound",
  CustomerResourceNotFound = "CustomerResourceNotFound",
  DowngradeBillingPeriodNotSupportedError = "DowngradeBillingPeriodNotSupportedError",
  DraftPlanCantBeArchived = "DraftPlanCantBeArchived",
  DuplicatedEntityNotAllowed = "DuplicatedEntityNotAllowed",
  EditAllowedOnDraftPackageOnlyError = "EditAllowedOnDraftPackageOnlyError",
  EntitlementsMustBelongToSamePackage = "EntitlementsMustBelongToSamePackage",
  EntityIdDifferentFromRefIdError = "EntityIdDifferentFromRefIdError",
  EnvironmentMissing = "EnvironmentMissing",
  ExperimentAlreadyRunning = "ExperimentAlreadyRunning",
  ExperimentNotFoundError = "ExperimentNotFoundError",
  ExperimentStatusError = "ExperimentStatusError",
  FailedToCreateCheckoutSessionError = "FailedToCreateCheckoutSessionError",
  FailedToImportCustomer = "FailedToImportCustomer",
  FeatureNotFound = "FeatureNotFound",
  FetchAllCountriesPricesNotAllowed = "FetchAllCountriesPricesNotAllowed",
  IdentityForbidden = "IdentityForbidden",
  ImportAlreadyInProgress = "ImportAlreadyInProgress",
  InitStripePaymentMethodError = "InitStripePaymentMethodError",
  IntegrationNotFound = "IntegrationNotFound",
  IntegrityViolation = "IntegrityViolation",
  InvalidAddressError = "InvalidAddressError",
  InvalidArgumentError = "InvalidArgumentError",
  InvalidCancellationDate = "InvalidCancellationDate",
  InvalidEntitlementResetPeriod = "InvalidEntitlementResetPeriod",
  InvalidMemberDelete = "InvalidMemberDelete",
  InvalidQuantity = "InvalidQuantity",
  InvalidSubscriptionStatus = "InvalidSubscriptionStatus",
  InvalidUpdatePriceUnitAmountError = "InvalidUpdatePriceUnitAmountError",
  InvalidUsageValueForIncrementalFeatureError = "InvalidUsageValueForIncrementalFeatureError",
  MemberInvitationError = "MemberInvitationError",
  MemberNotFound = "MemberNotFound",
  MeteringNotAvailableForFeatureType = "MeteringNotAvailableForFeatureType",
  MissingEntityIdError = "MissingEntityIdError",
  NoFeatureEntitlementInSubscription = "NoFeatureEntitlementInSubscription",
  NoProductsAvailable = "NoProductsAvailable",
  OperationNotAllowedDuringInProgressExperiment = "OperationNotAllowedDuringInProgressExperiment",
  PackageAlreadyPublished = "PackageAlreadyPublished",
  PackagePricingTypeNotSet = "PackagePricingTypeNotSet",
  PlanAlreadyExtended = "PlanAlreadyExtended",
  PlanCannotBePublishWhenBasePlanIsDraft = "PlanCannotBePublishWhenBasePlanIsDraft",
  PlanIsUsedAsDefaultStartPlan = "PlanIsUsedAsDefaultStartPlan",
  PlanIsUsedAsDowngradePlan = "PlanIsUsedAsDowngradePlan",
  PlanNotFound = "PlanNotFound",
  PlanWithChildCantBeDeleted = "PlanWithChildCantBeDeleted",
  PlansCircularDependencyError = "PlansCircularDependencyError",
  PriceNotFound = "PriceNotFound",
  PromotionCodeCustomerNotFirstPurchase = "PromotionCodeCustomerNotFirstPurchase",
  PromotionCodeMaxRedemptionsReached = "PromotionCodeMaxRedemptionsReached",
  PromotionCodeMinimumAmountNotReached = "PromotionCodeMinimumAmountNotReached",
  PromotionCodeNotActive = "PromotionCodeNotActive",
  PromotionCodeNotForCustomer = "PromotionCodeNotForCustomer",
  PromotionCodeNotFound = "PromotionCodeNotFound",
  RateLimitExceeded = "RateLimitExceeded",
  ResyncAlreadyInProgress = "ResyncAlreadyInProgress",
  SelectedBillingModelDoesntMatchImportedItemError = "SelectedBillingModelDoesntMatchImportedItemError",
  StripeCustomerIsDeleted = "StripeCustomerIsDeleted",
  SubscriptionAlreadyCanceledOrExpired = "SubscriptionAlreadyCanceledOrExpired",
  SubscriptionMustHaveSinglePlanError = "SubscriptionMustHaveSinglePlanError",
  SubscriptionNotFound = "SubscriptionNotFound",
  TrialMinDateError = "TrialMinDateError",
  TrialMustBeCancelledImmediately = "TrialMustBeCancelledImmediately",
  UnPublishedPackage = "UnPublishedPackage",
  Unauthenticated = "Unauthenticated",
  UncompatibleSubscriptionAddon = "UncompatibleSubscriptionAddon",
  UnexpectedError = "UnexpectedError",
  UnsupportedFeatureType = "UnsupportedFeatureType",
  UnsupportedSubscriptionScheduleType = "UnsupportedSubscriptionScheduleType",
  UnsupportedVendorIdentifier = "UnsupportedVendorIdentifier",
}

export type EstimateSubscriptionInput = {
  addons?: InputMaybe<Array<SubscriptionAddonInput>>;
  billingCountryCode?: InputMaybe<Scalars["String"]>;
  billingInformation?: InputMaybe<SubscriptionBillingInfo>;
  billingPeriod?: InputMaybe<BillingPeriod>;
  customerId: Scalars["String"];
  environmentId?: InputMaybe<Scalars["String"]>;
  planId: Scalars["String"];
  priceUnitAmount?: InputMaybe<Scalars["Float"]>;
  promotionCode?: InputMaybe<Scalars["String"]>;
  resourceId?: InputMaybe<Scalars["String"]>;
  skipTrial?: InputMaybe<Scalars["Boolean"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
};

export type EstimateSubscriptionUpdateInput = {
  addons?: InputMaybe<Array<SubscriptionAddonInput>>;
  environmentId?: InputMaybe<Scalars["String"]>;
  promotionCode?: InputMaybe<Scalars["String"]>;
  subscriptionId: Scalars["String"];
  unitQuantity?: InputMaybe<Scalars["Float"]>;
};

export type EventLog = {
  __typename?: "EventLog";
  description?: Maybe<Scalars["String"]>;
  environment?: Maybe<Environment>;
  environmentId: Scalars["String"];
  eventLogType: EventLogType;
  id: Scalars["String"];
  webhookEndpoints?: Maybe<Array<Scalars["String"]>>;
};

export type EventLogAggregateGroupBy = {
  __typename?: "EventLogAggregateGroupBy";
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
};

export type EventLogCountAggregate = {
  __typename?: "EventLogCountAggregate";
  environmentId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

export type EventLogEdge = {
  __typename?: "EventLogEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the EventLog */
  node: EventLog;
};

export type EventLogMaxAggregate = {
  __typename?: "EventLogMaxAggregate";
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
};

export type EventLogMinAggregate = {
  __typename?: "EventLogMinAggregate";
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
};

/** EventLogType */
export enum EventLogType {
  AddonCreated = "ADDON_CREATED",
  AddonDeleted = "ADDON_DELETED",
  AddonUpdated = "ADDON_UPDATED",
  CouponArchived = "COUPON_ARCHIVED",
  CouponCreated = "COUPON_CREATED",
  CouponUpdated = "COUPON_UPDATED",
  CreateSubscriptionFailed = "CREATE_SUBSCRIPTION_FAILED",
  CustomerCreated = "CUSTOMER_CREATED",
  CustomerDeleted = "CUSTOMER_DELETED",
  CustomerEntitlementCalculationTriggered = "CUSTOMER_ENTITLEMENT_CALCULATION_TRIGGERED",
  CustomerPaymentFailed = "CUSTOMER_PAYMENT_FAILED",
  CustomerResourceEntitlementCalculationTriggered = "CUSTOMER_RESOURCE_ENTITLEMENT_CALCULATION_TRIGGERED",
  CustomerUpdated = "CUSTOMER_UPDATED",
  EdgeApiDataResync = "EDGE_API_DATA_RESYNC",
  EntitlementsUpdated = "ENTITLEMENTS_UPDATED",
  EntitlementDenied = "ENTITLEMENT_DENIED",
  EntitlementGranted = "ENTITLEMENT_GRANTED",
  EntitlementRequested = "ENTITLEMENT_REQUESTED",
  EnvironmentDeleted = "ENVIRONMENT_DELETED",
  FeatureCreated = "FEATURE_CREATED",
  FeatureDeleted = "FEATURE_DELETED",
  FeatureUpdated = "FEATURE_UPDATED",
  ImportIntegrationCatalogTriggered = "IMPORT_INTEGRATION_CATALOG_TRIGGERED",
  ImportIntegrationCustomersTriggered = "IMPORT_INTEGRATION_CUSTOMERS_TRIGGERED",
  MeasurementReported = "MEASUREMENT_REPORTED",
  PackagePublished = "PACKAGE_PUBLISHED",
  PlanCreated = "PLAN_CREATED",
  PlanDeleted = "PLAN_DELETED",
  PlanUpdated = "PLAN_UPDATED",
  ProductCreated = "PRODUCT_CREATED",
  ProductDeleted = "PRODUCT_DELETED",
  ProductUpdated = "PRODUCT_UPDATED",
  PromotionalEntitlementExpired = "PROMOTIONAL_ENTITLEMENT_EXPIRED",
  PromotionalEntitlementGranted = "PROMOTIONAL_ENTITLEMENT_GRANTED",
  PromotionalEntitlementRevoked = "PROMOTIONAL_ENTITLEMENT_REVOKED",
  PromotionalEntitlementUpdated = "PROMOTIONAL_ENTITLEMENT_UPDATED",
  ResyncIntegrationTriggered = "RESYNC_INTEGRATION_TRIGGERED",
  SubscriptionCanceled = "SUBSCRIPTION_CANCELED",
  SubscriptionCreated = "SUBSCRIPTION_CREATED",
  SubscriptionExpired = "SUBSCRIPTION_EXPIRED",
  SubscriptionTrialConverted = "SUBSCRIPTION_TRIAL_CONVERTED",
  SubscriptionTrialEndsSoon = "SUBSCRIPTION_TRIAL_ENDS_SOON",
  SubscriptionTrialExpired = "SUBSCRIPTION_TRIAL_EXPIRED",
  SubscriptionTrialStarted = "SUBSCRIPTION_TRIAL_STARTED",
  SubscriptionUpdated = "SUBSCRIPTION_UPDATED",
  SubscriptionUsageUpdated = "SUBSCRIPTION_USAGE_UPDATED",
  SyncFailed = "SYNC_FAILED",
  WidgetConfigurationUpdated = "WIDGET_CONFIGURATION_UPDATED",
}

export type Experiment = {
  __typename?: "Experiment";
  controlGroupName: Scalars["String"];
  createdAt: Scalars["DateTime"];
  customers?: Maybe<Customer>;
  description?: Maybe<Scalars["String"]>;
  environment?: Maybe<Environment>;
  environmentId: Scalars["String"];
  id: Scalars["String"];
  initialProductSettings?: Maybe<ProductSettings>;
  name: Scalars["String"];
  product?: Maybe<Product>;
  productId: Scalars["String"];
  productSettings: ProductSettings;
  refId: Scalars["String"];
  startedAt?: Maybe<Scalars["DateTime"]>;
  status: ExperimentStatus;
  stoppedAt?: Maybe<Scalars["DateTime"]>;
  updatedAt: Scalars["DateTime"];
  variantGroupName: Scalars["String"];
  variantPercentage: Scalars["Float"];
};

export type ExperimentAggregateGroupBy = {
  __typename?: "ExperimentAggregateGroupBy";
  createdAt?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  productId?: Maybe<Scalars["String"]>;
  refId?: Maybe<Scalars["String"]>;
  status?: Maybe<ExperimentStatus>;
};

export type ExperimentAvgAggregate = {
  __typename?: "ExperimentAvgAggregate";
  id?: Maybe<Scalars["Float"]>;
};

export type ExperimentConnection = {
  __typename?: "ExperimentConnection";
  /** Array of edges. */
  edges: Array<ExperimentEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type ExperimentCountAggregate = {
  __typename?: "ExperimentCountAggregate";
  createdAt?: Maybe<Scalars["Int"]>;
  environmentId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["Int"]>;
  productId?: Maybe<Scalars["Int"]>;
  refId?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["Int"]>;
};

export type ExperimentEdge = {
  __typename?: "ExperimentEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the Experiment */
  node: Experiment;
};

export type ExperimentFilter = {
  and?: InputMaybe<Array<ExperimentFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  customers?: InputMaybe<ExperimentFilterCustomerFilter>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ExperimentFilter>>;
  productId?: InputMaybe<StringFieldComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<ExperimentStatusFilterComparison>;
};

export type ExperimentFilterCustomerFilter = {
  and?: InputMaybe<Array<ExperimentFilterCustomerFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  crmHubspotCompanyId?: InputMaybe<StringFieldComparison>;
  crmHubspotCompanyUrl?: InputMaybe<StringFieldComparison>;
  crmId?: InputMaybe<StringFieldComparison>;
  customerId?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ExperimentFilterCustomerFilter>>;
  refId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type ExperimentMaxAggregate = {
  __typename?: "ExperimentMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  productId?: Maybe<Scalars["String"]>;
  refId?: Maybe<Scalars["String"]>;
  status?: Maybe<ExperimentStatus>;
};

export type ExperimentMinAggregate = {
  __typename?: "ExperimentMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  productId?: Maybe<Scalars["String"]>;
  refId?: Maybe<Scalars["String"]>;
  status?: Maybe<ExperimentStatus>;
};

export type ExperimentSort = {
  direction: SortDirection;
  field: ExperimentSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ExperimentSortFields {
  CreatedAt = "createdAt",
  EnvironmentId = "environmentId",
  Id = "id",
  Name = "name",
  ProductId = "productId",
  RefId = "refId",
  Status = "status",
}

export type ExperimentStats = {
  __typename?: "ExperimentStats";
  controlPaidSubscriptions: Scalars["Float"];
  controlSubscriptions: Scalars["Float"];
  variantPaidSubscriptions: Scalars["Float"];
  variantSubscriptions: Scalars["Float"];
};

export type ExperimentStatsQuery = {
  environmentId?: InputMaybe<Scalars["String"]>;
  experimentRefId: Scalars["String"];
};

/** The status of the EXPERIMENT */
export enum ExperimentStatus {
  Completed = "COMPLETED",
  Draft = "DRAFT",
  InProgress = "IN_PROGRESS",
}

export type ExperimentStatusFilterComparison = {
  eq?: InputMaybe<ExperimentStatus>;
  gt?: InputMaybe<ExperimentStatus>;
  gte?: InputMaybe<ExperimentStatus>;
  iLike?: InputMaybe<ExperimentStatus>;
  in?: InputMaybe<Array<ExperimentStatus>>;
  is?: InputMaybe<Scalars["Boolean"]>;
  isNot?: InputMaybe<Scalars["Boolean"]>;
  like?: InputMaybe<ExperimentStatus>;
  lt?: InputMaybe<ExperimentStatus>;
  lte?: InputMaybe<ExperimentStatus>;
  neq?: InputMaybe<ExperimentStatus>;
  notILike?: InputMaybe<ExperimentStatus>;
  notIn?: InputMaybe<Array<ExperimentStatus>>;
  notLike?: InputMaybe<ExperimentStatus>;
};

export type ExperimentSumAggregate = {
  __typename?: "ExperimentSumAggregate";
  id?: Maybe<Scalars["Float"]>;
};

export type FailedToImportCustomerError = {
  __typename?: "FailedToImportCustomerError";
  billingId: Scalars["String"];
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
};

export type Feature = {
  __typename?: "Feature";
  account?: Maybe<Account>;
  additionalMetaData?: Maybe<Scalars["JSON"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  displayName: Scalars["String"];
  environment?: Maybe<Environment>;
  environmentId: Scalars["String"];
  featureStatus: FeatureStatus;
  featureType: FeatureType;
  featureUnits?: Maybe<Scalars["String"]>;
  featureUnitsPlural?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  meterType?: Maybe<MeterType>;
  refId: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type FeatureAggregateGroupBy = {
  __typename?: "FeatureAggregateGroupBy";
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  environmentId?: Maybe<Scalars["String"]>;
  featureStatus?: Maybe<FeatureStatus>;
  featureType?: Maybe<FeatureType>;
  id?: Maybe<Scalars["String"]>;
  meterType?: Maybe<MeterType>;
  refId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type FeatureConnection = {
  __typename?: "FeatureConnection";
  /** Array of edges. */
  edges: Array<FeatureEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type FeatureCountAggregate = {
  __typename?: "FeatureCountAggregate";
  createdAt?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["Int"]>;
  displayName?: Maybe<Scalars["Int"]>;
  environmentId?: Maybe<Scalars["Int"]>;
  featureStatus?: Maybe<Scalars["Int"]>;
  featureType?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  meterType?: Maybe<Scalars["Int"]>;
  refId?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["Int"]>;
};

export type FeatureEdge = {
  __typename?: "FeatureEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the Feature */
  node: Feature;
};

export type FeatureFilter = {
  and?: InputMaybe<Array<FeatureFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  featureStatus?: InputMaybe<FeatureStatusFilterComparison>;
  featureType?: InputMaybe<FeatureTypeFilterComparison>;
  id?: InputMaybe<StringFieldComparison>;
  meterType?: InputMaybe<MeterTypeFilterComparison>;
  or?: InputMaybe<Array<FeatureFilter>>;
  refId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type FeatureInput = {
  additionalMetaData?: InputMaybe<Scalars["JSON"]>;
  description?: InputMaybe<Scalars["String"]>;
  displayName: Scalars["String"];
  environmentId: Scalars["String"];
  featureStatus?: InputMaybe<FeatureStatus>;
  featureType: FeatureType;
  featureUnits?: InputMaybe<Scalars["String"]>;
  featureUnitsPlural?: InputMaybe<Scalars["String"]>;
  meterType?: InputMaybe<MeterType>;
  refId: Scalars["String"];
};

export type FeatureMaxAggregate = {
  __typename?: "FeatureMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  environmentId?: Maybe<Scalars["String"]>;
  featureStatus?: Maybe<FeatureStatus>;
  featureType?: Maybe<FeatureType>;
  id?: Maybe<Scalars["String"]>;
  meterType?: Maybe<MeterType>;
  refId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type FeatureMinAggregate = {
  __typename?: "FeatureMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  environmentId?: Maybe<Scalars["String"]>;
  featureStatus?: Maybe<FeatureStatus>;
  featureType?: Maybe<FeatureType>;
  id?: Maybe<Scalars["String"]>;
  meterType?: Maybe<MeterType>;
  refId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type FeatureNotFoundError = {
  __typename?: "FeatureNotFoundError";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
  refId: Scalars["String"];
};

export type FeatureSort = {
  direction: SortDirection;
  field: FeatureSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum FeatureSortFields {
  CreatedAt = "createdAt",
  Description = "description",
  DisplayName = "displayName",
  EnvironmentId = "environmentId",
  FeatureStatus = "featureStatus",
  FeatureType = "featureType",
  Id = "id",
  MeterType = "meterType",
  RefId = "refId",
  UpdatedAt = "updatedAt",
}

/** Feature status. */
export enum FeatureStatus {
  Active = "ACTIVE",
  New = "NEW",
  Suspended = "SUSPENDED",
}

export type FeatureStatusFilterComparison = {
  eq?: InputMaybe<FeatureStatus>;
  gt?: InputMaybe<FeatureStatus>;
  gte?: InputMaybe<FeatureStatus>;
  iLike?: InputMaybe<FeatureStatus>;
  in?: InputMaybe<Array<FeatureStatus>>;
  is?: InputMaybe<Scalars["Boolean"]>;
  isNot?: InputMaybe<Scalars["Boolean"]>;
  like?: InputMaybe<FeatureStatus>;
  lt?: InputMaybe<FeatureStatus>;
  lte?: InputMaybe<FeatureStatus>;
  neq?: InputMaybe<FeatureStatus>;
  notILike?: InputMaybe<FeatureStatus>;
  notIn?: InputMaybe<Array<FeatureStatus>>;
  notLike?: InputMaybe<FeatureStatus>;
};

/** The type of the feature */
export enum FeatureType {
  Boolean = "BOOLEAN",
  Number = "NUMBER",
}

export type FeatureTypeFilterComparison = {
  eq?: InputMaybe<FeatureType>;
  gt?: InputMaybe<FeatureType>;
  gte?: InputMaybe<FeatureType>;
  iLike?: InputMaybe<FeatureType>;
  in?: InputMaybe<Array<FeatureType>>;
  is?: InputMaybe<Scalars["Boolean"]>;
  isNot?: InputMaybe<Scalars["Boolean"]>;
  like?: InputMaybe<FeatureType>;
  lt?: InputMaybe<FeatureType>;
  lte?: InputMaybe<FeatureType>;
  neq?: InputMaybe<FeatureType>;
  notILike?: InputMaybe<FeatureType>;
  notIn?: InputMaybe<Array<FeatureType>>;
  notLike?: InputMaybe<FeatureType>;
};

export type FetchEntitlementQuery = {
  customerId: Scalars["String"];
  environmentId?: InputMaybe<Scalars["String"]>;
  featureId: Scalars["String"];
  options?: InputMaybe<EntitlementOptions>;
  resourceId?: InputMaybe<Scalars["String"]>;
};

export type FetchEntitlementsQuery = {
  customerId: Scalars["String"];
  environmentId?: InputMaybe<Scalars["String"]>;
  resourceId?: InputMaybe<Scalars["String"]>;
};

export type FontVariant = {
  __typename?: "FontVariant";
  fontSize?: Maybe<Scalars["Float"]>;
  fontWeight?: Maybe<FontWeight>;
};

export type FontVariantInput = {
  fontSize?: InputMaybe<Scalars["Float"]>;
  fontWeight?: InputMaybe<FontWeight>;
};

/** Font weight */
export enum FontWeight {
  Bold = "BOLD",
  Normal = "NORMAL",
}

export type GetActiveSubscriptionsInput = {
  customerId: Scalars["String"];
  environmentId?: InputMaybe<Scalars["String"]>;
  resourceId?: InputMaybe<Scalars["String"]>;
};

export type GetCustomerByRefIdInput = {
  customerId: Scalars["String"];
  environmentId?: InputMaybe<Scalars["String"]>;
};

export type GetPackageByRefIdInput = {
  environmentId: Scalars["String"];
  refId: Scalars["String"];
  versionNumber?: InputMaybe<Scalars["Float"]>;
};

export type GetPaywallInput = {
  billingCountryCode?: InputMaybe<Scalars["String"]>;
  context?: InputMaybe<WidgetType>;
  customerId?: InputMaybe<Scalars["String"]>;
  environmentId?: InputMaybe<Scalars["String"]>;
  fetchAllCountriesPrices?: InputMaybe<Scalars["Boolean"]>;
  productId?: InputMaybe<Scalars["String"]>;
  resourceId?: InputMaybe<Scalars["String"]>;
};

export type GetWidgetConfigurationInput = {
  environmentId?: InputMaybe<Scalars["String"]>;
};

export type HiddenFromWidgetsChange = {
  __typename?: "HiddenFromWidgetsChange";
  after?: Maybe<Array<WidgetType>>;
  before?: Maybe<Array<WidgetType>>;
  changeType?: Maybe<ChangeType>;
};

export type Hook = {
  __typename?: "Hook";
  account?: Maybe<Account>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  endpoint: Scalars["String"];
  environment?: Maybe<Environment>;
  environmentId: Scalars["String"];
  eventLogTypes: Array<EventLogType>;
  id: Scalars["String"];
  secretKey?: Maybe<Scalars["String"]>;
  status: HookStatus;
};

export type HookAggregateGroupBy = {
  __typename?: "HookAggregateGroupBy";
  createdAt?: Maybe<Scalars["DateTime"]>;
  endpoint?: Maybe<Scalars["String"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  status?: Maybe<HookStatus>;
};

export type HookConnection = {
  __typename?: "HookConnection";
  /** Array of edges. */
  edges: Array<HookEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type HookCountAggregate = {
  __typename?: "HookCountAggregate";
  createdAt?: Maybe<Scalars["Int"]>;
  endpoint?: Maybe<Scalars["Int"]>;
  environmentId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["Int"]>;
};

export type HookDeleteResponse = {
  __typename?: "HookDeleteResponse";
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  endpoint?: Maybe<Scalars["String"]>;
  environmentId?: Maybe<Scalars["String"]>;
  eventLogTypes?: Maybe<Array<EventLogType>>;
  id?: Maybe<Scalars["String"]>;
  secretKey?: Maybe<Scalars["String"]>;
  status?: Maybe<HookStatus>;
};

export type HookEdge = {
  __typename?: "HookEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the Hook */
  node: Hook;
};

export type HookFilter = {
  and?: InputMaybe<Array<HookFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  endpoint?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<HookFilter>>;
  status?: InputMaybe<HookStatusFilterComparison>;
};

export type HookMaxAggregate = {
  __typename?: "HookMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  endpoint?: Maybe<Scalars["String"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  status?: Maybe<HookStatus>;
};

export type HookMinAggregate = {
  __typename?: "HookMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  endpoint?: Maybe<Scalars["String"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  status?: Maybe<HookStatus>;
};

export type HookSort = {
  direction: SortDirection;
  field: HookSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum HookSortFields {
  CreatedAt = "createdAt",
  Endpoint = "endpoint",
  EnvironmentId = "environmentId",
  Id = "id",
  Status = "status",
}

/** HookStatus. */
export enum HookStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export type HookStatusFilterComparison = {
  eq?: InputMaybe<HookStatus>;
  gt?: InputMaybe<HookStatus>;
  gte?: InputMaybe<HookStatus>;
  iLike?: InputMaybe<HookStatus>;
  in?: InputMaybe<Array<HookStatus>>;
  is?: InputMaybe<Scalars["Boolean"]>;
  isNot?: InputMaybe<Scalars["Boolean"]>;
  like?: InputMaybe<HookStatus>;
  lt?: InputMaybe<HookStatus>;
  lte?: InputMaybe<HookStatus>;
  neq?: InputMaybe<HookStatus>;
  notILike?: InputMaybe<HookStatus>;
  notIn?: InputMaybe<Array<HookStatus>>;
  notLike?: InputMaybe<HookStatus>;
};

export type HubspotCredentials = {
  __typename?: "HubspotCredentials";
  hubDomain: Scalars["String"];
};

export type HubspotCredentialsInput = {
  authorizationCode: Scalars["String"];
  refreshToken?: InputMaybe<Scalars["String"]>;
};

export type IdentityForbiddenError = {
  __typename?: "IdentityForbiddenError";
  accessedField: Scalars["String"];
  code: Scalars["String"];
  currentIdentityType: Scalars["String"];
  isValidationError: Scalars["Boolean"];
  requiredIdentityType: Scalars["String"];
};

export type ImportAlreadyInProgressError = {
  __typename?: "ImportAlreadyInProgressError";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
};

export type ImportCustomerBulk = {
  customers: Array<ImportCustomerInput>;
  environmentId?: InputMaybe<Scalars["String"]>;
};

export type ImportCustomerInput = {
  additionalMetaData?: InputMaybe<Scalars["JSON"]>;
  billingId?: InputMaybe<Scalars["String"]>;
  customerId?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  environmentId?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  paymentMethodId?: InputMaybe<Scalars["String"]>;
  refId?: InputMaybe<Scalars["String"]>;
};

export type ImportIntegrationCatalogInput = {
  billingModel?: InputMaybe<BillingModel>;
  entitySelectionMode: EntitySelectionMode;
  environmentId: Scalars["String"];
  featureUnitName?: InputMaybe<Scalars["String"]>;
  featureUnitPluralName?: InputMaybe<Scalars["String"]>;
  plansSelectionBlacklist?: InputMaybe<Array<Scalars["String"]>>;
  plansSelectionWhitelist?: InputMaybe<Array<Scalars["String"]>>;
  productId: Scalars["String"];
  selectedAddonBillingIds: Array<Scalars["String"]>;
  vendorIdentifier: VendorIdentifier;
};

export type ImportIntegrationCustomersInput = {
  customersSelectionBlacklist?: InputMaybe<Array<Scalars["String"]>>;
  customersSelectionWhitelist?: InputMaybe<Array<Scalars["String"]>>;
  entitySelectionMode: EntitySelectionMode;
  environmentId: Scalars["String"];
  productId: Scalars["String"];
  vendorIdentifier: VendorIdentifier;
};

export type ImportIntegrationTask = {
  __typename?: "ImportIntegrationTask";
  createdAt?: Maybe<Scalars["DateTime"]>;
  customersCount: Scalars["Int"];
  endDate?: Maybe<Scalars["DateTime"]>;
  environmentId: Scalars["String"];
  id: Scalars["String"];
  importErrors: Array<ImportSubTaskError>;
  productsCount: Scalars["Int"];
  progress: Scalars["Int"];
  startDate: Scalars["DateTime"];
  status: TaskStatus;
  taskType: TaskType;
  totalSubtasksCount: Scalars["Int"];
};

export type ImportIntegrationTaskAggregateGroupBy = {
  __typename?: "ImportIntegrationTaskAggregateGroupBy";
  createdAt?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  status?: Maybe<TaskStatus>;
  taskType?: Maybe<TaskType>;
};

export type ImportIntegrationTaskConnection = {
  __typename?: "ImportIntegrationTaskConnection";
  /** Array of edges. */
  edges: Array<ImportIntegrationTaskEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type ImportIntegrationTaskCountAggregate = {
  __typename?: "ImportIntegrationTaskCountAggregate";
  createdAt?: Maybe<Scalars["Int"]>;
  environmentId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["Int"]>;
  taskType?: Maybe<Scalars["Int"]>;
};

export type ImportIntegrationTaskEdge = {
  __typename?: "ImportIntegrationTaskEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the ImportIntegrationTask */
  node: ImportIntegrationTask;
};

export type ImportIntegrationTaskFilter = {
  and?: InputMaybe<Array<ImportIntegrationTaskFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ImportIntegrationTaskFilter>>;
  status?: InputMaybe<TaskStatusFilterComparison>;
  taskType?: InputMaybe<TaskTypeFilterComparison>;
};

export type ImportIntegrationTaskMaxAggregate = {
  __typename?: "ImportIntegrationTaskMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  status?: Maybe<TaskStatus>;
  taskType?: Maybe<TaskType>;
};

export type ImportIntegrationTaskMinAggregate = {
  __typename?: "ImportIntegrationTaskMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  status?: Maybe<TaskStatus>;
  taskType?: Maybe<TaskType>;
};

export type ImportIntegrationTaskSort = {
  direction: SortDirection;
  field: ImportIntegrationTaskSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ImportIntegrationTaskSortFields {
  CreatedAt = "createdAt",
  EnvironmentId = "environmentId",
  Id = "id",
  Status = "status",
  TaskType = "taskType",
}

export type ImportSubTaskError = {
  __typename?: "ImportSubTaskError";
  error: Scalars["String"];
  id: Scalars["String"];
};

export type ImportSubscriptionInput = {
  additionalMetaData?: InputMaybe<Scalars["JSON"]>;
  billingId?: InputMaybe<Scalars["String"]>;
  billingPeriod?: InputMaybe<BillingPeriod>;
  customerId: Scalars["String"];
  planId: Scalars["String"];
  resourceId?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  unitQuantity?: InputMaybe<Scalars["Float"]>;
};

export type ImportSubscriptionsBulk = {
  environmentId?: InputMaybe<Scalars["String"]>;
  subscriptions: Array<ImportSubscriptionInput>;
};

export type InitAddStripeCustomerPaymentMethod = {
  __typename?: "InitAddStripeCustomerPaymentMethod";
  paymentIntentClientSecret: Scalars["String"];
};

export type InitAddStripeCustomerPaymentMethodInput = {
  customerRefId: Scalars["String"];
  environmentId?: InputMaybe<Scalars["String"]>;
};

export type InitStripePaymentMethodError = {
  __typename?: "InitStripePaymentMethodError";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
};

export type InitiateCheckoutInput = {
  addons?: InputMaybe<Array<SubscriptionAddonInput>>;
  allowPromoCodes?: InputMaybe<Scalars["Boolean"]>;
  allowTaxIdCollection?: InputMaybe<Scalars["Boolean"]>;
  billingCountryCode?: InputMaybe<Scalars["String"]>;
  billingPeriod: BillingPeriod;
  cancelUrl: Scalars["String"];
  collectBillingAddress?: InputMaybe<Scalars["Boolean"]>;
  collectPhoneNumber?: InputMaybe<Scalars["Boolean"]>;
  customerId: Scalars["String"];
  planId: Scalars["String"];
  resourceId?: InputMaybe<Scalars["String"]>;
  successUrl: Scalars["String"];
  unitQuantity?: InputMaybe<Scalars["Int"]>;
};

export type IntFieldComparison = {
  between?: InputMaybe<IntFieldComparisonBetween>;
  eq?: InputMaybe<Scalars["Int"]>;
  gt?: InputMaybe<Scalars["Int"]>;
  gte?: InputMaybe<Scalars["Int"]>;
  in?: InputMaybe<Array<Scalars["Int"]>>;
  is?: InputMaybe<Scalars["Boolean"]>;
  isNot?: InputMaybe<Scalars["Boolean"]>;
  lt?: InputMaybe<Scalars["Int"]>;
  lte?: InputMaybe<Scalars["Int"]>;
  neq?: InputMaybe<Scalars["Int"]>;
  notBetween?: InputMaybe<IntFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type IntFieldComparisonBetween = {
  lower: Scalars["Int"];
  upper: Scalars["Int"];
};

export type Integration = {
  __typename?: "Integration";
  account?: Maybe<Account>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  credentials?: Maybe<Credentials>;
  environment?: Maybe<Environment>;
  environmentId: Scalars["String"];
  id: Scalars["String"];
  vendorIdentifier: VendorIdentifier;
};

export type IntegrationAggregateGroupBy = {
  __typename?: "IntegrationAggregateGroupBy";
  createdAt?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  vendorIdentifier?: Maybe<VendorIdentifier>;
};

export type IntegrationConnection = {
  __typename?: "IntegrationConnection";
  /** Array of edges. */
  edges: Array<IntegrationEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type IntegrationCountAggregate = {
  __typename?: "IntegrationCountAggregate";
  createdAt?: Maybe<Scalars["Int"]>;
  environmentId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  vendorIdentifier?: Maybe<Scalars["Int"]>;
};

export type IntegrationDeleteResponse = {
  __typename?: "IntegrationDeleteResponse";
  createdAt?: Maybe<Scalars["DateTime"]>;
  credentials?: Maybe<Credentials>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  vendorIdentifier?: Maybe<VendorIdentifier>;
};

export type IntegrationEdge = {
  __typename?: "IntegrationEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the Integration */
  node: Integration;
};

export type IntegrationFilter = {
  and?: InputMaybe<Array<IntegrationFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<IntegrationFilter>>;
  vendorIdentifier?: InputMaybe<VendorIdentifierFilterComparison>;
};

export type IntegrationMaxAggregate = {
  __typename?: "IntegrationMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  vendorIdentifier?: Maybe<VendorIdentifier>;
};

export type IntegrationMinAggregate = {
  __typename?: "IntegrationMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  vendorIdentifier?: Maybe<VendorIdentifier>;
};

export type IntegrationSort = {
  direction: SortDirection;
  field: IntegrationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum IntegrationSortFields {
  CreatedAt = "createdAt",
  EnvironmentId = "environmentId",
  Id = "id",
  VendorIdentifier = "vendorIdentifier",
}

export type InvalidArgumentError = {
  __typename?: "InvalidArgumentError";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
};

export type InvalidCancellationDate = {
  __typename?: "InvalidCancellationDate";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
  refId: Scalars["String"];
};

export type InvalidEntitlementResetPeriodError = {
  __typename?: "InvalidEntitlementResetPeriodError";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
};

export type InvalidMemberDeleteError = {
  __typename?: "InvalidMemberDeleteError";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
};

export type InvalidSubscriptionStatus = {
  __typename?: "InvalidSubscriptionStatus";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
};

export type InvalidUsageValueForIncrementalFeatureError = {
  __typename?: "InvalidUsageValueForIncrementalFeatureError";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
  usageValue: Scalars["Float"];
};

export type Member = {
  __typename?: "Member";
  account: Account;
  createdAt?: Maybe<Scalars["DateTime"]>;
  cubejsToken?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  hideGettingStartedPage?: Maybe<Scalars["Boolean"]>;
  id: Scalars["String"];
  memberStatus: MemberStatus;
  serviceApiKey?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
};

export type MemberAggregateGroupBy = {
  __typename?: "MemberAggregateGroupBy";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
};

export type MemberConnection = {
  __typename?: "MemberConnection";
  /** Array of edges. */
  edges: Array<MemberEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type MemberCountAggregate = {
  __typename?: "MemberCountAggregate";
  createdAt?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

export type MemberEdge = {
  __typename?: "MemberEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the Member */
  node: Member;
};

export type MemberFilter = {
  and?: InputMaybe<Array<MemberFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<MemberFilter>>;
};

export type MemberInvitationError = {
  __typename?: "MemberInvitationError";
  code: Scalars["String"];
  reason: Scalars["String"];
};

export type MemberMaxAggregate = {
  __typename?: "MemberMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
};

export type MemberMinAggregate = {
  __typename?: "MemberMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
};

export type MemberNotFoundError = {
  __typename?: "MemberNotFoundError";
  code: Scalars["String"];
};

export type MemberSort = {
  direction: SortDirection;
  field: MemberSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum MemberSortFields {
  CreatedAt = "createdAt",
  Id = "id",
}

/** Member Status. */
export enum MemberStatus {
  Invited = "INVITED",
  Registered = "REGISTERED",
}

export type MembersInviteResponse = {
  __typename?: "MembersInviteResponse";
  failedInvites: Array<Scalars["String"]>;
  skippedInvites: Array<Scalars["String"]>;
  successInvites: Array<Scalars["String"]>;
};

/** The meter type of the feature */
export enum MeterType {
  Fluctuating = "Fluctuating",
  Incremental = "Incremental",
  None = "None",
}

export type MeterTypeFilterComparison = {
  eq?: InputMaybe<MeterType>;
  gt?: InputMaybe<MeterType>;
  gte?: InputMaybe<MeterType>;
  iLike?: InputMaybe<MeterType>;
  in?: InputMaybe<Array<MeterType>>;
  is?: InputMaybe<Scalars["Boolean"]>;
  isNot?: InputMaybe<Scalars["Boolean"]>;
  like?: InputMaybe<MeterType>;
  lt?: InputMaybe<MeterType>;
  lte?: InputMaybe<MeterType>;
  neq?: InputMaybe<MeterType>;
  notILike?: InputMaybe<MeterType>;
  notIn?: InputMaybe<Array<MeterType>>;
  notLike?: InputMaybe<MeterType>;
};

export type MeteringNotAvailableForFeatureTypeError = {
  __typename?: "MeteringNotAvailableForFeatureTypeError";
  code: Scalars["String"];
  featureType: Scalars["String"];
  isValidationError: Scalars["Boolean"];
};

export type MockPaywall = {
  __typename?: "MockPaywall";
  configuration?: Maybe<PaywallConfiguration>;
  plans: Array<PaywallPlan>;
};

export type Money = {
  __typename?: "Money";
  amount: Scalars["Float"];
  currency: Currency;
};

export type MoneyInputDto = {
  amount: Scalars["Float"];
  currency?: InputMaybe<Currency>;
};

/** Montly reset period according to configuration */
export enum MonthlyAccordingTo {
  StartOfTheMonth = "StartOfTheMonth",
  SubscriptionStart = "SubscriptionStart",
}

export type MonthlyResetPeriodConfig = {
  __typename?: "MonthlyResetPeriodConfig";
  monthlyAccordingTo?: Maybe<MonthlyAccordingTo>;
};

export type MonthlyResetPeriodConfigInput = {
  accordingTo: MonthlyAccordingTo;
};

export type Mutation = {
  __typename?: "Mutation";
  addCompatibleAddonsToPlan: Plan;
  archiveOneCoupon: Coupon;
  archivePlan: Plan;
  attachCustomerPaymentMethod: Customer;
  cancelSchedule: Scalars["String"];
  cancelSubscription: CustomerSubscription;
  createAccount: Member;
  createAddonDraft: Addon;
  createFeature: Feature;
  createManyPackageEntitlements: Array<PackageEntitlement>;
  createManyPromotionalEntitlements: Array<PromotionalEntitlement>;
  createOneAddon: Addon;
  createOneCoupon: Coupon;
  /** @deprecated Use 'provisionCustomer' instead to create both customer and subscription in single operation. See 'provisionCustomer' for more info. */
  createOneCustomer: Customer;
  createOneEnvironment: Environment;
  createOneExperiment: Experiment;
  createOneFeature: Feature;
  createOneHook: Hook;
  createOneIntegration: Integration;
  createOnePlan: Plan;
  createOneProduct: Product;
  createPlanDraft: Plan;
  createSubscription: CustomerSubscription;
  createUsageMeasurement: UsageMeasurementWithCurrentUsage;
  deleteEnvironment: Scalars["String"];
  deleteFeature: Feature;
  deleteOneAddon: AddonDeleteResponse;
  deleteOneCustomer: Customer;
  deleteOneEnvironment: EnvironmentDeleteResponse;
  deleteOneFeature: Feature;
  deleteOneHook: HookDeleteResponse;
  deleteOneIntegration: IntegrationDeleteResponse;
  deleteOnePackageEntitlement: PackageEntitlementDeleteResponse;
  deleteOnePrice: PriceDeleteResponse;
  deleteOneProduct: ProductDeleteResponse;
  deleteOnePromotionalEntitlement: PromotionalEntitlementDeleteResponse;
  estimateSubscription: SubscriptionPreview;
  estimateSubscriptionUpdate: SubscriptionPreview;
  hideGettingStartedPage: Scalars["String"];
  importCustomersBulk?: Maybe<Scalars["String"]>;
  importOneCustomer: Customer;
  importSubscriptionsBulk?: Maybe<Scalars["String"]>;
  initAddStripeCustomerPaymentMethod: InitAddStripeCustomerPaymentMethod;
  /** @deprecated Use 'provisionSubscription' instead to create subscription or checkout session according to customer and subscription payment requirements. See 'provisionSubscription' for more info. */
  initiateCheckout: Checkout;
  inviteMembers: MembersInviteResponse;
  migrateSubscriptionToLatest: CustomerSubscription;
  provisionCustomer: ProvisionedCustomer;
  provisionSandbox: Environment;
  provisionSubscription: ProvisionSubscriptionResult;
  provisionSubscriptionV2: ProvisionSubscriptionResult;
  publishAddon: PublishPackageResult;
  publishPlan: PublishPackageResult;
  purgeCustomerCache: Scalars["String"];
  registerMember: User;
  removeAddonDraft: Scalars["String"];
  removeBasePlanFromPlan: Plan;
  removeCompatibleAddonsFromPlan: Plan;
  removeCouponFromCustomer: Customer;
  removeCouponFromCustomerSubscription: CustomerSubscription;
  removeExperimentFromCustomer: Customer;
  removeExperimentFromCustomerSubscription: CustomerSubscription;
  removeMember: Scalars["String"];
  removePlanDraft: Scalars["String"];
  reportEntitlementCheckRequested: Scalars["Boolean"];
  resendEmailVerification: Scalars["String"];
  resyncIntegration: ResyncIntegrationResult;
  setBasePlanOnPlan: Plan;
  setCompatibleAddonsOnPlan: Plan;
  setCouponOnCustomer: Customer;
  setCouponOnCustomerSubscription: CustomerSubscription;
  setExperimentOnCustomer: Customer;
  setExperimentOnCustomerSubscription: CustomerSubscription;
  setPackagePricing: PackagePrice;
  setWidgetConfiguration?: Maybe<Scalars["String"]>;
  startExperiment: Scalars["String"];
  stopExperiment: Scalars["String"];
  triggerImportCatalog: AsyncTaskResult;
  triggerImportCustomers: AsyncTaskResult;
  updateAccount: Account;
  updateEntitlementsOrder: Array<UpdateEntitlementsOrderDto>;
  updateFeature: Feature;
  updateOneAddon: Addon;
  updateOneCoupon: Coupon;
  updateOneCustomer: Customer;
  updateOneEnvironment: Environment;
  updateOneExperiment: Experiment;
  updateOneFeature: Feature;
  updateOneHook: Hook;
  updateOneIntegration: Integration;
  updateOnePackageEntitlement: PackageEntitlement;
  updateOnePlan: Plan;
  updateOneProduct: Product;
  updateOnePromotionalEntitlement: PromotionalEntitlement;
  updateOneSubscription: CustomerSubscription;
  updateUser: User;
};

export type MutationAddCompatibleAddonsToPlanArgs = {
  input: AddCompatibleAddonsToPlanInput;
};

export type MutationArchiveOneCouponArgs = {
  input: ArchiveCouponInput;
};

export type MutationArchivePlanArgs = {
  input: ArchivePlanInput;
};

export type MutationAttachCustomerPaymentMethodArgs = {
  input: AttachCustomerPaymentMethodInput;
};

export type MutationCancelScheduleArgs = {
  input: SubscriptionUpdateScheduleCancellationInput;
};

export type MutationCancelSubscriptionArgs = {
  input: SubscriptionCancellationInput;
};

export type MutationCreateAccountArgs = {
  accountName: Scalars["String"];
};

export type MutationCreateAddonDraftArgs = {
  id: Scalars["String"];
};

export type MutationCreateFeatureArgs = {
  input: FeatureInput;
};

export type MutationCreateManyPackageEntitlementsArgs = {
  input: CreateManyPackageEntitlementsInput;
};

export type MutationCreateManyPromotionalEntitlementsArgs = {
  input: CreateManyPromotionalEntitlementsInput;
};

export type MutationCreateOneAddonArgs = {
  input: AddonCreateInput;
};

export type MutationCreateOneCouponArgs = {
  input: CreateCouponInput;
};

export type MutationCreateOneCustomerArgs = {
  input: CustomerInput;
};

export type MutationCreateOneEnvironmentArgs = {
  input: CreateOneEnvironmentInput;
};

export type MutationCreateOneExperimentArgs = {
  input: CreateExperimentInput;
};

export type MutationCreateOneFeatureArgs = {
  input: CreateOneFeatureInput;
};

export type MutationCreateOneHookArgs = {
  input: CreateOneHookInput;
};

export type MutationCreateOneIntegrationArgs = {
  input: CreateOneIntegrationInput;
};

export type MutationCreateOnePlanArgs = {
  input: PlanCreateInput;
};

export type MutationCreateOneProductArgs = {
  input: CreateOneProductInput;
};

export type MutationCreatePlanDraftArgs = {
  id: Scalars["String"];
};

export type MutationCreateSubscriptionArgs = {
  subscription: SubscriptionInput;
};

export type MutationCreateUsageMeasurementArgs = {
  usageMeasurement: UsageMeasurementCreateInput;
};

export type MutationDeleteEnvironmentArgs = {
  slug: Scalars["String"];
};

export type MutationDeleteFeatureArgs = {
  input: DeleteFeatureInput;
};

export type MutationDeleteOneAddonArgs = {
  input: DeleteOneAddonInput;
};

export type MutationDeleteOneCustomerArgs = {
  input: DeleteCustomerInput;
};

export type MutationDeleteOneEnvironmentArgs = {
  input: DeleteOneEnvironmentInput;
};

export type MutationDeleteOneFeatureArgs = {
  input: DeleteFeatureInput;
};

export type MutationDeleteOneHookArgs = {
  input: DeleteOneHookInput;
};

export type MutationDeleteOneIntegrationArgs = {
  input: DeleteOneIntegrationInput;
};

export type MutationDeleteOnePackageEntitlementArgs = {
  input: DeleteOnePackageEntitlementInput;
};

export type MutationDeleteOnePriceArgs = {
  input: DeleteOnePriceInput;
};

export type MutationDeleteOneProductArgs = {
  input: DeleteOneProductInput;
};

export type MutationDeleteOnePromotionalEntitlementArgs = {
  input: DeleteOnePromotionalEntitlementInput;
};

export type MutationEstimateSubscriptionArgs = {
  input: EstimateSubscriptionInput;
};

export type MutationEstimateSubscriptionUpdateArgs = {
  input: EstimateSubscriptionUpdateInput;
};

export type MutationHideGettingStartedPageArgs = {
  memberId: Scalars["String"];
};

export type MutationImportCustomersBulkArgs = {
  input: ImportCustomerBulk;
};

export type MutationImportOneCustomerArgs = {
  input: ImportCustomerInput;
};

export type MutationImportSubscriptionsBulkArgs = {
  input: ImportSubscriptionsBulk;
};

export type MutationInitAddStripeCustomerPaymentMethodArgs = {
  input: InitAddStripeCustomerPaymentMethodInput;
};

export type MutationInitiateCheckoutArgs = {
  input: InitiateCheckoutInput;
};

export type MutationInviteMembersArgs = {
  invites: Array<Scalars["String"]>;
};

export type MutationMigrateSubscriptionToLatestArgs = {
  input: SubscriptionMigrationInput;
};

export type MutationProvisionCustomerArgs = {
  input: ProvisionCustomerInput;
};

export type MutationProvisionSandboxArgs = {
  input: ProvisionSandboxInput;
};

export type MutationProvisionSubscriptionArgs = {
  input: ProvisionSubscription;
};

export type MutationProvisionSubscriptionV2Args = {
  input: ProvisionSubscriptionInput;
};

export type MutationPublishAddonArgs = {
  input: PackagePublishInput;
};

export type MutationPublishPlanArgs = {
  input: PackagePublishInput;
};

export type MutationPurgeCustomerCacheArgs = {
  input: ClearCustomerPersistentCacheInput;
};

export type MutationRemoveAddonDraftArgs = {
  input: DiscardPackageDraftInput;
};

export type MutationRemoveBasePlanFromPlanArgs = {
  input: RemoveBasePlanFromPlanInput;
};

export type MutationRemoveCompatibleAddonsFromPlanArgs = {
  input: RemoveCompatibleAddonsFromPlanInput;
};

export type MutationRemoveCouponFromCustomerArgs = {
  input: RemoveCouponFromCustomerInput;
};

export type MutationRemoveCouponFromCustomerSubscriptionArgs = {
  input: RemoveCouponFromCustomerSubscriptionInput;
};

export type MutationRemoveExperimentFromCustomerArgs = {
  input: RemoveExperimentFromCustomerInput;
};

export type MutationRemoveExperimentFromCustomerSubscriptionArgs = {
  input: RemoveExperimentFromCustomerSubscriptionInput;
};

export type MutationRemoveMemberArgs = {
  memberId: Scalars["String"];
};

export type MutationRemovePlanDraftArgs = {
  input: DiscardPackageDraftInput;
};

export type MutationReportEntitlementCheckRequestedArgs = {
  entitlementCheckRequested: EntitlementCheckRequested;
};

export type MutationResyncIntegrationArgs = {
  input: ResyncIntegrationInput;
};

export type MutationSetBasePlanOnPlanArgs = {
  input: SetBasePlanOnPlanInput;
};

export type MutationSetCompatibleAddonsOnPlanArgs = {
  input: SetCompatibleAddonsOnPlanInput;
};

export type MutationSetCouponOnCustomerArgs = {
  input: SetCouponOnCustomerInput;
};

export type MutationSetCouponOnCustomerSubscriptionArgs = {
  input: SetCouponOnCustomerSubscriptionInput;
};

export type MutationSetExperimentOnCustomerArgs = {
  input: SetExperimentOnCustomerInput;
};

export type MutationSetExperimentOnCustomerSubscriptionArgs = {
  input: SetExperimentOnCustomerSubscriptionInput;
};

export type MutationSetPackagePricingArgs = {
  input: PackagePricingInput;
};

export type MutationSetWidgetConfigurationArgs = {
  input: WidgetConfigurationUpdateInput;
};

export type MutationStartExperimentArgs = {
  input: StartExperimentInput;
};

export type MutationStopExperimentArgs = {
  input: StopExperimentInput;
};

export type MutationTriggerImportCatalogArgs = {
  input: ImportIntegrationCatalogInput;
};

export type MutationTriggerImportCustomersArgs = {
  input: ImportIntegrationCustomersInput;
};

export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};

export type MutationUpdateEntitlementsOrderArgs = {
  input: UpdatePackageEntitlementOrderInput;
};

export type MutationUpdateFeatureArgs = {
  input: UpdateFeatureInput;
};

export type MutationUpdateOneAddonArgs = {
  input: AddonUpdateInput;
};

export type MutationUpdateOneCouponArgs = {
  input: UpdateCouponInput;
};

export type MutationUpdateOneCustomerArgs = {
  input: UpdateCustomerInput;
};

export type MutationUpdateOneEnvironmentArgs = {
  input: UpdateOneEnvironmentInput;
};

export type MutationUpdateOneExperimentArgs = {
  input: UpdateExperimentInput;
};

export type MutationUpdateOneFeatureArgs = {
  input: UpdateOneFeatureInput;
};

export type MutationUpdateOneHookArgs = {
  input: UpdateOneHookInput;
};

export type MutationUpdateOneIntegrationArgs = {
  input: UpdateOneIntegrationInput;
};

export type MutationUpdateOnePackageEntitlementArgs = {
  input: UpdateOnePackageEntitlementInput;
};

export type MutationUpdateOnePlanArgs = {
  input: PlanUpdateInput;
};

export type MutationUpdateOneProductArgs = {
  input: UpdateOneProductInput;
};

export type MutationUpdateOnePromotionalEntitlementArgs = {
  input: UpdateOnePromotionalEntitlementInput;
};

export type MutationUpdateOneSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type NumberFieldComparison = {
  between?: InputMaybe<NumberFieldComparisonBetween>;
  eq?: InputMaybe<Scalars["Float"]>;
  gt?: InputMaybe<Scalars["Float"]>;
  gte?: InputMaybe<Scalars["Float"]>;
  in?: InputMaybe<Array<Scalars["Float"]>>;
  is?: InputMaybe<Scalars["Boolean"]>;
  isNot?: InputMaybe<Scalars["Boolean"]>;
  lt?: InputMaybe<Scalars["Float"]>;
  lte?: InputMaybe<Scalars["Float"]>;
  neq?: InputMaybe<Scalars["Float"]>;
  notBetween?: InputMaybe<NumberFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars["Float"]>>;
};

export type NumberFieldComparisonBetween = {
  lower: Scalars["Float"];
  upper: Scalars["Float"];
};

export type PackageAlreadyPublishedError = {
  __typename?: "PackageAlreadyPublishedError";
  code: Scalars["String"];
};

export type PackageChanges = {
  __typename?: "PackageChanges";
  additionalMetaData?: Maybe<AdditionalMetaDataChange>;
  basePlan?: Maybe<BasePlanChange>;
  compatibleAddons?: Maybe<Array<PlanCompatibleAddonChange>>;
  defaultTrialConfig?: Maybe<DefaultTrialConfigChange>;
  description?: Maybe<StringChangeDto>;
  displayName?: Maybe<StringChangeDto>;
  entitlements: Array<PackageEntitlementChange>;
  hiddenFromWidgets?: Maybe<HiddenFromWidgetsChange>;
  prices: Array<PackagePriceChange>;
  pricingType?: Maybe<PricingTypeChange>;
  totalChanges: Scalars["Int"];
};

export type PackageDto = {
  __typename?: "PackageDTO";
  additionalMetaData?: Maybe<Scalars["JSON"]>;
  billingId?: Maybe<Scalars["String"]>;
  billingLinkUrl?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  displayName: Scalars["String"];
  draftDetails?: Maybe<PackageDraftDetails>;
  draftSummary?: Maybe<PackageDraftSummary>;
  entitlements?: Maybe<Array<PackageEntitlement>>;
  environmentId?: Maybe<Scalars["String"]>;
  hiddenFromWidgets?: Maybe<Array<WidgetType>>;
  id: Scalars["String"];
  isLatest?: Maybe<Scalars["Boolean"]>;
  prices?: Maybe<Array<Price>>;
  pricingType?: Maybe<PricingType>;
  productId?: Maybe<Scalars["String"]>;
  refId: Scalars["String"];
  status: PackageStatus;
  syncStates: Array<SyncState>;
  type?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  versionNumber: Scalars["Int"];
};

export type PackageDtoFilter = {
  and?: InputMaybe<Array<PackageDtoFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  isLatest?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<PackageDtoFilter>>;
  pricingType?: InputMaybe<PricingTypeFilterComparison>;
  productId?: InputMaybe<StringFieldComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<PackageStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  versionNumber?: InputMaybe<IntFieldComparison>;
};

export type PackageDtoSort = {
  direction: SortDirection;
  field: PackageDtoSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PackageDtoSortFields {
  BillingId = "billingId",
  CreatedAt = "createdAt",
  Description = "description",
  DisplayName = "displayName",
  EnvironmentId = "environmentId",
  Id = "id",
  IsLatest = "isLatest",
  PricingType = "pricingType",
  ProductId = "productId",
  RefId = "refId",
  Status = "status",
  UpdatedAt = "updatedAt",
  VersionNumber = "versionNumber",
}

export type PackageDraftDetails = {
  __typename?: "PackageDraftDetails";
  affectedChildPlans?: Maybe<Array<Plan>>;
  changes?: Maybe<PackageChanges>;
  childPlansWithDraft?: Maybe<Array<Plan>>;
  customersAffected?: Maybe<Scalars["Int"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<Scalars["String"]>;
  version: Scalars["Int"];
};

export type PackageDraftSummary = {
  __typename?: "PackageDraftSummary";
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<Scalars["String"]>;
  version: Scalars["Int"];
};

export type PackageEntitlement = {
  __typename?: "PackageEntitlement";
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  displayNameOverride?: Maybe<Scalars["String"]>;
  environmentId: Scalars["String"];
  feature: Feature;
  featureId: Scalars["String"];
  hasUnlimitedUsage?: Maybe<Scalars["Boolean"]>;
  hiddenFromWidgets?: Maybe<Array<WidgetType>>;
  id: Scalars["String"];
  isCustom?: Maybe<Scalars["Boolean"]>;
  order?: Maybe<Scalars["Float"]>;
  package?: Maybe<PackageDto>;
  packageId: Scalars["String"];
  resetPeriod?: Maybe<EntitlementResetPeriod>;
  resetPeriodConfiguration?: Maybe<ResetPeriodConfiguration>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  usageLimit?: Maybe<Scalars["Float"]>;
};

export type PackageEntitlementAggregateGroupBy = {
  __typename?: "PackageEntitlementAggregateGroupBy";
  createdAt?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  packageId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type PackageEntitlementChange = {
  __typename?: "PackageEntitlementChange";
  after?: Maybe<PackageEntitlement>;
  before?: Maybe<PackageEntitlement>;
  changeType?: Maybe<ChangeType>;
};

export type PackageEntitlementConnection = {
  __typename?: "PackageEntitlementConnection";
  /** Array of edges. */
  edges: Array<PackageEntitlementEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type PackageEntitlementCountAggregate = {
  __typename?: "PackageEntitlementCountAggregate";
  createdAt?: Maybe<Scalars["Int"]>;
  environmentId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  packageId?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["Int"]>;
};

export type PackageEntitlementDeleteResponse = {
  __typename?: "PackageEntitlementDeleteResponse";
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  displayNameOverride?: Maybe<Scalars["String"]>;
  environmentId?: Maybe<Scalars["String"]>;
  featureId?: Maybe<Scalars["String"]>;
  hasUnlimitedUsage?: Maybe<Scalars["Boolean"]>;
  hiddenFromWidgets?: Maybe<Array<WidgetType>>;
  id?: Maybe<Scalars["String"]>;
  isCustom?: Maybe<Scalars["Boolean"]>;
  order?: Maybe<Scalars["Float"]>;
  packageId?: Maybe<Scalars["String"]>;
  resetPeriod?: Maybe<EntitlementResetPeriod>;
  resetPeriodConfiguration?: Maybe<ResetPeriodConfiguration>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  usageLimit?: Maybe<Scalars["Float"]>;
};

export type PackageEntitlementEdge = {
  __typename?: "PackageEntitlementEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the PackageEntitlement */
  node: PackageEntitlement;
};

export type PackageEntitlementFilter = {
  and?: InputMaybe<Array<PackageEntitlementFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  feature?: InputMaybe<PackageEntitlementFilterFeatureFilter>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PackageEntitlementFilter>>;
  package?: InputMaybe<PackageEntitlementFilterPackageDtoFilter>;
  packageId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type PackageEntitlementFilterFeatureFilter = {
  and?: InputMaybe<Array<PackageEntitlementFilterFeatureFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  featureStatus?: InputMaybe<FeatureStatusFilterComparison>;
  featureType?: InputMaybe<FeatureTypeFilterComparison>;
  id?: InputMaybe<StringFieldComparison>;
  meterType?: InputMaybe<MeterTypeFilterComparison>;
  or?: InputMaybe<Array<PackageEntitlementFilterFeatureFilter>>;
  refId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type PackageEntitlementFilterPackageDtoFilter = {
  and?: InputMaybe<Array<PackageEntitlementFilterPackageDtoFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  isLatest?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<PackageEntitlementFilterPackageDtoFilter>>;
  pricingType?: InputMaybe<PricingTypeFilterComparison>;
  productId?: InputMaybe<StringFieldComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<PackageStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  versionNumber?: InputMaybe<IntFieldComparison>;
};

export type PackageEntitlementInput = {
  description?: InputMaybe<Scalars["String"]>;
  displayNameOverride?: InputMaybe<Scalars["String"]>;
  environmentId: Scalars["String"];
  featureId: Scalars["String"];
  hasUnlimitedUsage?: InputMaybe<Scalars["Boolean"]>;
  hiddenFromWidgets?: InputMaybe<Array<WidgetType>>;
  isCustom?: InputMaybe<Scalars["Boolean"]>;
  monthlyResetPeriodConfiguration?: InputMaybe<MonthlyResetPeriodConfigInput>;
  order?: InputMaybe<Scalars["Float"]>;
  packageId: Scalars["String"];
  resetPeriod?: InputMaybe<EntitlementResetPeriod>;
  usageLimit?: InputMaybe<Scalars["Float"]>;
  weeklyResetPeriodConfiguration?: InputMaybe<WeeklyResetPeriodConfigInput>;
};

export type PackageEntitlementMaxAggregate = {
  __typename?: "PackageEntitlementMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  packageId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type PackageEntitlementMinAggregate = {
  __typename?: "PackageEntitlementMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  packageId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type PackageEntitlementSort = {
  direction: SortDirection;
  field: PackageEntitlementSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PackageEntitlementSortFields {
  CreatedAt = "createdAt",
  EnvironmentId = "environmentId",
  Id = "id",
  PackageId = "packageId",
  UpdatedAt = "updatedAt",
}

export type PackageEntitlementUpdateInput = {
  description?: InputMaybe<Scalars["String"]>;
  displayNameOverride?: InputMaybe<Scalars["String"]>;
  hasUnlimitedUsage?: InputMaybe<Scalars["Boolean"]>;
  hiddenFromWidgets?: InputMaybe<Array<WidgetType>>;
  isCustom?: InputMaybe<Scalars["Boolean"]>;
  monthlyResetPeriodConfiguration?: InputMaybe<MonthlyResetPeriodConfigInput>;
  order?: InputMaybe<Scalars["Float"]>;
  resetPeriod?: InputMaybe<EntitlementResetPeriod>;
  usageLimit?: InputMaybe<Scalars["Float"]>;
  weeklyResetPeriodConfiguration?: InputMaybe<WeeklyResetPeriodConfigInput>;
};

export type PackagePrice = {
  __typename?: "PackagePrice";
  packageId: Scalars["String"];
  pricingType: PricingType;
};

export type PackagePriceChange = {
  __typename?: "PackagePriceChange";
  after?: Maybe<Price>;
  before?: Maybe<Price>;
  changeType?: Maybe<ChangeType>;
};

export type PackagePricingInput = {
  environmentId: Scalars["String"];
  packageId: Scalars["String"];
  pricingModel?: InputMaybe<PricingModelCreateInput>;
  pricingType: PricingType;
};

export type PackagePricingTypeNotSetError = {
  __typename?: "PackagePricingTypeNotSetError";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
  refId: Scalars["String"];
};

export type PackagePublishInput = {
  id: Scalars["ID"];
  migrationType: PublishMigrationType;
};

/** Package status. */
export enum PackageStatus {
  Archived = "ARCHIVED",
  Draft = "DRAFT",
  Published = "PUBLISHED",
}

export type PackageStatusFilterComparison = {
  eq?: InputMaybe<PackageStatus>;
  gt?: InputMaybe<PackageStatus>;
  gte?: InputMaybe<PackageStatus>;
  iLike?: InputMaybe<PackageStatus>;
  in?: InputMaybe<Array<PackageStatus>>;
  is?: InputMaybe<Scalars["Boolean"]>;
  isNot?: InputMaybe<Scalars["Boolean"]>;
  like?: InputMaybe<PackageStatus>;
  lt?: InputMaybe<PackageStatus>;
  lte?: InputMaybe<PackageStatus>;
  neq?: InputMaybe<PackageStatus>;
  notILike?: InputMaybe<PackageStatus>;
  notIn?: InputMaybe<Array<PackageStatus>>;
  notLike?: InputMaybe<PackageStatus>;
};

export type PageInfo = {
  __typename?: "PageInfo";
  /** The cursor of the last returned record. */
  endCursor?: Maybe<Scalars["ConnectionCursor"]>;
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars["Boolean"]>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars["Boolean"]>;
  /** The cursor of the first returned record. */
  startCursor?: Maybe<Scalars["ConnectionCursor"]>;
};

/** Type of a payment method */
export enum PaymentMethodType {
  Bank = "BANK",
  Card = "CARD",
}

export type Paywall = {
  __typename?: "Paywall";
  configuration?: Maybe<PaywallConfiguration>;
  currency: PaywallCurrency;
  customer?: Maybe<Customer>;
  plans: Array<Plan>;
  resource?: Maybe<CustomerResource>;
};

export type PaywallAddon = {
  __typename?: "PaywallAddon";
  additionalMetaData?: Maybe<Scalars["JSON"]>;
  billingId?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  displayName: Scalars["String"];
  entitlements: Array<Entitlement>;
  prices: Array<PaywallPrice>;
  pricingType?: Maybe<PricingType>;
  refId: Scalars["String"];
};

export type PaywallBasePlan = {
  __typename?: "PaywallBasePlan";
  displayName: Scalars["String"];
  refId: Scalars["String"];
};

export type PaywallColorsPalette = {
  __typename?: "PaywallColorsPalette";
  backgroundColor?: Maybe<Scalars["String"]>;
  borderColor?: Maybe<Scalars["String"]>;
  currentPlanBackground?: Maybe<Scalars["String"]>;
  primary?: Maybe<Scalars["String"]>;
  textColor?: Maybe<Scalars["String"]>;
};

export type PaywallColorsPaletteInput = {
  backgroundColor?: InputMaybe<Scalars["String"]>;
  borderColor?: InputMaybe<Scalars["String"]>;
  currentPlanBackground?: InputMaybe<Scalars["String"]>;
  primary?: InputMaybe<Scalars["String"]>;
  textColor?: InputMaybe<Scalars["String"]>;
};

export type PaywallConfiguration = {
  __typename?: "PaywallConfiguration";
  customCss?: Maybe<Scalars["String"]>;
  layout?: Maybe<PaywallLayoutConfiguration>;
  palette?: Maybe<PaywallColorsPalette>;
  typography?: Maybe<TypographyConfiguration>;
};

export type PaywallConfigurationInput = {
  customCss?: InputMaybe<Scalars["String"]>;
  layout?: InputMaybe<PaywallLayoutConfigurationInput>;
  palette?: InputMaybe<PaywallColorsPaletteInput>;
  typography?: InputMaybe<TypographyConfigurationInput>;
};

export type PaywallCurrency = {
  __typename?: "PaywallCurrency";
  code: Currency;
  symbol: Scalars["String"];
};

export type PaywallLayoutConfiguration = {
  __typename?: "PaywallLayoutConfiguration";
  alignment?: Maybe<Alignment>;
  planMargin?: Maybe<Scalars["Float"]>;
  planPadding?: Maybe<Scalars["Float"]>;
  planWidth?: Maybe<Scalars["Float"]>;
};

export type PaywallLayoutConfigurationInput = {
  alignment?: InputMaybe<Alignment>;
  planMargin?: InputMaybe<Scalars["Float"]>;
  planPadding?: InputMaybe<Scalars["Float"]>;
  planWidth?: InputMaybe<Scalars["Float"]>;
};

export type PaywallPlan = {
  __typename?: "PaywallPlan";
  additionalMetaData?: Maybe<Scalars["JSON"]>;
  basePlan?: Maybe<PaywallBasePlan>;
  billingId?: Maybe<Scalars["String"]>;
  compatibleAddons?: Maybe<Array<PaywallAddon>>;
  defaultTrialConfig?: Maybe<DefaultTrialConfig>;
  description?: Maybe<Scalars["String"]>;
  displayName: Scalars["String"];
  entitlements?: Maybe<Array<Entitlement>>;
  inheritedEntitlements?: Maybe<Array<Entitlement>>;
  prices: Array<PaywallPrice>;
  pricingType?: Maybe<PricingType>;
  product: PaywallProduct;
  refId: Scalars["String"];
};

export type PaywallPrice = {
  __typename?: "PaywallPrice";
  billingCountryCode?: Maybe<Scalars["String"]>;
  billingId?: Maybe<Scalars["String"]>;
  billingModel: BillingModel;
  billingPeriod: BillingPeriod;
  feature?: Maybe<EntitlementFeature>;
  featureId?: Maybe<Scalars["String"]>;
  maxUnitQuantity?: Maybe<Scalars["Float"]>;
  minUnitQuantity?: Maybe<Scalars["Float"]>;
  price: Money;
};

export type PaywallProduct = {
  __typename?: "PaywallProduct";
  additionalMetaData?: Maybe<Scalars["JSON"]>;
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  refId: Scalars["String"];
};

export type Plan = {
  __typename?: "Plan";
  additionalMetaData?: Maybe<Scalars["JSON"]>;
  basePlan?: Maybe<Plan>;
  billingId?: Maybe<Scalars["String"]>;
  billingLinkUrl?: Maybe<Scalars["String"]>;
  compatibleAddons?: Maybe<Array<Addon>>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  defaultTrialConfig?: Maybe<DefaultTrialConfig>;
  description?: Maybe<Scalars["String"]>;
  displayName: Scalars["String"];
  draftDetails?: Maybe<PackageDraftDetails>;
  draftSummary?: Maybe<PackageDraftSummary>;
  entitlements?: Maybe<Array<PackageEntitlement>>;
  environment?: Maybe<Environment>;
  environmentId?: Maybe<Scalars["String"]>;
  hiddenFromWidgets?: Maybe<Array<WidgetType>>;
  id: Scalars["String"];
  inheritedEntitlements?: Maybe<Array<PackageEntitlement>>;
  isLatest?: Maybe<Scalars["Boolean"]>;
  isParent: Scalars["Boolean"];
  prices?: Maybe<Array<Price>>;
  pricingType?: Maybe<PricingType>;
  product: Product;
  productId?: Maybe<Scalars["String"]>;
  refId: Scalars["String"];
  status: PackageStatus;
  syncStates?: Maybe<Array<SyncState>>;
  type?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  versionNumber: Scalars["Int"];
};

export type PlanCompatibleAddonsArgs = {
  filter?: InputMaybe<AddonFilter>;
  sorting?: InputMaybe<Array<AddonSort>>;
};

export type PlanInheritedEntitlementsArgs = {
  includeOverridden?: InputMaybe<Scalars["Boolean"]>;
};

export type PlanAggregateGroupBy = {
  __typename?: "PlanAggregateGroupBy";
  billingId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  isLatest?: Maybe<Scalars["Boolean"]>;
  pricingType?: Maybe<PricingType>;
  productId?: Maybe<Scalars["String"]>;
  refId?: Maybe<Scalars["String"]>;
  status?: Maybe<PackageStatus>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  versionNumber?: Maybe<Scalars["Int"]>;
};

export type PlanAvgAggregate = {
  __typename?: "PlanAvgAggregate";
  versionNumber?: Maybe<Scalars["Float"]>;
};

export type PlanCompatibleAddonChange = {
  __typename?: "PlanCompatibleAddonChange";
  after?: Maybe<Addon>;
  before?: Maybe<Addon>;
  changeType?: Maybe<ChangeType>;
};

export type PlanConnection = {
  __typename?: "PlanConnection";
  /** Array of edges. */
  edges: Array<PlanEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type PlanCountAggregate = {
  __typename?: "PlanCountAggregate";
  billingId?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["Int"]>;
  displayName?: Maybe<Scalars["Int"]>;
  environmentId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  isLatest?: Maybe<Scalars["Int"]>;
  pricingType?: Maybe<Scalars["Int"]>;
  productId?: Maybe<Scalars["Int"]>;
  refId?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["Int"]>;
  versionNumber?: Maybe<Scalars["Int"]>;
};

export type PlanCreateInput = {
  additionalMetaData?: InputMaybe<Scalars["JSON"]>;
  billingId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  displayName: Scalars["String"];
  environmentId?: InputMaybe<Scalars["String"]>;
  hiddenFromWidgets?: InputMaybe<Array<WidgetType>>;
  parentPlanId?: InputMaybe<Scalars["String"]>;
  productId: Scalars["String"];
  refId?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<PackageStatus>;
};

export type PlanEdge = {
  __typename?: "PlanEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the Plan */
  node: Plan;
};

export type PlanFilter = {
  and?: InputMaybe<Array<PlanFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  compatibleAddons?: InputMaybe<PlanFilterAddonFilter>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  isLatest?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<PlanFilter>>;
  pricingType?: InputMaybe<PricingTypeFilterComparison>;
  product?: InputMaybe<PlanFilterProductFilter>;
  productId?: InputMaybe<StringFieldComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<PackageStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  versionNumber?: InputMaybe<IntFieldComparison>;
};

export type PlanFilterAddonFilter = {
  and?: InputMaybe<Array<PlanFilterAddonFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  isLatest?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<PlanFilterAddonFilter>>;
  pricingType?: InputMaybe<PricingTypeFilterComparison>;
  productId?: InputMaybe<StringFieldComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<PackageStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  versionNumber?: InputMaybe<IntFieldComparison>;
};

export type PlanFilterProductFilter = {
  and?: InputMaybe<Array<PlanFilterProductFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  isDefaultProduct?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<PlanFilterProductFilter>>;
  refId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type PlanMaxAggregate = {
  __typename?: "PlanMaxAggregate";
  billingId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  pricingType?: Maybe<PricingType>;
  productId?: Maybe<Scalars["String"]>;
  refId?: Maybe<Scalars["String"]>;
  status?: Maybe<PackageStatus>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  versionNumber?: Maybe<Scalars["Int"]>;
};

export type PlanMinAggregate = {
  __typename?: "PlanMinAggregate";
  billingId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  pricingType?: Maybe<PricingType>;
  productId?: Maybe<Scalars["String"]>;
  refId?: Maybe<Scalars["String"]>;
  status?: Maybe<PackageStatus>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  versionNumber?: Maybe<Scalars["Int"]>;
};

export type PlanNotFoundError = {
  __typename?: "PlanNotFoundError";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
  refId: Scalars["String"];
};

export type PlanSort = {
  direction: SortDirection;
  field: PlanSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PlanSortFields {
  BillingId = "billingId",
  CreatedAt = "createdAt",
  Description = "description",
  DisplayName = "displayName",
  EnvironmentId = "environmentId",
  Id = "id",
  IsLatest = "isLatest",
  PricingType = "pricingType",
  ProductId = "productId",
  RefId = "refId",
  Status = "status",
  UpdatedAt = "updatedAt",
  VersionNumber = "versionNumber",
}

export type PlanSumAggregate = {
  __typename?: "PlanSumAggregate";
  versionNumber?: Maybe<Scalars["Float"]>;
};

export type PlanUpdateInput = {
  additionalMetaData?: InputMaybe<Scalars["JSON"]>;
  billingId?: InputMaybe<Scalars["String"]>;
  defaultTrialConfig?: InputMaybe<DefaultTrialConfigInputDto>;
  description?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  hiddenFromWidgets?: InputMaybe<Array<WidgetType>>;
  id: Scalars["String"];
  parentPlanId?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<PackageStatus>;
};

export type Price = {
  __typename?: "Price";
  billingCountryCode?: Maybe<Scalars["String"]>;
  billingId?: Maybe<Scalars["String"]>;
  billingModel: BillingModel;
  billingPeriod: BillingPeriod;
  createdAt?: Maybe<Scalars["DateTime"]>;
  crmId?: Maybe<Scalars["String"]>;
  crmLinkUrl?: Maybe<Scalars["String"]>;
  feature?: Maybe<Feature>;
  featureId?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  maxUnitQuantity?: Maybe<Scalars["Float"]>;
  minUnitQuantity?: Maybe<Scalars["Float"]>;
  package: PackageDto;
  packageId?: Maybe<Scalars["String"]>;
  price: Money;
  usedInSubscriptions?: Maybe<Scalars["Boolean"]>;
};

export type PriceAggregateGroupBy = {
  __typename?: "PriceAggregateGroupBy";
  billingId?: Maybe<Scalars["String"]>;
  billingModel?: Maybe<BillingModel>;
  billingPeriod?: Maybe<BillingPeriod>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
};

export type PriceCountAggregate = {
  __typename?: "PriceCountAggregate";
  billingId?: Maybe<Scalars["Int"]>;
  billingModel?: Maybe<Scalars["Int"]>;
  billingPeriod?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

export type PriceDeleteResponse = {
  __typename?: "PriceDeleteResponse";
  billingCountryCode?: Maybe<Scalars["String"]>;
  billingId?: Maybe<Scalars["String"]>;
  billingModel?: Maybe<BillingModel>;
  billingPeriod?: Maybe<BillingPeriod>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  crmId?: Maybe<Scalars["String"]>;
  crmLinkUrl?: Maybe<Scalars["String"]>;
  feature?: Maybe<Feature>;
  featureId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  maxUnitQuantity?: Maybe<Scalars["Float"]>;
  minUnitQuantity?: Maybe<Scalars["Float"]>;
  packageId?: Maybe<Scalars["String"]>;
  price?: Maybe<Money>;
  usedInSubscriptions?: Maybe<Scalars["Boolean"]>;
};

export type PriceEdge = {
  __typename?: "PriceEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the Price */
  node: Price;
};

export type PriceEntitlement = {
  __typename?: "PriceEntitlement";
  description?: Maybe<Scalars["String"]>;
  feature: Feature;
  featureId: Scalars["String"];
  hasUnlimitedUsage?: Maybe<Scalars["Boolean"]>;
  package: PackageDto;
  packageId: Scalars["String"];
  resetPeriod?: Maybe<EntitlementResetPeriod>;
  resetPeriodConfiguration?: Maybe<ResetPeriodConfiguration>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  usageLimit?: Maybe<Scalars["Float"]>;
};

export type PriceFilter = {
  and?: InputMaybe<Array<PriceFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  billingModel?: InputMaybe<BillingModelFilterComparison>;
  billingPeriod?: InputMaybe<BillingPeriodFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PriceFilter>>;
  package?: InputMaybe<PriceFilterPackageDtoFilter>;
};

export type PriceFilterPackageDtoFilter = {
  and?: InputMaybe<Array<PriceFilterPackageDtoFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  isLatest?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<PriceFilterPackageDtoFilter>>;
  pricingType?: InputMaybe<PricingTypeFilterComparison>;
  productId?: InputMaybe<StringFieldComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<PackageStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  versionNumber?: InputMaybe<IntFieldComparison>;
};

export type PriceMaxAggregate = {
  __typename?: "PriceMaxAggregate";
  billingId?: Maybe<Scalars["String"]>;
  billingModel?: Maybe<BillingModel>;
  billingPeriod?: Maybe<BillingPeriod>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
};

export type PriceMinAggregate = {
  __typename?: "PriceMinAggregate";
  billingId?: Maybe<Scalars["String"]>;
  billingModel?: Maybe<BillingModel>;
  billingPeriod?: Maybe<BillingPeriod>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
};

export type PriceNotFoundError = {
  __typename?: "PriceNotFoundError";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
};

export type PricePeriodInput = {
  billingCountryCode?: InputMaybe<Scalars["String"]>;
  billingPeriod: BillingPeriod;
  price: MoneyInputDto;
};

export type PriceSort = {
  direction: SortDirection;
  field: PriceSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PriceSortFields {
  BillingId = "billingId",
  BillingModel = "billingModel",
  BillingPeriod = "billingPeriod",
  CreatedAt = "createdAt",
  Id = "id",
}

export type PricingModelCreateInput = {
  billingModel: BillingModel;
  featureId?: InputMaybe<Scalars["String"]>;
  maxUnitQuantity?: InputMaybe<Scalars["Float"]>;
  minUnitQuantity?: InputMaybe<Scalars["Float"]>;
  pricePeriods: Array<PricePeriodInput>;
};

/** Package pricing type. */
export enum PricingType {
  Custom = "CUSTOM",
  Free = "FREE",
  Paid = "PAID",
}

export type PricingTypeChange = {
  __typename?: "PricingTypeChange";
  after?: Maybe<PricingType>;
  before?: Maybe<PricingType>;
  changeType?: Maybe<ChangeType>;
};

export type PricingTypeFilterComparison = {
  eq?: InputMaybe<PricingType>;
  gt?: InputMaybe<PricingType>;
  gte?: InputMaybe<PricingType>;
  iLike?: InputMaybe<PricingType>;
  in?: InputMaybe<Array<PricingType>>;
  is?: InputMaybe<Scalars["Boolean"]>;
  isNot?: InputMaybe<Scalars["Boolean"]>;
  like?: InputMaybe<PricingType>;
  lt?: InputMaybe<PricingType>;
  lte?: InputMaybe<PricingType>;
  neq?: InputMaybe<PricingType>;
  notILike?: InputMaybe<PricingType>;
  notIn?: InputMaybe<Array<PricingType>>;
  notLike?: InputMaybe<PricingType>;
};

export type Product = {
  __typename?: "Product";
  additionalMetaData?: Maybe<Scalars["JSON"]>;
  addons: Array<Addon>;
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  downgradePlan?: Maybe<Plan>;
  environment?: Maybe<Environment>;
  environmentId: Scalars["String"];
  hasSubscriptions: Scalars["Boolean"];
  id: Scalars["String"];
  isDefaultProduct: Scalars["Boolean"];
  multipleSubscriptions: Scalars["Boolean"];
  plans: Array<Plan>;
  productSettings: ProductSettings;
  refId: Scalars["String"];
  subscriptionStartPlan?: Maybe<Plan>;
  updatedAt: Scalars["DateTime"];
};

export type ProductAggregateGroupBy = {
  __typename?: "ProductAggregateGroupBy";
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  isDefaultProduct?: Maybe<Scalars["Boolean"]>;
  refId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ProductConnection = {
  __typename?: "ProductConnection";
  /** Array of edges. */
  edges: Array<ProductEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type ProductCountAggregate = {
  __typename?: "ProductCountAggregate";
  createdAt?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["Int"]>;
  displayName?: Maybe<Scalars["Int"]>;
  environmentId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  isDefaultProduct?: Maybe<Scalars["Int"]>;
  refId?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["Int"]>;
};

export type ProductCreateInput = {
  additionalMetaData?: InputMaybe<Scalars["JSON"]>;
  description?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  environmentId: Scalars["String"];
  multipleSubscriptions?: InputMaybe<Scalars["Boolean"]>;
  refId: Scalars["String"];
};

export type ProductDeleteResponse = {
  __typename?: "ProductDeleteResponse";
  additionalMetaData?: Maybe<Scalars["JSON"]>;
  addons?: Maybe<Array<Addon>>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  isDefaultProduct?: Maybe<Scalars["Boolean"]>;
  multipleSubscriptions?: Maybe<Scalars["Boolean"]>;
  plans?: Maybe<Array<Plan>>;
  productSettings?: Maybe<ProductSettings>;
  refId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ProductEdge = {
  __typename?: "ProductEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the Product */
  node: Product;
};

export type ProductFilter = {
  and?: InputMaybe<Array<ProductFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  isDefaultProduct?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<ProductFilter>>;
  refId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type ProductMaxAggregate = {
  __typename?: "ProductMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  refId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ProductMinAggregate = {
  __typename?: "ProductMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  refId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ProductSettings = {
  __typename?: "ProductSettings";
  downgradePlan?: Maybe<Plan>;
  downgradePlanId?: Maybe<Scalars["String"]>;
  subscriptionCancellationTime: SubscriptionCancellationTime;
  subscriptionEndSetup: SubscriptionEndSetup;
  subscriptionStartPlan?: Maybe<Plan>;
  subscriptionStartPlanId?: Maybe<Scalars["String"]>;
  subscriptionStartSetup: SubscriptionStartSetup;
};

export type ProductSettingsInput = {
  downgradeAtEndOfBillingPeriod?: InputMaybe<Scalars["String"]>;
  downgradePlanId?: InputMaybe<Scalars["String"]>;
  subscriptionCancellationTime: SubscriptionCancellationTime;
  subscriptionEndSetup: SubscriptionEndSetup;
  subscriptionStartPlanId?: InputMaybe<Scalars["String"]>;
  subscriptionStartSetup: SubscriptionStartSetup;
};

export type ProductSort = {
  direction: SortDirection;
  field: ProductSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ProductSortFields {
  CreatedAt = "createdAt",
  Description = "description",
  DisplayName = "displayName",
  EnvironmentId = "environmentId",
  Id = "id",
  IsDefaultProduct = "isDefaultProduct",
  RefId = "refId",
  UpdatedAt = "updatedAt",
}

export type ProductUpdateInput = {
  additionalMetaData?: InputMaybe<Scalars["JSON"]>;
  description?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  multipleSubscriptions?: InputMaybe<Scalars["Boolean"]>;
  productSettings?: InputMaybe<ProductSettingsInput>;
};

export type PromotionCodeCustomerNotFirstPurchase = {
  __typename?: "PromotionCodeCustomerNotFirstPurchase";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
};

export type PromotionCodeMaxRedemptionsReached = {
  __typename?: "PromotionCodeMaxRedemptionsReached";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
};

export type PromotionCodeMinimumAmountNotReached = {
  __typename?: "PromotionCodeMinimumAmountNotReached";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
};

export type PromotionCodeNotActive = {
  __typename?: "PromotionCodeNotActive";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
};

export type PromotionCodeNotForCustomer = {
  __typename?: "PromotionCodeNotForCustomer";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
};

export type PromotionCodeNotFound = {
  __typename?: "PromotionCodeNotFound";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
};

export type PromotionalEntitlement = {
  __typename?: "PromotionalEntitlement";
  createdAt?: Maybe<Scalars["DateTime"]>;
  customer: Customer;
  description?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["DateTime"]>;
  environmentId: Scalars["String"];
  feature: Feature;
  featureId: Scalars["String"];
  hasUnlimitedUsage?: Maybe<Scalars["Boolean"]>;
  id: Scalars["String"];
  isVisible: Scalars["Boolean"];
  period: PromotionalEntitlementPeriod;
  resetPeriod?: Maybe<EntitlementResetPeriod>;
  resetPeriodConfiguration?: Maybe<ResetPeriodConfiguration>;
  startDate: Scalars["DateTime"];
  status: PromotionalEntitlementStatus;
  unlimited?: Maybe<Scalars["Boolean"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  usageLimit?: Maybe<Scalars["Float"]>;
};

export type PromotionalEntitlementAggregateGroupBy = {
  __typename?: "PromotionalEntitlementAggregateGroupBy";
  createdAt?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  status?: Maybe<PromotionalEntitlementStatus>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type PromotionalEntitlementConnection = {
  __typename?: "PromotionalEntitlementConnection";
  /** Array of edges. */
  edges: Array<PromotionalEntitlementEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type PromotionalEntitlementCountAggregate = {
  __typename?: "PromotionalEntitlementCountAggregate";
  createdAt?: Maybe<Scalars["Int"]>;
  environmentId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["Int"]>;
};

export type PromotionalEntitlementDeleteResponse = {
  __typename?: "PromotionalEntitlementDeleteResponse";
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  featureId?: Maybe<Scalars["String"]>;
  hasUnlimitedUsage?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["String"]>;
  isVisible?: Maybe<Scalars["Boolean"]>;
  period?: Maybe<PromotionalEntitlementPeriod>;
  resetPeriod?: Maybe<EntitlementResetPeriod>;
  resetPeriodConfiguration?: Maybe<ResetPeriodConfiguration>;
  startDate?: Maybe<Scalars["DateTime"]>;
  status?: Maybe<PromotionalEntitlementStatus>;
  unlimited?: Maybe<Scalars["Boolean"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  usageLimit?: Maybe<Scalars["Float"]>;
};

export type PromotionalEntitlementEdge = {
  __typename?: "PromotionalEntitlementEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the PromotionalEntitlement */
  node: PromotionalEntitlement;
};

export type PromotionalEntitlementFilter = {
  and?: InputMaybe<Array<PromotionalEntitlementFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PromotionalEntitlementFilter>>;
  status?: InputMaybe<PromotionalEntitlementStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type PromotionalEntitlementInput = {
  customerId: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  environmentId: Scalars["String"];
  featureId: Scalars["String"];
  hasUnlimitedUsage?: InputMaybe<Scalars["Boolean"]>;
  isVisible: Scalars["Boolean"];
  monthlyResetPeriodConfiguration?: InputMaybe<MonthlyResetPeriodConfigInput>;
  period: PromotionalEntitlementPeriod;
  resetPeriod?: InputMaybe<EntitlementResetPeriod>;
  usageLimit?: InputMaybe<Scalars["Float"]>;
  weeklyResetPeriodConfiguration?: InputMaybe<WeeklyResetPeriodConfigInput>;
};

export type PromotionalEntitlementMaxAggregate = {
  __typename?: "PromotionalEntitlementMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  status?: Maybe<PromotionalEntitlementStatus>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type PromotionalEntitlementMinAggregate = {
  __typename?: "PromotionalEntitlementMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  status?: Maybe<PromotionalEntitlementStatus>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

/** Promotional entitlement duration */
export enum PromotionalEntitlementPeriod {
  Custom = "CUSTOM",
  Lifetime = "LIFETIME",
  OneMonth = "ONE_MONTH",
  OneWeek = "ONE_WEEK",
  OneYear = "ONE_YEAR",
  SixMonth = "SIX_MONTH",
}

export type PromotionalEntitlementSort = {
  direction: SortDirection;
  field: PromotionalEntitlementSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PromotionalEntitlementSortFields {
  CreatedAt = "createdAt",
  EnvironmentId = "environmentId",
  Id = "id",
  Status = "status",
  UpdatedAt = "updatedAt",
}

/** Promotional entitlement status */
export enum PromotionalEntitlementStatus {
  Active = "Active",
  Expired = "Expired",
  Paused = "Paused",
}

export type PromotionalEntitlementStatusFilterComparison = {
  eq?: InputMaybe<PromotionalEntitlementStatus>;
  gt?: InputMaybe<PromotionalEntitlementStatus>;
  gte?: InputMaybe<PromotionalEntitlementStatus>;
  iLike?: InputMaybe<PromotionalEntitlementStatus>;
  in?: InputMaybe<Array<PromotionalEntitlementStatus>>;
  is?: InputMaybe<Scalars["Boolean"]>;
  isNot?: InputMaybe<Scalars["Boolean"]>;
  like?: InputMaybe<PromotionalEntitlementStatus>;
  lt?: InputMaybe<PromotionalEntitlementStatus>;
  lte?: InputMaybe<PromotionalEntitlementStatus>;
  neq?: InputMaybe<PromotionalEntitlementStatus>;
  notILike?: InputMaybe<PromotionalEntitlementStatus>;
  notIn?: InputMaybe<Array<PromotionalEntitlementStatus>>;
  notLike?: InputMaybe<PromotionalEntitlementStatus>;
};

export type PromotionalEntitlementUpdateInput = {
  description?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  hasUnlimitedUsage?: InputMaybe<Scalars["Boolean"]>;
  isVisible: Scalars["Boolean"];
  monthlyResetPeriodConfiguration?: InputMaybe<MonthlyResetPeriodConfigInput>;
  period: PromotionalEntitlementPeriod;
  resetPeriod?: InputMaybe<EntitlementResetPeriod>;
  usageLimit?: InputMaybe<Scalars["Float"]>;
  weeklyResetPeriodConfiguration?: InputMaybe<WeeklyResetPeriodConfigInput>;
};

export enum ProrationBehavior {
  CreateProrations = "CREATE_PRORATIONS",
  InvoiceImmediately = "INVOICE_IMMEDIATELY",
}

export type ProvisionCustomerInput = {
  additionalMetaData?: InputMaybe<Scalars["JSON"]>;
  billingId?: InputMaybe<Scalars["String"]>;
  billingInformation?: InputMaybe<CustomerBillingInfo>;
  couponRefId?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  crmId?: InputMaybe<Scalars["String"]>;
  customerId?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  environmentId?: InputMaybe<Scalars["String"]>;
  excludeFromExperiment?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  refId?: InputMaybe<Scalars["String"]>;
  shouldSyncFree?: InputMaybe<Scalars["Boolean"]>;
  subscriptionParams?: InputMaybe<ProvisionCustomerSubscriptionInput>;
};

export type ProvisionCustomerSubscriptionInput = {
  additionalMetaData?: InputMaybe<Scalars["JSON"]>;
  addons?: InputMaybe<Array<SubscriptionAddonInput>>;
  awaitPaymentConfirmation?: InputMaybe<Scalars["Boolean"]>;
  billingCountryCode?: InputMaybe<Scalars["String"]>;
  billingId?: InputMaybe<Scalars["String"]>;
  billingInformation?: InputMaybe<SubscriptionBillingInfo>;
  billingPeriod?: InputMaybe<BillingPeriod>;
  planId: Scalars["String"];
  priceUnitAmount?: InputMaybe<Scalars["Float"]>;
  promotionCode?: InputMaybe<Scalars["String"]>;
  refId?: InputMaybe<Scalars["String"]>;
  resourceId?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  subscriptionId?: InputMaybe<Scalars["String"]>;
};

export type ProvisionSandboxInput = {
  billingModel: BillingModel;
  displayName: Scalars["String"];
};

export type ProvisionSubscription = {
  additionalMetaData?: InputMaybe<Scalars["JSON"]>;
  addons?: InputMaybe<Array<SubscriptionAddonInput>>;
  awaitPaymentConfirmation?: InputMaybe<Scalars["Boolean"]>;
  billingCountryCode?: InputMaybe<Scalars["String"]>;
  billingId?: InputMaybe<Scalars["String"]>;
  billingInformation?: InputMaybe<SubscriptionBillingInfo>;
  billingPeriod?: InputMaybe<BillingPeriod>;
  checkoutOptions?: InputMaybe<CheckoutOptions>;
  customerId: Scalars["String"];
  planId: Scalars["String"];
  priceUnitAmount?: InputMaybe<Scalars["Float"]>;
  promotionCode?: InputMaybe<Scalars["String"]>;
  refId?: InputMaybe<Scalars["String"]>;
  resourceId?: InputMaybe<Scalars["String"]>;
  skipTrial?: InputMaybe<Scalars["Boolean"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  subscriptionId?: InputMaybe<Scalars["String"]>;
};

export type ProvisionSubscriptionInput = {
  additionalMetaData?: InputMaybe<Scalars["JSON"]>;
  addons?: InputMaybe<Array<SubscriptionAddonInput>>;
  awaitPaymentConfirmation?: InputMaybe<Scalars["Boolean"]>;
  billingCountryCode?: InputMaybe<Scalars["String"]>;
  billingId?: InputMaybe<Scalars["String"]>;
  billingInformation?: InputMaybe<SubscriptionBillingInfo>;
  billingPeriod?: InputMaybe<BillingPeriod>;
  checkoutOptions?: InputMaybe<CheckoutOptions>;
  customerId: Scalars["String"];
  planId: Scalars["String"];
  priceUnitAmount?: InputMaybe<Scalars["Float"]>;
  promotionCode?: InputMaybe<Scalars["String"]>;
  refId?: InputMaybe<Scalars["String"]>;
  resourceId?: InputMaybe<Scalars["String"]>;
  skipTrial?: InputMaybe<Scalars["Boolean"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  subscriptionId?: InputMaybe<Scalars["String"]>;
};

export type ProvisionSubscriptionResult = {
  __typename?: "ProvisionSubscriptionResult";
  checkoutBillingId?: Maybe<Scalars["String"]>;
  checkoutUrl?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  isScheduled?: Maybe<Scalars["Boolean"]>;
  status: ProvisionSubscriptionStatus;
  subscription?: Maybe<CustomerSubscription>;
};

/** Provision subscription status */
export enum ProvisionSubscriptionStatus {
  PaymentRequired = "PAYMENT_REQUIRED",
  Success = "SUCCESS",
}

export type ProvisionedCustomer = {
  __typename?: "ProvisionedCustomer";
  customer: Customer;
  subscription?: Maybe<CustomerSubscription>;
  subscriptionDecisionStrategy: SubscriptionDecisionStrategy;
  /** @deprecated this field was renamed to 'subscriptionDecisionStrategy' */
  subscriptionStrategyDecision: SubscriptionDecisionStrategy;
};

/** PublishMigrationType */
export enum PublishMigrationType {
  AllCustomers = "ALL_CUSTOMERS",
  NewCustomers = "NEW_CUSTOMERS",
}

export type PublishPackageResult = {
  __typename?: "PublishPackageResult";
  taskId?: Maybe<Scalars["String"]>;
};

export type Query = {
  __typename?: "Query";
  addons: AddonConnection;
  cachedEntitlements: Array<Entitlement>;
  coupon?: Maybe<Coupon>;
  coupons: CouponConnection;
  currentEnvironment: Scalars["String"];
  currentUser: User;
  customerPortal: CustomerPortal;
  customerResources: CustomerResourceConnection;
  customerSubscriptions: CustomerSubscriptionConnection;
  customers: CustomerConnection;
  entitlement: Entitlement;
  entitlements: Array<EntitlementWithSummary>;
  environments: EnvironmentConnection;
  experiment?: Maybe<Experiment>;
  experiments: ExperimentConnection;
  features: FeatureConnection;
  fetchAccount?: Maybe<Account>;
  getActiveSubscriptions: Array<CustomerSubscription>;
  getAddonByRefId?: Maybe<Addon>;
  getCustomerByRefId?: Maybe<Customer>;
  getExperimentStats: ExperimentStats;
  /** @deprecated Deprecated query, use paywall query instead */
  getPaywall: Array<Plan>;
  getPlanByRefId?: Maybe<Plan>;
  hook?: Maybe<Hook>;
  hooks: HookConnection;
  importIntegrationTasks: ImportIntegrationTaskConnection;
  integrations: IntegrationConnection;
  members: MemberConnection;
  mockPaywall: MockPaywall;
  packageEntitlements: PackageEntitlementConnection;
  paywall: Paywall;
  plans: PlanConnection;
  products: ProductConnection;
  promotionalEntitlements: PromotionalEntitlementConnection;
  sdkConfiguration?: Maybe<SdkConfiguration>;
  sendTestHook: TestHookResult;
  stripeCustomers: StripeCustomerSearchResult;
  stripeProducts: StripeProductSearchResult;
  stripeSubscriptions: StripeSubscriptionSearchResult;
  subscriptionEntitlements: SubscriptionEntitlementConnection;
  subscriptionMigrationTasks: SubscriptionMigrationTaskConnection;
  testHookData: TestHook;
  usageHistory: UsageHistory;
  usageMeasurements: UsageMeasurementConnection;
  widgetConfiguration: WidgetConfiguration;
};

export type QueryAddonsArgs = {
  filter?: InputMaybe<AddonFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<AddonSort>>;
};

export type QueryCachedEntitlementsArgs = {
  query: FetchEntitlementsQuery;
};

export type QueryCouponArgs = {
  id: Scalars["String"];
};

export type QueryCouponsArgs = {
  filter?: InputMaybe<CouponFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<CouponSort>>;
};

export type QueryCustomerPortalArgs = {
  input: CustomerPortalInput;
};

export type QueryCustomerResourcesArgs = {
  filter?: InputMaybe<CustomerResourceFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<CustomerResourceSort>>;
};

export type QueryCustomerSubscriptionsArgs = {
  filter?: InputMaybe<CustomerSubscriptionFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<CustomerSubscriptionSort>>;
};

export type QueryCustomersArgs = {
  filter?: InputMaybe<CustomerFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<CustomerSort>>;
};

export type QueryEntitlementArgs = {
  query: FetchEntitlementQuery;
};

export type QueryEntitlementsArgs = {
  query: FetchEntitlementsQuery;
};

export type QueryEnvironmentsArgs = {
  filter?: InputMaybe<EnvironmentFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<EnvironmentSort>>;
};

export type QueryExperimentArgs = {
  id: Scalars["String"];
};

export type QueryExperimentsArgs = {
  filter?: InputMaybe<ExperimentFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<ExperimentSort>>;
};

export type QueryFeaturesArgs = {
  filter?: InputMaybe<FeatureFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<FeatureSort>>;
};

export type QueryGetActiveSubscriptionsArgs = {
  input: GetActiveSubscriptionsInput;
};

export type QueryGetAddonByRefIdArgs = {
  input: GetPackageByRefIdInput;
};

export type QueryGetCustomerByRefIdArgs = {
  input: GetCustomerByRefIdInput;
};

export type QueryGetExperimentStatsArgs = {
  query: ExperimentStatsQuery;
};

export type QueryGetPaywallArgs = {
  input: GetPaywallInput;
};

export type QueryGetPlanByRefIdArgs = {
  input: GetPackageByRefIdInput;
};

export type QueryHookArgs = {
  id: Scalars["String"];
};

export type QueryHooksArgs = {
  filter?: InputMaybe<HookFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<HookSort>>;
};

export type QueryImportIntegrationTasksArgs = {
  filter?: InputMaybe<ImportIntegrationTaskFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<ImportIntegrationTaskSort>>;
};

export type QueryIntegrationsArgs = {
  filter?: InputMaybe<IntegrationFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<IntegrationSort>>;
};

export type QueryMembersArgs = {
  filter?: InputMaybe<MemberFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<MemberSort>>;
};

export type QueryMockPaywallArgs = {
  input: GetPaywallInput;
};

export type QueryPackageEntitlementsArgs = {
  filter?: InputMaybe<PackageEntitlementFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<PackageEntitlementSort>>;
};

export type QueryPaywallArgs = {
  input: GetPaywallInput;
};

export type QueryPlansArgs = {
  filter?: InputMaybe<PlanFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<PlanSort>>;
};

export type QueryProductsArgs = {
  filter?: InputMaybe<ProductFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<ProductSort>>;
};

export type QueryPromotionalEntitlementsArgs = {
  filter?: InputMaybe<PromotionalEntitlementFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<PromotionalEntitlementSort>>;
};

export type QuerySendTestHookArgs = {
  testHookInput: TestHookInput;
};

export type QueryStripeCustomersArgs = {
  input: StripeCustomerSearchInput;
};

export type QueryStripeProductsArgs = {
  input: StripeProductSearchInput;
};

export type QueryStripeSubscriptionsArgs = {
  input: StripeSubscriptionSearchInput;
};

export type QuerySubscriptionEntitlementsArgs = {
  filter?: InputMaybe<SubscriptionEntitlementFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<SubscriptionEntitlementSort>>;
};

export type QuerySubscriptionMigrationTasksArgs = {
  filter?: InputMaybe<SubscriptionMigrationTaskFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<SubscriptionMigrationTaskSort>>;
};

export type QueryTestHookDataArgs = {
  eventLogType: EventLogType;
};

export type QueryUsageHistoryArgs = {
  usageHistoryInput: UsageHistoryInput;
};

export type QueryUsageMeasurementsArgs = {
  filter?: InputMaybe<UsageMeasurementFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<UsageMeasurementSort>>;
};

export type QueryWidgetConfigurationArgs = {
  input: GetWidgetConfigurationInput;
};

export type RemoveBasePlanFromPlanInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type RemoveCompatibleAddonsFromPlanInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type RemoveCouponFromCustomerInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type RemoveCouponFromCustomerSubscriptionInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type RemoveExperimentFromCustomerInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type RemoveExperimentFromCustomerSubscriptionInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type ResetPeriodConfiguration =
  | MonthlyResetPeriodConfig
  | WeeklyResetPeriodConfig;

export type ResyncIntegrationInput = {
  environmentId: Scalars["String"];
  vendorIdentifier: VendorIdentifier;
};

export type ResyncIntegrationResult = {
  __typename?: "ResyncIntegrationResult";
  taskId: Scalars["String"];
};

export type ScheduleVariables =
  | BillingPeriodChangeVariables
  | DowngradeChangeVariables
  | UnitAmountChangeVariables;

export type SdkConfiguration = {
  __typename?: "SdkConfiguration";
  isWidgetWatermarkEnabled?: Maybe<Scalars["Boolean"]>;
  sentryDsn?: Maybe<Scalars["String"]>;
};

export type SetBasePlanOnPlanInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type SetCompatibleAddonsOnPlanInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The ids of the relations. */
  relationIds: Array<Scalars["String"]>;
};

export type SetCouponOnCustomerInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type SetCouponOnCustomerSubscriptionInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type SetExperimentOnCustomerInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

export type SetExperimentOnCustomerSubscriptionInput = {
  /** The id of the record. */
  id: Scalars["String"];
  /** The id of relation. */
  relationId: Scalars["String"];
};

/** Sort Directions */
export enum SortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = "NULLS_FIRST",
  NullsLast = "NULLS_LAST",
}

export enum SourceType {
  JsClientSdk = "JS_CLIENT_SDK",
  NodeServerSdk = "NODE_SERVER_SDK",
}

export type StartExperimentInput = {
  environmentId?: InputMaybe<Scalars["String"]>;
  refId: Scalars["String"];
};

export type StopExperimentInput = {
  environmentId?: InputMaybe<Scalars["String"]>;
  refId: Scalars["String"];
};

export type StringChangeDto = {
  __typename?: "StringChangeDTO";
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  changeType?: Maybe<ChangeType>;
};

export type StringFieldComparison = {
  eq?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  iLike?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  is?: InputMaybe<Scalars["Boolean"]>;
  isNot?: InputMaybe<Scalars["Boolean"]>;
  like?: InputMaybe<Scalars["String"]>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  neq?: InputMaybe<Scalars["String"]>;
  notILike?: InputMaybe<Scalars["String"]>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
  notLike?: InputMaybe<Scalars["String"]>;
};

export type StripeCredentials = {
  __typename?: "StripeCredentials";
  accountDisplayName: Scalars["String"];
  accountId: Scalars["String"];
  isTestMode: Scalars["Boolean"];
};

export type StripeCredentialsInput = {
  accountId?: InputMaybe<Scalars["String"]>;
  authorizationCode: Scalars["String"];
  isTestMode: Scalars["Boolean"];
};

export type StripeCustomer = {
  __typename?: "StripeCustomer";
  createdAt: Scalars["DateTime"];
  email: Scalars["String"];
  environmentId?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  isSynced: Scalars["Boolean"];
  name: Scalars["String"];
  subscriptionPlanName?: Maybe<Scalars["String"]>;
  subscriptionsCount: Scalars["Float"];
};

export type StripeCustomerIsDeleted = {
  __typename?: "StripeCustomerIsDeleted";
  billingId: Scalars["String"];
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
};

export type StripeCustomerSearchInput = {
  customerName?: InputMaybe<Scalars["String"]>;
  environmentId: Scalars["String"];
  nextPage?: InputMaybe<Scalars["String"]>;
};

export type StripeCustomerSearchResult = {
  __typename?: "StripeCustomerSearchResult";
  customers: Array<StripeCustomer>;
  nextPage?: Maybe<Scalars["String"]>;
  totalCount: Scalars["Int"];
};

export type StripeProduct = {
  __typename?: "StripeProduct";
  environmentId?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  isSynced: Scalars["Boolean"];
  name: Scalars["String"];
  notSupportedForImport: Scalars["Boolean"];
  prices: Array<StripeProductPrice>;
  updatedAt: Scalars["DateTime"];
};

export type StripeProductPrice = {
  __typename?: "StripeProductPrice";
  amount: Scalars["Float"];
  billingPeriod?: Maybe<BillingPeriod>;
};

export type StripeProductSearchInput = {
  environmentId: Scalars["String"];
  nextPage?: InputMaybe<Scalars["String"]>;
  productName?: InputMaybe<Scalars["String"]>;
};

export type StripeProductSearchResult = {
  __typename?: "StripeProductSearchResult";
  nextPage?: Maybe<Scalars["String"]>;
  products: Array<StripeProduct>;
  totalCount: Scalars["Int"];
  usageBasedProductPresent?: Maybe<Scalars["Boolean"]>;
};

export type StripeSubscription = {
  __typename?: "StripeSubscription";
  id: Scalars["String"];
};

export type StripeSubscriptionSearchInput = {
  environmentId: Scalars["String"];
  nextPage?: InputMaybe<Scalars["String"]>;
};

export type StripeSubscriptionSearchResult = {
  __typename?: "StripeSubscriptionSearchResult";
  nextPage?: Maybe<Scalars["String"]>;
  subscriptions: Array<StripeSubscription>;
  totalCount: Scalars["Int"];
};

export type Subscription = {
  __typename?: "Subscription";
  entitlementsUpdated: EntitlementsUpdated;
  usageUpdated: UsageUpdated;
};

export type SubscriptionAddon = {
  __typename?: "SubscriptionAddon";
  addon: Addon;
  createdAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["String"];
  price?: Maybe<Price>;
  quantity: Scalars["Float"];
  subscription: CustomerSubscription;
  updatedAt: Scalars["DateTime"];
};

export type SubscriptionAddonAggregateGroupBy = {
  __typename?: "SubscriptionAddonAggregateGroupBy";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["Float"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type SubscriptionAddonAvgAggregate = {
  __typename?: "SubscriptionAddonAvgAggregate";
  quantity?: Maybe<Scalars["Float"]>;
};

export type SubscriptionAddonCountAggregate = {
  __typename?: "SubscriptionAddonCountAggregate";
  createdAt?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["Int"]>;
};

export type SubscriptionAddonEdge = {
  __typename?: "SubscriptionAddonEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the SubscriptionAddon */
  node: SubscriptionAddon;
};

export type SubscriptionAddonFilter = {
  addon?: InputMaybe<SubscriptionAddonFilterAddonFilter>;
  and?: InputMaybe<Array<SubscriptionAddonFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SubscriptionAddonFilter>>;
  price?: InputMaybe<SubscriptionAddonFilterPriceFilter>;
  quantity?: InputMaybe<NumberFieldComparison>;
  subscription?: InputMaybe<SubscriptionAddonFilterCustomerSubscriptionFilter>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type SubscriptionAddonFilterAddonFilter = {
  and?: InputMaybe<Array<SubscriptionAddonFilterAddonFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  isLatest?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<SubscriptionAddonFilterAddonFilter>>;
  pricingType?: InputMaybe<PricingTypeFilterComparison>;
  productId?: InputMaybe<StringFieldComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<PackageStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  versionNumber?: InputMaybe<IntFieldComparison>;
};

export type SubscriptionAddonFilterCustomerSubscriptionFilter = {
  and?: InputMaybe<Array<SubscriptionAddonFilterCustomerSubscriptionFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  cancelReason?: InputMaybe<SubscriptionCancelReasonFilterComparison>;
  cancellationDate?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  crmId?: InputMaybe<StringFieldComparison>;
  crmLinkUrl?: InputMaybe<StringFieldComparison>;
  effectiveEndDate?: InputMaybe<DateFieldComparison>;
  endDate?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  oldBillingId?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SubscriptionAddonFilterCustomerSubscriptionFilter>>;
  pricingType?: InputMaybe<PricingTypeFilterComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<DateFieldComparison>;
  status?: InputMaybe<SubscriptionStatusFilterComparison>;
  subscriptionId?: InputMaybe<StringFieldComparison>;
  trialEndDate?: InputMaybe<DateFieldComparison>;
};

export type SubscriptionAddonFilterPriceFilter = {
  and?: InputMaybe<Array<SubscriptionAddonFilterPriceFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  billingModel?: InputMaybe<BillingModelFilterComparison>;
  billingPeriod?: InputMaybe<BillingPeriodFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SubscriptionAddonFilterPriceFilter>>;
};

export type SubscriptionAddonInput = {
  addonId: Scalars["String"];
  quantity?: InputMaybe<Scalars["Int"]>;
};

export type SubscriptionAddonMaxAggregate = {
  __typename?: "SubscriptionAddonMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["Float"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type SubscriptionAddonMinAggregate = {
  __typename?: "SubscriptionAddonMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["Float"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type SubscriptionAddonSort = {
  direction: SortDirection;
  field: SubscriptionAddonSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SubscriptionAddonSortFields {
  CreatedAt = "createdAt",
  Id = "id",
  Quantity = "quantity",
  UpdatedAt = "updatedAt",
}

export type SubscriptionAddonSumAggregate = {
  __typename?: "SubscriptionAddonSumAggregate";
  quantity?: Maybe<Scalars["Float"]>;
};

export type SubscriptionAlreadyCanceledOrExpired = {
  __typename?: "SubscriptionAlreadyCanceledOrExpired";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
  refId: Scalars["String"];
  status: SubscriptionStatus;
};

export type SubscriptionBillingInfo = {
  taxRateIds?: InputMaybe<Array<Scalars["String"]>>;
};

/** Subscription cancellation status */
export enum SubscriptionCancelReason {
  CancelledByBilling = "CancelledByBilling",
  DetachBilling = "DetachBilling",
  Expired = "Expired",
  Immediate = "Immediate",
  PendingPaymentExpired = "PendingPaymentExpired",
  ScheduledCancellation = "ScheduledCancellation",
  TrialConverted = "TrialConverted",
  TrialEnded = "TrialEnded",
  UpgradeOrDowngrade = "UpgradeOrDowngrade",
}

export type SubscriptionCancelReasonFilterComparison = {
  eq?: InputMaybe<SubscriptionCancelReason>;
  gt?: InputMaybe<SubscriptionCancelReason>;
  gte?: InputMaybe<SubscriptionCancelReason>;
  iLike?: InputMaybe<SubscriptionCancelReason>;
  in?: InputMaybe<Array<SubscriptionCancelReason>>;
  is?: InputMaybe<Scalars["Boolean"]>;
  isNot?: InputMaybe<Scalars["Boolean"]>;
  like?: InputMaybe<SubscriptionCancelReason>;
  lt?: InputMaybe<SubscriptionCancelReason>;
  lte?: InputMaybe<SubscriptionCancelReason>;
  neq?: InputMaybe<SubscriptionCancelReason>;
  notILike?: InputMaybe<SubscriptionCancelReason>;
  notIn?: InputMaybe<Array<SubscriptionCancelReason>>;
  notLike?: InputMaybe<SubscriptionCancelReason>;
};

export type SubscriptionCancellationInput = {
  endDate?: InputMaybe<Scalars["DateTime"]>;
  environmentId?: InputMaybe<Scalars["String"]>;
  subscriptionCancellationTime?: InputMaybe<SubscriptionCancellationTime>;
  subscriptionRefId: Scalars["String"];
};

export enum SubscriptionCancellationTime {
  EndOfBillingPeriod = "END_OF_BILLING_PERIOD",
  Immediate = "IMMEDIATE",
  SpecificDate = "SPECIFIC_DATE",
}

export type SubscriptionCoupon = {
  __typename?: "SubscriptionCoupon";
  discountValue: Scalars["Float"];
  environmentId: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
  refId: Scalars["String"];
  type: CouponType;
};

/** Subscription decision strategy */
export enum SubscriptionDecisionStrategy {
  PredefinedFreePlan = "PREDEFINED_FREE_PLAN",
  PredefinedTrialPlan = "PREDEFINED_TRIAL_PLAN",
  RequestedPlan = "REQUESTED_PLAN",
  SkippedSubscriptionCreation = "SKIPPED_SUBSCRIPTION_CREATION",
}

export enum SubscriptionEndSetup {
  CancelSubscription = "CANCEL_SUBSCRIPTION",
  DowngradeToFree = "DOWNGRADE_TO_FREE",
}

export type SubscriptionEntitlement = {
  __typename?: "SubscriptionEntitlement";
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  environmentId: Scalars["String"];
  feature: Feature;
  featureId: Scalars["String"];
  hasUnlimitedUsage?: Maybe<Scalars["Boolean"]>;
  id: Scalars["String"];
  resetPeriod?: Maybe<EntitlementResetPeriod>;
  resetPeriodConfiguration?: Maybe<ResetPeriodConfiguration>;
  subscription: CustomerSubscription;
  subscriptionId: Scalars["String"];
  updatedAt?: Maybe<Scalars["DateTime"]>;
  usageLimit?: Maybe<Scalars["Float"]>;
};

export type SubscriptionEntitlementAggregateGroupBy = {
  __typename?: "SubscriptionEntitlementAggregateGroupBy";
  createdAt?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  subscriptionId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type SubscriptionEntitlementConnection = {
  __typename?: "SubscriptionEntitlementConnection";
  /** Array of edges. */
  edges: Array<SubscriptionEntitlementEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type SubscriptionEntitlementCountAggregate = {
  __typename?: "SubscriptionEntitlementCountAggregate";
  createdAt?: Maybe<Scalars["Int"]>;
  environmentId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  subscriptionId?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["Int"]>;
};

export type SubscriptionEntitlementEdge = {
  __typename?: "SubscriptionEntitlementEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the SubscriptionEntitlement */
  node: SubscriptionEntitlement;
};

export type SubscriptionEntitlementFilter = {
  and?: InputMaybe<Array<SubscriptionEntitlementFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  feature?: InputMaybe<SubscriptionEntitlementFilterFeatureFilter>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SubscriptionEntitlementFilter>>;
  subscription?: InputMaybe<SubscriptionEntitlementFilterCustomerSubscriptionFilter>;
  subscriptionId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type SubscriptionEntitlementFilterCustomerSubscriptionFilter = {
  and?: InputMaybe<
    Array<SubscriptionEntitlementFilterCustomerSubscriptionFilter>
  >;
  billingId?: InputMaybe<StringFieldComparison>;
  cancelReason?: InputMaybe<SubscriptionCancelReasonFilterComparison>;
  cancellationDate?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  crmId?: InputMaybe<StringFieldComparison>;
  crmLinkUrl?: InputMaybe<StringFieldComparison>;
  effectiveEndDate?: InputMaybe<DateFieldComparison>;
  endDate?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  oldBillingId?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<
    Array<SubscriptionEntitlementFilterCustomerSubscriptionFilter>
  >;
  pricingType?: InputMaybe<PricingTypeFilterComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<DateFieldComparison>;
  status?: InputMaybe<SubscriptionStatusFilterComparison>;
  subscriptionId?: InputMaybe<StringFieldComparison>;
  trialEndDate?: InputMaybe<DateFieldComparison>;
};

export type SubscriptionEntitlementFilterFeatureFilter = {
  and?: InputMaybe<Array<SubscriptionEntitlementFilterFeatureFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  featureStatus?: InputMaybe<FeatureStatusFilterComparison>;
  featureType?: InputMaybe<FeatureTypeFilterComparison>;
  id?: InputMaybe<StringFieldComparison>;
  meterType?: InputMaybe<MeterTypeFilterComparison>;
  or?: InputMaybe<Array<SubscriptionEntitlementFilterFeatureFilter>>;
  refId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type SubscriptionEntitlementInput = {
  description?: InputMaybe<Scalars["String"]>;
  featureId: Scalars["String"];
  hasUnlimitedUsage?: InputMaybe<Scalars["Boolean"]>;
  monthlyResetPeriodConfiguration?: InputMaybe<MonthlyResetPeriodConfigInput>;
  resetPeriod?: InputMaybe<EntitlementResetPeriod>;
  usageLimit?: InputMaybe<Scalars["Float"]>;
  weeklyResetPeriodConfiguration?: InputMaybe<WeeklyResetPeriodConfigInput>;
};

export type SubscriptionEntitlementMaxAggregate = {
  __typename?: "SubscriptionEntitlementMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  subscriptionId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type SubscriptionEntitlementMinAggregate = {
  __typename?: "SubscriptionEntitlementMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  subscriptionId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type SubscriptionEntitlementSort = {
  direction: SortDirection;
  field: SubscriptionEntitlementSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SubscriptionEntitlementSortFields {
  CreatedAt = "createdAt",
  EnvironmentId = "environmentId",
  Id = "id",
  SubscriptionId = "subscriptionId",
  UpdatedAt = "updatedAt",
}

export type SubscriptionInput = {
  additionalMetaData?: InputMaybe<Scalars["JSON"]>;
  addons?: InputMaybe<Array<SubscriptionAddonInput>>;
  awaitPaymentConfirmation?: InputMaybe<Scalars["Boolean"]>;
  billingCountryCode?: InputMaybe<Scalars["String"]>;
  billingId?: InputMaybe<Scalars["String"]>;
  billingInformation?: InputMaybe<SubscriptionBillingInfo>;
  billingPeriod?: InputMaybe<BillingPeriod>;
  crmId?: InputMaybe<Scalars["String"]>;
  customerId: Scalars["String"];
  endDate?: InputMaybe<Scalars["DateTime"]>;
  environmentId?: InputMaybe<Scalars["String"]>;
  isCustomPriceSubscription?: InputMaybe<Scalars["Boolean"]>;
  isOverridingTrialConfig?: InputMaybe<Scalars["Boolean"]>;
  isTrial?: InputMaybe<Scalars["Boolean"]>;
  planId: Scalars["String"];
  priceUnitAmount?: InputMaybe<Scalars["Float"]>;
  promotionCode?: InputMaybe<Scalars["String"]>;
  refId?: InputMaybe<Scalars["String"]>;
  resourceId?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  subscriptionEntitlements?: InputMaybe<Array<SubscriptionEntitlementInput>>;
  subscriptionId?: InputMaybe<Scalars["String"]>;
};

export type SubscriptionMigrationInput = {
  environmentId?: InputMaybe<Scalars["String"]>;
  subscriptionId: Scalars["String"];
};

export type SubscriptionMigrationTask = {
  __typename?: "SubscriptionMigrationTask";
  affectedCustomersCount?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  endDate?: Maybe<Scalars["DateTime"]>;
  environmentId: Scalars["String"];
  id: Scalars["String"];
  initiatedPackageId?: Maybe<Scalars["String"]>;
  packages: Array<PackageDto>;
  progress: Scalars["Int"];
  startDate: Scalars["DateTime"];
  status: TaskStatus;
  taskType: TaskType;
};

export type SubscriptionMigrationTaskPackagesArgs = {
  filter?: InputMaybe<PackageDtoFilter>;
  sorting?: InputMaybe<Array<PackageDtoSort>>;
};

export type SubscriptionMigrationTaskAggregateGroupBy = {
  __typename?: "SubscriptionMigrationTaskAggregateGroupBy";
  createdAt?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  status?: Maybe<TaskStatus>;
  taskType?: Maybe<TaskType>;
};

export type SubscriptionMigrationTaskConnection = {
  __typename?: "SubscriptionMigrationTaskConnection";
  /** Array of edges. */
  edges: Array<SubscriptionMigrationTaskEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type SubscriptionMigrationTaskCountAggregate = {
  __typename?: "SubscriptionMigrationTaskCountAggregate";
  createdAt?: Maybe<Scalars["Int"]>;
  environmentId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["Int"]>;
  taskType?: Maybe<Scalars["Int"]>;
};

export type SubscriptionMigrationTaskEdge = {
  __typename?: "SubscriptionMigrationTaskEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the SubscriptionMigrationTask */
  node: SubscriptionMigrationTask;
};

export type SubscriptionMigrationTaskFilter = {
  and?: InputMaybe<Array<SubscriptionMigrationTaskFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SubscriptionMigrationTaskFilter>>;
  status?: InputMaybe<TaskStatusFilterComparison>;
  taskType?: InputMaybe<TaskTypeFilterComparison>;
};

export type SubscriptionMigrationTaskMaxAggregate = {
  __typename?: "SubscriptionMigrationTaskMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  status?: Maybe<TaskStatus>;
  taskType?: Maybe<TaskType>;
};

export type SubscriptionMigrationTaskMinAggregate = {
  __typename?: "SubscriptionMigrationTaskMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  status?: Maybe<TaskStatus>;
  taskType?: Maybe<TaskType>;
};

export type SubscriptionMigrationTaskSort = {
  direction: SortDirection;
  field: SubscriptionMigrationTaskSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SubscriptionMigrationTaskSortFields {
  CreatedAt = "createdAt",
  EnvironmentId = "environmentId",
  Id = "id",
  Status = "status",
  TaskType = "taskType",
}

export type SubscriptionMustHaveSinglePlanError = {
  __typename?: "SubscriptionMustHaveSinglePlanError";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
  refIds: Array<Scalars["String"]>;
};

export type SubscriptionPreview = {
  __typename?: "SubscriptionPreview";
  billingPeriodRange: DateRange;
  discount?: Maybe<SubscriptionPreviewDiscountDto>;
  proration?: Maybe<SubscriptionPreviewProrations>;
  subTotal: Money;
  subscription?: Maybe<SubscriptionPricePreviewDto>;
  total: Money;
};

export type SubscriptionPreviewDiscountDto = {
  __typename?: "SubscriptionPreviewDiscountDTO";
  durationInMonths?: Maybe<Scalars["Float"]>;
  durationType: DiscountDurationType;
  type: DiscountType;
  value: Scalars["Float"];
};

export type SubscriptionPreviewProrations = {
  __typename?: "SubscriptionPreviewProrations";
  credit: Money;
  debit: Money;
  netAmount: Money;
  prorationDate: Scalars["DateTime"];
};

export type SubscriptionPrice = {
  __typename?: "SubscriptionPrice";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["String"];
  price?: Maybe<Price>;
  subscription: CustomerSubscription;
  updatedAt: Scalars["DateTime"];
  usageLimit?: Maybe<Scalars["Float"]>;
};

export type SubscriptionPriceAggregateGroupBy = {
  __typename?: "SubscriptionPriceAggregateGroupBy";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  usageLimit?: Maybe<Scalars["Float"]>;
};

export type SubscriptionPriceAvgAggregate = {
  __typename?: "SubscriptionPriceAvgAggregate";
  usageLimit?: Maybe<Scalars["Float"]>;
};

export type SubscriptionPriceCountAggregate = {
  __typename?: "SubscriptionPriceCountAggregate";
  createdAt?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["Int"]>;
  usageLimit?: Maybe<Scalars["Int"]>;
};

export type SubscriptionPriceEdge = {
  __typename?: "SubscriptionPriceEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the SubscriptionPrice */
  node: SubscriptionPrice;
};

export type SubscriptionPriceFilter = {
  and?: InputMaybe<Array<SubscriptionPriceFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SubscriptionPriceFilter>>;
  price?: InputMaybe<SubscriptionPriceFilterPriceFilter>;
  subscription?: InputMaybe<SubscriptionPriceFilterCustomerSubscriptionFilter>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  usageLimit?: InputMaybe<NumberFieldComparison>;
};

export type SubscriptionPriceFilterCustomerSubscriptionFilter = {
  and?: InputMaybe<Array<SubscriptionPriceFilterCustomerSubscriptionFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  cancelReason?: InputMaybe<SubscriptionCancelReasonFilterComparison>;
  cancellationDate?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  crmId?: InputMaybe<StringFieldComparison>;
  crmLinkUrl?: InputMaybe<StringFieldComparison>;
  effectiveEndDate?: InputMaybe<DateFieldComparison>;
  endDate?: InputMaybe<DateFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  oldBillingId?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SubscriptionPriceFilterCustomerSubscriptionFilter>>;
  pricingType?: InputMaybe<PricingTypeFilterComparison>;
  refId?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<DateFieldComparison>;
  status?: InputMaybe<SubscriptionStatusFilterComparison>;
  subscriptionId?: InputMaybe<StringFieldComparison>;
  trialEndDate?: InputMaybe<DateFieldComparison>;
};

export type SubscriptionPriceFilterPriceFilter = {
  and?: InputMaybe<Array<SubscriptionPriceFilterPriceFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  billingModel?: InputMaybe<BillingModelFilterComparison>;
  billingPeriod?: InputMaybe<BillingPeriodFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SubscriptionPriceFilterPriceFilter>>;
};

export type SubscriptionPriceMaxAggregate = {
  __typename?: "SubscriptionPriceMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  usageLimit?: Maybe<Scalars["Float"]>;
};

export type SubscriptionPriceMinAggregate = {
  __typename?: "SubscriptionPriceMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  usageLimit?: Maybe<Scalars["Float"]>;
};

export type SubscriptionPricePreviewDto = {
  __typename?: "SubscriptionPricePreviewDTO";
  subTotal: Money;
  total: Money;
};

export type SubscriptionPriceSort = {
  direction: SortDirection;
  field: SubscriptionPriceSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SubscriptionPriceSortFields {
  CreatedAt = "createdAt",
  Id = "id",
  UpdatedAt = "updatedAt",
  UsageLimit = "usageLimit",
}

export type SubscriptionPriceSumAggregate = {
  __typename?: "SubscriptionPriceSumAggregate";
  usageLimit?: Maybe<Scalars["Float"]>;
};

/** Subscription scheduled schedule status */
export enum SubscriptionScheduleStatus {
  Canceled = "Canceled",
  Done = "Done",
  Failed = "Failed",
  Scheduled = "Scheduled",
}

/** Subscription scheduled schedule type */
export enum SubscriptionScheduleType {
  BillingPeriod = "BillingPeriod",
  Downgrade = "Downgrade",
  UnitAmount = "UnitAmount",
}

export type SubscriptionScheduledUpdate = {
  __typename?: "SubscriptionScheduledUpdate";
  billingId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  scheduleStatus: SubscriptionScheduleStatus;
  scheduleVariables?: Maybe<ScheduleVariables>;
  scheduledExecutionTime: Scalars["DateTime"];
  subscriptionScheduleType: SubscriptionScheduleType;
  targetPackage?: Maybe<PackageDto>;
};

export enum SubscriptionStartSetup {
  FreePlan = "FREE_PLAN",
  PlanSelection = "PLAN_SELECTION",
  TrialPeriod = "TRIAL_PERIOD",
}

/** Subscription status */
export enum SubscriptionStatus {
  Active = "ACTIVE",
  Canceled = "CANCELED",
  Expired = "EXPIRED",
  InTrial = "IN_TRIAL",
  NotStarted = "NOT_STARTED",
  PaymentPending = "PAYMENT_PENDING",
}

export type SubscriptionStatusFilterComparison = {
  eq?: InputMaybe<SubscriptionStatus>;
  gt?: InputMaybe<SubscriptionStatus>;
  gte?: InputMaybe<SubscriptionStatus>;
  iLike?: InputMaybe<SubscriptionStatus>;
  in?: InputMaybe<Array<SubscriptionStatus>>;
  is?: InputMaybe<Scalars["Boolean"]>;
  isNot?: InputMaybe<Scalars["Boolean"]>;
  like?: InputMaybe<SubscriptionStatus>;
  lt?: InputMaybe<SubscriptionStatus>;
  lte?: InputMaybe<SubscriptionStatus>;
  neq?: InputMaybe<SubscriptionStatus>;
  notILike?: InputMaybe<SubscriptionStatus>;
  notIn?: InputMaybe<Array<SubscriptionStatus>>;
  notLike?: InputMaybe<SubscriptionStatus>;
};

export type SubscriptionUpdateScheduleCancellationInput = {
  environmentId?: InputMaybe<Scalars["String"]>;
  subscriptionId: Scalars["String"];
};

export type SyncState = {
  __typename?: "SyncState";
  error?: Maybe<Scalars["String"]>;
  status: SyncStatus;
  vendorIdentifier: VendorIdentifier;
};

/** Status of the integration sync */
export enum SyncStatus {
  Error = "ERROR",
  NoSyncRequired = "NO_SYNC_REQUIRED",
  Pending = "PENDING",
  Success = "SUCCESS",
}

export enum TaskStatus {
  Completed = "COMPLETED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
  PartiallyFailed = "PARTIALLY_FAILED",
  Pending = "PENDING",
}

export type TaskStatusFilterComparison = {
  eq?: InputMaybe<TaskStatus>;
  gt?: InputMaybe<TaskStatus>;
  gte?: InputMaybe<TaskStatus>;
  iLike?: InputMaybe<TaskStatus>;
  in?: InputMaybe<Array<TaskStatus>>;
  is?: InputMaybe<Scalars["Boolean"]>;
  isNot?: InputMaybe<Scalars["Boolean"]>;
  like?: InputMaybe<TaskStatus>;
  lt?: InputMaybe<TaskStatus>;
  lte?: InputMaybe<TaskStatus>;
  neq?: InputMaybe<TaskStatus>;
  notILike?: InputMaybe<TaskStatus>;
  notIn?: InputMaybe<Array<TaskStatus>>;
  notLike?: InputMaybe<TaskStatus>;
};

export enum TaskType {
  ImportIntegrationCatalog = "IMPORT_INTEGRATION_CATALOG",
  ImportIntegrationCustomers = "IMPORT_INTEGRATION_CUSTOMERS",
  ResyncIntegration = "RESYNC_INTEGRATION",
  SubscriptionMigration = "SUBSCRIPTION_MIGRATION",
}

export type TaskTypeFilterComparison = {
  eq?: InputMaybe<TaskType>;
  gt?: InputMaybe<TaskType>;
  gte?: InputMaybe<TaskType>;
  iLike?: InputMaybe<TaskType>;
  in?: InputMaybe<Array<TaskType>>;
  is?: InputMaybe<Scalars["Boolean"]>;
  isNot?: InputMaybe<Scalars["Boolean"]>;
  like?: InputMaybe<TaskType>;
  lt?: InputMaybe<TaskType>;
  lte?: InputMaybe<TaskType>;
  neq?: InputMaybe<TaskType>;
  notILike?: InputMaybe<TaskType>;
  notIn?: InputMaybe<Array<TaskType>>;
  notLike?: InputMaybe<TaskType>;
};

export type TaxExempt = {
  type: Scalars["String"];
  value: Scalars["String"];
};

export type TestHook = {
  __typename?: "TestHook";
  testHookEventType: Scalars["String"];
  testHookPayload: Scalars["String"];
};

export type TestHookInput = {
  endpointUrl: Scalars["String"];
  environmentId: Scalars["String"];
  hookEventType: EventLogType;
};

export type TestHookResult = {
  __typename?: "TestHookResult";
  responsePayload: Scalars["String"];
  responseStatusCode?: Maybe<Scalars["Float"]>;
  responseStatusText?: Maybe<Scalars["String"]>;
  responseSuccess: Scalars["Boolean"];
};

export type TrialMinDateError = {
  __typename?: "TrialMinDateError";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
};

export type TrialMustBeCancelledImmediately = {
  __typename?: "TrialMustBeCancelledImmediately";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
  refId: Scalars["String"];
};

/** day or month. */
export enum TrialPeriodUnits {
  Day = "DAY",
  Month = "MONTH",
}

export type TrialedPlan = {
  __typename?: "TrialedPlan";
  planId?: Maybe<Scalars["String"]>;
  planRefId?: Maybe<Scalars["String"]>;
  productId?: Maybe<Scalars["String"]>;
  productRefId?: Maybe<Scalars["String"]>;
};

export type TypographyConfiguration = {
  __typename?: "TypographyConfiguration";
  body?: Maybe<FontVariant>;
  fontFamily?: Maybe<Scalars["String"]>;
  h1?: Maybe<FontVariant>;
  h2?: Maybe<FontVariant>;
  h3?: Maybe<FontVariant>;
};

export type TypographyConfigurationInput = {
  body?: InputMaybe<FontVariantInput>;
  fontFamily?: InputMaybe<Scalars["String"]>;
  h1?: InputMaybe<FontVariantInput>;
  h2?: InputMaybe<FontVariantInput>;
  h3?: InputMaybe<FontVariantInput>;
};

export type UnPublishedPackageError = {
  __typename?: "UnPublishedPackageError";
  code: Scalars["String"];
  nonPublishedPackageIds?: Maybe<Array<Scalars["String"]>>;
  packageType: Scalars["String"];
};

export type UncompatibleSubscriptionAddonError = {
  __typename?: "UncompatibleSubscriptionAddonError";
  code: Scalars["String"];
  isValidationError: Scalars["Boolean"];
  nonCompatibleAddons?: Maybe<Array<Scalars["String"]>>;
  planDisplayName: Scalars["String"];
};

export type UnitAmountChangeVariables = {
  __typename?: "UnitAmountChangeVariables";
  newUnitAmount?: Maybe<Scalars["Float"]>;
};

export type UnsupportedFeatureTypeError = {
  __typename?: "UnsupportedFeatureTypeError";
  code: Scalars["String"];
  featureType: Scalars["String"];
};

export type UnsupportedVendorIdentifierError = {
  __typename?: "UnsupportedVendorIdentifierError";
  code: Scalars["String"];
  vendorIdentifier: Scalars["String"];
};

export type UpdateAccountInput = {
  displayName: Scalars["String"];
  id: Scalars["String"];
  subscriptionBillingAnchor?: InputMaybe<BillingAnchor>;
  subscriptionProrationBehavior?: InputMaybe<ProrationBehavior>;
  timezone?: InputMaybe<Scalars["String"]>;
};

export type UpdateCouponInput = {
  additionalMetaData?: InputMaybe<Scalars["JSON"]>;
  description?: InputMaybe<Scalars["String"]>;
  environmentId?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  refId: Scalars["String"];
};

export type UpdateCustomerInput = {
  additionalMetaData?: InputMaybe<Scalars["JSON"]>;
  billingId?: InputMaybe<Scalars["String"]>;
  billingInformation?: InputMaybe<CustomerBillingInfo>;
  couponRefId?: InputMaybe<Scalars["String"]>;
  crmId?: InputMaybe<Scalars["String"]>;
  customerId?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  environmentId?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  refId?: InputMaybe<Scalars["String"]>;
};

export type UpdateEntitlementsOrderDto = {
  __typename?: "UpdateEntitlementsOrderDTO";
  id: Scalars["String"];
  order?: Maybe<Scalars["Float"]>;
};

export type UpdateExperimentInput = {
  controlGroupName?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  environmentId?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  productId?: InputMaybe<Scalars["String"]>;
  productSettings?: InputMaybe<ProductSettingsInput>;
  refId: Scalars["String"];
  variantGroupName?: InputMaybe<Scalars["String"]>;
  variantPercentage?: InputMaybe<Scalars["Float"]>;
};

export type UpdateFeature = {
  additionalMetaData?: InputMaybe<Scalars["JSON"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  environmentId?: InputMaybe<Scalars["String"]>;
  featureStatus?: InputMaybe<FeatureStatus>;
  featureType?: InputMaybe<FeatureType>;
  featureUnits?: InputMaybe<Scalars["String"]>;
  featureUnitsPlural?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  meterType?: InputMaybe<MeterType>;
  refId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UpdateFeatureInput = {
  additionalMetaData?: InputMaybe<Scalars["JSON"]>;
  description?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  environmentId: Scalars["String"];
  featureUnits?: InputMaybe<Scalars["String"]>;
  featureUnitsPlural?: InputMaybe<Scalars["String"]>;
  refId: Scalars["String"];
};

export type UpdateHook = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  endpoint?: InputMaybe<Scalars["String"]>;
  environmentId?: InputMaybe<Scalars["String"]>;
  eventLogTypes?: InputMaybe<Array<EventLogType>>;
  id?: InputMaybe<Scalars["String"]>;
  secretKey?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<HookStatus>;
};

export type UpdateIntegrationInput = {
  stripeCredentials?: InputMaybe<StripeCredentialsInput>;
  vendorIdentifier: VendorIdentifier;
  zuoraCredentials?: InputMaybe<ZuoraCredentialsInput>;
};

export type UpdateOneEnvironmentInput = {
  /** The id of the record to update */
  id: Scalars["String"];
  /** The update to apply. */
  update: EnvironmentInput;
};

export type UpdateOneFeatureInput = {
  /** The id of the record to update */
  id: Scalars["String"];
  /** The update to apply. */
  update: UpdateFeature;
};

export type UpdateOneHookInput = {
  /** The id of the record to update */
  id: Scalars["String"];
  /** The update to apply. */
  update: UpdateHook;
};

export type UpdateOneIntegrationInput = {
  /** The id of the record to update */
  id: Scalars["String"];
  /** The update to apply. */
  update: UpdateIntegrationInput;
};

export type UpdateOnePackageEntitlementInput = {
  /** The id of the record to update */
  id: Scalars["String"];
  /** The update to apply. */
  update: PackageEntitlementUpdateInput;
};

export type UpdateOneProductInput = {
  /** The id of the record to update */
  id: Scalars["String"];
  /** The update to apply. */
  update: ProductUpdateInput;
};

export type UpdateOnePromotionalEntitlementInput = {
  /** The id of the record to update */
  id: Scalars["String"];
  /** The update to apply. */
  update: PromotionalEntitlementUpdateInput;
};

export type UpdatePackageEntitlementOrderInput = {
  entitlements: Array<UpdatePackageEntitlementOrderItemInput>;
  environmentId?: InputMaybe<Scalars["String"]>;
  packageId: Scalars["String"];
};

export type UpdatePackageEntitlementOrderItemInput = {
  id: Scalars["String"];
  order?: InputMaybe<Scalars["Float"]>;
};

export type UpdateSubscriptionEntitlementInput = {
  hasUnlimitedUsage?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["String"];
  monthlyResetPeriodConfiguration?: InputMaybe<MonthlyResetPeriodConfigInput>;
  resetPeriod?: InputMaybe<EntitlementResetPeriod>;
  usageLimit?: InputMaybe<Scalars["Float"]>;
  weeklyResetPeriodConfiguration?: InputMaybe<WeeklyResetPeriodConfigInput>;
};

export type UpdateSubscriptionInput = {
  additionalMetaData?: InputMaybe<Scalars["JSON"]>;
  addons?: InputMaybe<Array<SubscriptionAddonInput>>;
  billingPeriod?: InputMaybe<BillingPeriod>;
  environmentId?: InputMaybe<Scalars["String"]>;
  promotionCode?: InputMaybe<Scalars["String"]>;
  refId?: InputMaybe<Scalars["String"]>;
  subscriptionEntitlements?: InputMaybe<
    Array<UpdateSubscriptionEntitlementInput>
  >;
  subscriptionId?: InputMaybe<Scalars["String"]>;
  trialEndDate?: InputMaybe<Scalars["DateTime"]>;
  unitQuantity?: InputMaybe<Scalars["Float"]>;
};

export type UpdateUserInput = {
  department?: InputMaybe<Department>;
  name: Scalars["String"];
};

export type UsageHistory = {
  __typename?: "UsageHistory";
  endDate?: Maybe<Scalars["DateTime"]>;
  startDate: Scalars["DateTime"];
  usageMeasurements: Array<UsageMeasurementPoint>;
};

export type UsageHistoryInput = {
  customerRefId: Scalars["String"];
  endDate?: InputMaybe<Scalars["DateTime"]>;
  environmentId?: InputMaybe<Scalars["String"]>;
  featureRefId: Scalars["String"];
  monthlyResetPeriodConfiguration?: InputMaybe<MonthlyResetPeriodConfigInput>;
  resetPeriod?: InputMaybe<EntitlementResetPeriod>;
  resourceRefId?: InputMaybe<Scalars["String"]>;
  startDate: Scalars["DateTime"];
  weeklyResetPeriodConfiguration?: InputMaybe<WeeklyResetPeriodConfigInput>;
};

export type UsageMeasurement = {
  __typename?: "UsageMeasurement";
  createdAt: Scalars["DateTime"];
  customer: Customer;
  customerId: Scalars["String"];
  environment: Environment;
  environmentId: Scalars["String"];
  feature: Feature;
  featureId: Scalars["String"];
  id: Scalars["String"];
  value: Scalars["Float"];
};

export type UsageMeasurementAggregateGroupBy = {
  __typename?: "UsageMeasurementAggregateGroupBy";
  createdAt?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
};

export type UsageMeasurementAvgAggregate = {
  __typename?: "UsageMeasurementAvgAggregate";
  id?: Maybe<Scalars["Float"]>;
};

export type UsageMeasurementConnection = {
  __typename?: "UsageMeasurementConnection";
  /** Array of edges. */
  edges: Array<UsageMeasurementEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type UsageMeasurementCountAggregate = {
  __typename?: "UsageMeasurementCountAggregate";
  createdAt?: Maybe<Scalars["Int"]>;
  environmentId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

export type UsageMeasurementCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  customerId: Scalars["String"];
  environmentId?: InputMaybe<Scalars["String"]>;
  featureId: Scalars["String"];
  resourceId?: InputMaybe<Scalars["String"]>;
  value: Scalars["Float"];
};

export type UsageMeasurementEdge = {
  __typename?: "UsageMeasurementEdge";
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
  /** The node containing the UsageMeasurement */
  node: UsageMeasurement;
};

export type UsageMeasurementFilter = {
  and?: InputMaybe<Array<UsageMeasurementFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  customer?: InputMaybe<UsageMeasurementFilterCustomerFilter>;
  environmentId?: InputMaybe<StringFieldComparison>;
  feature?: InputMaybe<UsageMeasurementFilterFeatureFilter>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UsageMeasurementFilter>>;
};

export type UsageMeasurementFilterCustomerFilter = {
  and?: InputMaybe<Array<UsageMeasurementFilterCustomerFilter>>;
  billingId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  crmHubspotCompanyId?: InputMaybe<StringFieldComparison>;
  crmHubspotCompanyUrl?: InputMaybe<StringFieldComparison>;
  crmId?: InputMaybe<StringFieldComparison>;
  customerId?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UsageMeasurementFilterCustomerFilter>>;
  refId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type UsageMeasurementFilterFeatureFilter = {
  and?: InputMaybe<Array<UsageMeasurementFilterFeatureFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  displayName?: InputMaybe<StringFieldComparison>;
  environmentId?: InputMaybe<StringFieldComparison>;
  featureStatus?: InputMaybe<FeatureStatusFilterComparison>;
  featureType?: InputMaybe<FeatureTypeFilterComparison>;
  id?: InputMaybe<StringFieldComparison>;
  meterType?: InputMaybe<MeterTypeFilterComparison>;
  or?: InputMaybe<Array<UsageMeasurementFilterFeatureFilter>>;
  refId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type UsageMeasurementMaxAggregate = {
  __typename?: "UsageMeasurementMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
};

export type UsageMeasurementMinAggregate = {
  __typename?: "UsageMeasurementMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]>;
  environmentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
};

export type UsageMeasurementPoint = {
  __typename?: "UsageMeasurementPoint";
  date: Scalars["DateTime"];
  isResetPoint?: Maybe<Scalars["Boolean"]>;
  value: Scalars["Float"];
};

export type UsageMeasurementSort = {
  direction: SortDirection;
  field: UsageMeasurementSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum UsageMeasurementSortFields {
  CreatedAt = "createdAt",
  EnvironmentId = "environmentId",
  Id = "id",
}

export type UsageMeasurementSumAggregate = {
  __typename?: "UsageMeasurementSumAggregate";
  id?: Maybe<Scalars["Float"]>;
};

export type UsageMeasurementUpdated = {
  __typename?: "UsageMeasurementUpdated";
  accountId: Scalars["String"];
  currentUsage: Scalars["Float"];
  customerId: Scalars["String"];
  environmentId: Scalars["String"];
  featureId: Scalars["String"];
  nextResetDate?: Maybe<Scalars["Float"]>;
  resourceId?: Maybe<Scalars["String"]>;
};

export type UsageMeasurementWithCurrentUsage = {
  __typename?: "UsageMeasurementWithCurrentUsage";
  createdAt: Scalars["DateTime"];
  currentUsage?: Maybe<Scalars["Float"]>;
  customerId: Scalars["String"];
  environmentId: Scalars["String"];
  featureId: Scalars["String"];
  id: Scalars["String"];
  nextResetDate?: Maybe<Scalars["DateTime"]>;
  timestamp: Scalars["DateTime"];
  value: Scalars["Float"];
};

export type UsageUpdated = {
  __typename?: "UsageUpdated";
  entitlement: Entitlement;
  usage: UsageMeasurementUpdated;
};

export type User = {
  __typename?: "User";
  department?: Maybe<Department>;
  email?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  lastSeenAt?: Maybe<Scalars["DateTime"]>;
  memberships: Array<Member>;
  name?: Maybe<Scalars["String"]>;
  profileImageUrl?: Maybe<Scalars["String"]>;
};

export enum VendorIdentifier {
  Hubspot = "HUBSPOT",
  Stripe = "STRIPE",
  Zuora = "ZUORA",
}

export type VendorIdentifierFilterComparison = {
  eq?: InputMaybe<VendorIdentifier>;
  gt?: InputMaybe<VendorIdentifier>;
  gte?: InputMaybe<VendorIdentifier>;
  iLike?: InputMaybe<VendorIdentifier>;
  in?: InputMaybe<Array<VendorIdentifier>>;
  is?: InputMaybe<Scalars["Boolean"]>;
  isNot?: InputMaybe<Scalars["Boolean"]>;
  like?: InputMaybe<VendorIdentifier>;
  lt?: InputMaybe<VendorIdentifier>;
  lte?: InputMaybe<VendorIdentifier>;
  neq?: InputMaybe<VendorIdentifier>;
  notILike?: InputMaybe<VendorIdentifier>;
  notIn?: InputMaybe<Array<VendorIdentifier>>;
  notLike?: InputMaybe<VendorIdentifier>;
};

/** Weekly reset period according to configuration */
export enum WeeklyAccordingTo {
  EveryFriday = "EveryFriday",
  EveryMonday = "EveryMonday",
  EverySaturday = "EverySaturday",
  EverySunday = "EverySunday",
  EveryThursday = "EveryThursday",
  EveryTuesday = "EveryTuesday",
  EveryWednesday = "EveryWednesday",
  SubscriptionStart = "SubscriptionStart",
}

export type WeeklyResetPeriodConfig = {
  __typename?: "WeeklyResetPeriodConfig";
  weeklyAccordingTo?: Maybe<WeeklyAccordingTo>;
};

export type WeeklyResetPeriodConfigInput = {
  accordingTo: WeeklyAccordingTo;
};

export type WidgetConfiguration = {
  __typename?: "WidgetConfiguration";
  customerPortal?: Maybe<CustomerPortalConfiguration>;
  paywall?: Maybe<PaywallConfiguration>;
};

export type WidgetConfigurationUpdateInput = {
  customerPortalConfiguration?: InputMaybe<CustomerPortalConfigurationInput>;
  environmentId?: InputMaybe<Scalars["String"]>;
  paywallConfiguration?: InputMaybe<PaywallConfigurationInput>;
};

/** The widget type */
export enum WidgetType {
  CustomerPortal = "CUSTOMER_PORTAL",
  Paywall = "PAYWALL",
}

export type ZuoraCredentials = {
  __typename?: "ZuoraCredentials";
  baseUrl: Scalars["String"];
  clientId: Scalars["String"];
  clientSecret: Scalars["String"];
};

export type ZuoraCredentialsInput = {
  baseUrl: Scalars["String"];
  clientId: Scalars["String"];
  clientSecret: Scalars["String"];
};

/** The group of the experiment */
export enum ExperimentGroupType {
  Control = "CONTROL",
  Variant = "VARIANT",
}

export type ExperimentInfo = {
  __typename?: "experimentInfo";
  groupName: Scalars["String"];
  groupType: ExperimentGroupType;
  id: Scalars["String"];
  name: Scalars["String"];
  status: ExperimentStatus;
};

export type CustomerResourcesQueryVariables = Exact<{
  paging?: InputMaybe<CursorPaging>;
  filter?: InputMaybe<CustomerResourceFilter>;
}>;

export type CustomerResourcesQuery = {
  __typename?: "Query";
  customerResources: {
    __typename?: "CustomerResourceConnection";
    edges: Array<{
      __typename?: "CustomerResourceEdge";
      node: { __typename?: "CustomerResource" } & CustomerResourceFragment;
    }>;
  };
};

export type CustomerResourceFragment = {
  __typename?: "CustomerResource";
  resourceId: string;
  subscriptions: Array<{
    __typename?: "CustomerSubscription";
    subscriptionId: string;
    plan: {
      __typename?: "Plan";
      displayName: string;
      product: { __typename?: "Product"; displayName?: string | null };
    };
  }>;
};
