import React, { useState } from "react";
import { MoreVertical } from "react-feather";
import {
  IconButton,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";

type OptionsDropdownProps = {
  options: {
    icon: React.FC<any>;
    text: string;
    onClick: () => void;
  }[];
  icon?: React.FC<any>;
};

export function OptionsDropdown({ options, icon: Icon }: OptionsDropdownProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid onClick={(e) => e.stopPropagation()}>
      <IconButton
        aria-owns={anchorEl ? "icon-menu" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {Icon ? <Icon /> : <MoreVertical color="#000000" size={20} />}
      </IconButton>
      <Menu
        PaperProps={{
          sx: {
            boxShadow:
              "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
            width: 180,
          },
        }}
        id="icon-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClick={handleClose}
        onClose={handleClose}
      >
        {options.map(({ onClick, text, icon: Icon }, index) => (
          <MenuItem
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
              onClick();
            }}
          >
            <ListItemIcon>{<Icon />}</ListItemIcon>
            <ListItemText primary={text} />
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );
}
