import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Site } from "./sitesLoader";
import { LoadingButton } from "@mui/lab";

type AddSiteDialogProps = {
  isOpen: boolean;
  closeDialog: () => void;
  onAddSite: (id: string) => Promise<void>;
  existingSites: Site[];
};

export function CreateSiteDialog({
  isOpen,
  onAddSite,
  existingSites,
  closeDialog,
}: AddSiteDialogProps) {
  const [id, setId] = useState("");
  const [siteAlreadyExists, setSiteAlreadyExists] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    await onAddSite(id);
    setId("");
    closeDialog();
    setIsLoading(false);
  };

  useEffect(() => {
    setSiteAlreadyExists(existingSites.some((site) => site.id === id));
  }, [id, existingSites]);

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          padding: 8,
          minWidth: 460,
          backgroundColor: "#F4F4F4",
        },
      }}
    >
      <DialogTitle
        sx={{
          pt: 0,
          fontSize: 24,
          display: "flex",
          justifyContent: "center",
        }}
      >
        Create new site
      </DialogTitle>
      <Grid
        container
        flexDirection="column"
        display="flex"
        alignContent="space-around"
      >
        <TextField
          value={id}
          placeholder="Enter site id..."
          error={siteAlreadyExists}
          data-testid="input-site-id"
          helperText={
            siteAlreadyExists ? "Site already exists. Choose different id" : " "
          }
          onChange={(event) => setId(event.target.value)}
          sx={{ width: 250 }}
        />
      </Grid>
      <DialogActions>
        <Grid container display="flex" justifyContent="center">
          <Grid item mr={2}>
            <Button
              onClick={() => closeDialog()}
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
          </Grid>
          <LoadingButton
            variant="contained"
            onClick={onSubmit}
            disabled={id === "" || siteAlreadyExists}
            loading={isLoading}
            data-testid="button-confirm-create-site"
          >
            Create a new site
          </LoadingButton>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
