import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useState } from "react";

export type ConfirmationDialogProps = {
  title: string;
  subTitle: string;
  confirmText: string;
  cancelText: string;
  callback: () => void | Promise<void>;
};

export function useConfirmationDialog() {
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationDialogOpened, setIsConfirmationDialogOpened] =
    useState(false);
  const [dialogProps, setDialogProps] =
    useState<ConfirmationDialogProps | null>(null);

  const dialog = (
    <Dialog
      open={isConfirmationDialogOpened}
      PaperProps={{
        sx: {
          padding: 8,
          minWidth: 460,
          backgroundColor: "#F4F4F4",
        },
      }}
    >
      <DialogTitle
        sx={{
          pt: 0,
          fontSize: 24,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {dialogProps?.title}
      </DialogTitle>
      <Grid
        container
        flexDirection="column"
        display="flex"
        alignContent="space-around"
      >
        <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
          {dialogProps?.subTitle}
        </Typography>
      </Grid>
      <DialogActions>
        <Grid container display="flex" justifyContent="center" mt={8}>
          <Grid item mr={2}>
            <Button
              onClick={() => setIsConfirmationDialogOpened(false)}
              variant="outlined"
              disabled={isLoading}
              color="primary"
              style={{ textTransform: "none" }}
              data-testid="button-dialog-cancel"
            >
              <Typography variant="body1">{dialogProps?.cancelText}</Typography>
            </Button>
          </Grid>
          <LoadingButton
            sx={{ cursor: "pointer" }}
            loading={isLoading}
            onClick={() => {
              if (dialogProps?.callback) {
                setIsLoading(true);
                Promise.resolve(dialogProps?.callback()).finally(() => {
                  setIsConfirmationDialogOpened(false);
                  setIsLoading(false);
                });
              }
            }}
            variant="contained"
            color="error"
            style={{ textTransform: "none" }}
            data-testid="button-dialog-confirm"
          >
            <Typography variant="body1">{dialogProps?.confirmText}</Typography>
          </LoadingButton>
        </Grid>
      </DialogActions>
    </Dialog>
  );

  return {
    dialog,
    isConfirmationDialogOpened,
    setDialogProps,
    setIsConfirmationDialogOpened,
  };
}
