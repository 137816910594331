import * as React from "react";
import styled, { withTheme } from "styled-components/macro";
import { ReactComponent as RevveLogo } from "../../vendor/revve-logo.svg";
import { ReactComponent as UpgradeIcon } from "../../vendor/RequiredUpgrade.svg";
import isEmpty from "lodash/isEmpty";

import {
  Grid,
  Hidden,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  Home as DashboardIcon,
  Dashboard as StoresIcon,
  Email as OrdersIcon,
  TrendingUp as AnalyticsIcon,
  Menu as MenuIcon,
  SvgIconComponent,
  TableChart,
} from "@mui/icons-material";
import NavbarUserDropdown from "./NavbarUserDropdown";
import { NavLink, useNavigate } from "react-router-dom";
import getStiggClient from "../../stigg";
import { useEffect, useState } from "react";

const StyledUpgradeIcon = styled(UpgradeIcon)`
  margin-right: ${(props) => props.theme.spacing(5)};
`;

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const BrandIcon = styled(RevveLogo)`
  margin-right: ${(props) => props.theme.spacing(16)};
  vertical-align: middle;
  display: inline;
`;

type NavbarProps = {
  theme: {};
  userId: string;
};

type NavBarPageProp = {
  title: string;
  to: string;
  Icon: SvgIconComponent;
  featureId?: string;
  productId?: string;
};

type PageEntitlement = {
  featureId: string;
  isGranted: boolean;
};

const NAVBAR_PAGES: NavBarPageProp[] = [
  {
    title: "DASHBOARD",
    to: "/",
    Icon: DashboardIcon,
  },
  {
    title: "TEMPLATES",
    to: "/templates",
    Icon: StoresIcon,
  },
  {
    title: "CAMPAIGNS",
    to: "/campaigns",
    Icon: OrdersIcon,
  },
  {
    title: "ANALYTICS",
    to: "/analytics",
    Icon: AnalyticsIcon,
    featureId: "feature-04-analytics",
  },
  {
    title: "SITES",
    to: "/sites",
    Icon: TableChart,
    productId: "product-site",
  },
];

const Navbar: React.FC<NavbarProps> = ({ userId }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [availableProducts, setAvailableProducts] = useState<string[]>([]);
  const [entitlements, setEntitlements] = useState<PageEntitlement[]>([]);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  useEffect(() => {
    const loadEntitlement = async () => {
      setIsLoading(true);
      const stigg = getStiggClient();

      const entitlements: PageEntitlement[] = await Promise.all(
        NAVBAR_PAGES.filter((page) => !!page.featureId).map(async (page) => {
          const entitlement = stigg.getBooleanEntitlement({
            featureId: page.featureId!!,
            options: {
              fallback: {
                hasAccess: true,
              },
            },
          });
          return {
            featureId: page.featureId!!,
            isGranted: entitlement.hasAccess,
          };
        })
      );

      const availableProducts: string[] = await Promise.all(
        NAVBAR_PAGES.filter((page) => !!page.productId).map(
          async ({ productId }) => {
            const paywall = await stigg.getPaywall({ productId });
            return paywall.plans.length > 0 ? productId! : "";
          }
        )
      );

      setEntitlements(entitlements);
      setAvailableProducts(availableProducts);
      setIsLoading(false);
    };

    void loadEntitlement();
  }, []);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  function getOptionalUpgradeIcon(featureId: string) {
    const entitlement = entitlements.filter((en) => en.featureId === featureId);

    if (isEmpty(entitlement)) {
      console.log(`Couldn't find entitlement for feature: ${featureId}`);
      return StyledUpgradeIcon;
    }

    if (!entitlement[0].isGranted) {
      return StyledUpgradeIcon;
    }

    return null;
  }

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={handleOpenNavMenu}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item>
              <BrandIcon
                onClick={() => navigate("/landing-page")}
                style={{ cursor: "pointer" }}
              />
            </Grid>

            <Hidden mdDown>
              <Grid item container xs flex={1}>
                {!isLoading &&
                  NAVBAR_PAGES.filter(
                    ({ productId }) =>
                      !productId || availableProducts.includes(productId)
                  ).map(({ title, to, Icon, featureId }) => {
                    const UpgradeIcon = featureId
                      ? getOptionalUpgradeIcon(featureId)
                      : null;

                    return (
                      <Button
                        data-testid={`navigation-tab-${title}`}
                        key={title}
                        component={NavLink}
                        variant="text"
                        to={to}
                        sx={{
                          color: "#fff",
                          width: "164px",
                          height: "64px",
                          "&.active": {
                            background: "rgba(255, 255, 255, 0.1)",
                          },
                        }}
                      >
                        {UpgradeIcon ? (
                          <UpgradeIcon
                            data-testid={`upgrade-icon-tab-${title}`}
                          />
                        ) : (
                          <Icon sx={{ opacity: 0.5, mr: 5 }} />
                        )}
                        {title}
                      </Button>
                    );
                  })}
              </Grid>
            </Hidden>

            <Hidden mdUp>
              <Grid item>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                >
                  {NAVBAR_PAGES.map(({ title, to }) => (
                    <MenuItem
                      key={title}
                      onClick={() => {
                        handleCloseNavMenu();
                        navigate(to);
                      }}
                    >
                      <Typography textAlign="center">{title}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Grid>

              <Grid item xs />
            </Hidden>

            <Grid item>
              <NavbarUserDropdown userId={userId} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
