import * as React from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";

import {
  Divider,
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
  Avatar,
} from "@mui/material";

import useAuth from "../../hooks/useAuth";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown({ userId }: { userId: string }) {
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    await signOut();
    navigate("/sign-in");
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          onClick={toggleMenu}
          sx={{ p: 0 }}
          data-testid="user-avatar"
        >
          <Avatar alt="Random dude" src="/static/img/avatars/avatar.jpg" />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem disabled>
          User ID: &nbsp; <strong>{userId}</strong>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            closeMenu();
            navigate("/customer-portal");
          }}
          data-testid="navigation-customer-portal"
        >
          Account settings
        </MenuItem>
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
