import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { Outlet } from "react-router-dom";
import { customAlphabet } from "nanoid";
import { CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";

import axios from "../utils/axios";
import { useLocalStorage, LOCAL_STORAGE_USER_ID_KEY } from "../useLocalStorage";
import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import UpgradeBanner from "../components/UpgradeBanner";
import { getConfiguration } from "../utils/getConfiguration";

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const nanoid = customAlphabet("1234567890abcdef", 10);

const Shell: React.FC = ({ children }) => {
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  const defaultUserId = nanoid();
  const [userId] = useLocalStorage(LOCAL_STORAGE_USER_ID_KEY, defaultUserId);

  useEffect(() => {
    const reportEvent = async () => {
      const { customerId } = getConfiguration();
      if (userId && customerId) {
        await axios.post("/api/reportEvent", {
          eventName: "user_login",
          customerId: customerId,
          dimensions: {
            user_id: userId,
          },
        });
      }
    };

    reportEvent();
  }, [userId]);

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <AppContent>
        <UpgradeBanner />
        <Navbar userId={userId} />
        <MainContent p={isLgUp ? 12 : 5}>
          {children}
          <Outlet />
        </MainContent>
      </AppContent>
    </Root>
  );
};

export default Shell;
