import { customerId } from "../data";

export const CUSTOMER_ID = customerId;

const defaults = {
  customerId: CUSTOMER_ID,
};

export function getConfiguration() {
  const search = window.location.search;

  const serverSdkKeyLocalStorage = window.localStorage.getItem("serverSdkKey");
  const clientSdkKeyLocalStorage = window.localStorage.getItem("clientSdkKey");
  const signingTokenLocalStorage = window.localStorage.getItem("signingToken");
  const customerIdLocalStorage = window.localStorage.getItem("customerId");
  const environmentNameLocalStorage =
    window.localStorage.getItem("environmentName");

  const urlParams = new URLSearchParams(search);
  const serverSdkKeyParams = urlParams.get("serverSdkKey");
  const clientSdkKeyParams = urlParams.get("clientSdkKey");
  const signingTokenParams = urlParams.get("signingToken");
  const customerIdParams = urlParams.get("customerId");
  const environmentNameParams = urlParams.get("environmentName");

  const resolvedConfiguration = {
    serverSdkKey: serverSdkKeyParams || serverSdkKeyLocalStorage,
    clientSdkKey: clientSdkKeyParams || clientSdkKeyLocalStorage,
    signingToken: signingTokenParams || signingTokenLocalStorage,
    customerId:
      customerIdParams || customerIdLocalStorage || defaults.customerId,
    environmentName: environmentNameParams || environmentNameLocalStorage,
  };

  if (resolvedConfiguration.serverSdkKey) {
    window.localStorage.setItem(
      "serverSdkKey",
      resolvedConfiguration.serverSdkKey
    );
  }
  if (resolvedConfiguration.clientSdkKey) {
    window.localStorage.setItem(
      "clientSdkKey",
      resolvedConfiguration.clientSdkKey
    );
  }
  if (resolvedConfiguration.signingToken) {
    window.localStorage.setItem(
      "signingToken",
      resolvedConfiguration.signingToken
    );
  }
  if (resolvedConfiguration.customerId) {
    window.localStorage.setItem("customerId", resolvedConfiguration.customerId);
  }
  if (resolvedConfiguration.environmentName) {
    window.localStorage.setItem(
      "environmentName",
      resolvedConfiguration.environmentName
    );
  }

  return resolvedConfiguration;
}
