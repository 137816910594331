import { green, grey } from "@mui/material/colors";
import { THEMES } from "../constants";

let defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: "light",
    primary: {
      main: green[800],
      contrastText: "#FFF",
    },
    secondary: {
      main: green[500],
      contrastText: "#FFF",
    },
    background: {
      default: "#F7F9FC",
      paper: "#FFF",
    },
  },
  header: {
    color: "#FFF",
    background: "#427F3D",
    search: {
      color: grey[800],
    },
    indicator: {
      background: green[600],
    },
  },
  footer: {
    color: grey[500],
    background: "#FFF",
  },
};

const variants: Array<VariantType> = [defaultVariant];

export default variants;

export type VariantType = {
  name: string;
  palette: {
    primary: MainContrastTextType;
    secondary: MainContrastTextType;
  };
  header: ColorBgType & {
    search: {
      color: string;
    };
    indicator: {
      background: string;
    };
  };
  footer: ColorBgType;
  // sidebar: ColorBgType & {
  //   header: ColorBgType & {
  //     brand: {
  //       color: string;
  //     };
  //   };
  //   footer: ColorBgType & {
  //     online: {
  //       background: string;
  //     };
  //   };
  //   badge: ColorBgType;
  // };
};

type MainContrastTextType = {
  main: string;
  contrastText: string;
};
type ColorBgType = {
  color: string;
  background: string;
};
