import React from "react";
import styled from "styled-components/macro";
import {
  Button,
  Card as MuiCard,
  CardActionArea,
  CardActions,
  CardContent as MuiCardContent,
  Grid,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { Trash2 } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
  min-height: 215px;
`;

interface TypographyProps extends SpacingProps {
  component?: string;
}

const Typography = styled(MuiTypography)<TypographyProps>(spacing);

type SiteProps = {
  siteId: string;
  planName: string;
  removeSite: () => void;
};

const SitePreview: React.FC<SiteProps> = ({ siteId, planName, removeSite }) => {
  const navigate = useNavigate();
  const { dialog, setDialogProps, setIsConfirmationDialogOpened } =
    useConfirmationDialog();

  const onDeleteSite = () => {
    setDialogProps({
      title: `Delete ${siteId}`,
      subTitle: `Are you sure that you want to delete this site?`,
      confirmText: "Yes",
      cancelText: "No",
      callback: () => removeSite(),
    });

    setIsConfirmationDialogOpened(true);
  };

  return (
    <>
      <Card sx={{ width: "300px" }}>
        <CardActionArea
          onClick={() => navigate(`/sites/${siteId}`)}
          data-testid={`navigation-site-${siteId}`}
        >
          <CardContent>
            <Grid container wrap="nowrap" justifyContent="space-between">
              <Grid item container flexDirection="column">
                <Grid item>
                  <Typography gutterBottom variant="h5" component="h2">
                    {siteId}
                  </Typography>
                </Grid>
                <Grid item mt={4}>
                  <img
                    src={`https://picsum.photos/seed/${siteId}/200`}
                    alt=""
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Grid item>
            <Button
              size="small"
              color="error"
              onClick={onDeleteSite}
              data-testid={`button-delete-site-${siteId}`}
            >
              <Trash2 size={16} /> &nbsp; Delete
            </Button>
          </Grid>
        </CardActions>
      </Card>

      {dialog}
    </>
  );
};

export default SitePreview;
