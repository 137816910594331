import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Shell from "../layouts/Shell";
import Dashboard from "./dashboard/Dashboard";
import AuthLayout from "../layouts/Auth";
import SignIn from "./auth/SignIn";
import AuthGuard from "../components/guards/AuthGuard";
import GuestGuard from "../components/guards/GuestGuard";
import { Grid, Typography } from "@mui/material";
import Stores from "./stores/Stores";
import Orders from "./orders/Orders";
import Analytics from "./analytics/Analytics";
import { Frown } from "react-feather";
import { CustomerPortalPage } from "./customerPortal/CustomerPortalPage";
import { LandingPage } from "./landing-page";
import { StiggContext } from "../StiggContext";
import Sites from "./sites/Sites";
import SingleSite from "./sites/site/SingleSite";

const AppRouter: React.FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/landing-page" element={<LandingPage />} />
      <Route
        path="/sign-in"
        element={
          <GuestGuard>
            <AuthLayout>
              <SignIn />
            </AuthLayout>
          </GuestGuard>
        }
      />
      <Route
        path="/"
        element={
          <AuthGuard>
            <Shell />
          </AuthGuard>
        }
        children={
          <>
            <Route index element={<Dashboard />} />
            <Route path="templates" element={<Stores />} />
            <Route path="sites" element={<Sites />} />
            <Route path="sites/:siteId" element={<SingleSite />} />
            <Route
              path="campaigns"
              element={
                <Typography variant="h3">
                  <Orders />
                </Typography>
              }
            />
            <Route path="analytics" element={<Analytics />} />
            <Route
              path="users"
              element={<Typography variant="h3">Users</Typography>}
            />
            <Route
              path="customer-portal"
              element={
                <StiggContext withCustomer>
                  <CustomerPortalPage />
                </StiggContext>
              }
            />
          </>
        }
      />
      <Route
        path="/invalid-configuration"
        element={
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            height="100vh"
          >
            <Grid item container flexDirection="column" alignItems="center">
              <Grid item mb={3}>
                <Frown size={100} />
              </Grid>
              <Grid item mb={5}>
                <Typography variant="h1">Invalid configuration</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  Please use Stigg CLI to generate the demo URL
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </BrowserRouter>
);

export default AppRouter;
