import Stigg, { StiggClient } from "@stigg/js-client-sdk";
import { getConfiguration } from "./utils/getConfiguration";
import axios from "./utils/axios";

let stiggClient: StiggClient;

export const initializeStiggClient = async (): Promise<StiggClient> => {
  const { serverSdkKey, clientSdkKey, customerId, signingToken } =
    getConfiguration();

  if (clientSdkKey && serverSdkKey && signingToken) {
    const [baseUrl, customerToken] = await Promise.all([
      axios.get("/api/get-base-url").then((response) => response.data.baseUrl),
      axios.get("/api/user").then((response) => response.data.customerToken),
    ]);
    if (!stiggClient) {
      const client = Stigg.initialize({
        apiKey: clientSdkKey,
        baseUri: baseUrl,
        customerId,
        customerToken,
      });
      await client.waitForInitialization();
      stiggClient = client;
    }
    return stiggClient;
  }

  throw new Error("Missing clientSdkKey / serverSdkKey / signingToken");
};

export default function getStiggClient() {
  if (!stiggClient) {
    throw new Error("Stigg SDK is not initialized");
  }
  return stiggClient;
}
