import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useAuth from "./useAuth";

const COUNTRY_CODE_QUERY_PARAM = "countryCode";

export const useCountryCode = () => {
  const [countryCode, setCountryCode] = useState<string | undefined>();
  const { clientGeoData, isLoadingClientGeoData } = useAuth();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!countryCode && !isLoadingClientGeoData) {
      let selectedCountryCode;
      const countryCodeFromQuery = searchParams.get(COUNTRY_CODE_QUERY_PARAM);
      if (countryCodeFromQuery) {
        selectedCountryCode = countryCodeFromQuery;
      } else {
        selectedCountryCode = clientGeoData?.countryCode;
      }
      setCountryCode(selectedCountryCode || undefined);
      console.log(`Country code is set to: ${selectedCountryCode}`, {
        countryCode: selectedCountryCode,
      });
    }
  }, [
    clientGeoData?.countryCode,
    countryCode,
    isLoadingClientGeoData,
    searchParams,
  ]);

  return {
    countryCode,
    isLoadingCountryCode: isLoadingClientGeoData,
  };
};
