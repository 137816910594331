import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton as MuiIconButton,
  Link,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Box } from "@mui/system";
import getStiggClient from "../../stigg";
import {
  Customer,
  Plan,
  BillingPeriod,
  Subscription,
  BillableFeature,
} from "@stigg/js-client-sdk";
import { useNavigate } from "react-router-dom";
import axios from "../../utils/axios";
import { StiggContext } from "../../StiggContext";
import { InlinePaywall } from "./InlinePaywall";
import { useCountryCode } from "../../hooks/useCountryCode";
import { CheckoutDialog } from "./InlineCheckout";

export type PaywallProps = {
  paywallIsOpen: boolean;
  setPaywallIsOpen: (isOpen: boolean) => any;
  redirectAfterExit: boolean;
  onUpgradeSuccess?: () => any;
  paywallTitle: string;
  paywallSubtitle?: string;
  showAllPlans?: boolean;
  showCancelPlan?: boolean;
  showRecommendedPlan?: boolean;
  siteId?: string;
};

export const STEPS = {
  paywall: "paywall",
  checkout: "checkout",
  postCheckout: "checkoutDone",
};

function Paywall({
  paywallIsOpen,
  setPaywallIsOpen,
  onUpgradeSuccess,
  redirectAfterExit,
  paywallTitle,
  paywallSubtitle,
  showAllPlans,
  showCancelPlan,
  showRecommendedPlan = true,
  siteId,
}: PaywallProps) {
  const [currentStep, setCurrentStep] = useState(STEPS.paywall);
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [recommendedPlanId, setRecommendedPlanId] = useState<string | null>(
    null
  );
  const [trialSubscription, setTrialSubscription] = useState(false);
  const [processingUpgrade, setProcessingUpgrade] = useState(false);
  const [customer, setCustomer] = useState<Customer | undefined>();
  const [cancelSubOpen, setCancelSubOpen] = useState(false);
  const [currentBillingPeriod, setCurrentBillingPeriod] = useState(
    BillingPeriod.Annually
  );
  const [isAwaitingCheckout, setIsAwaitingCheckout] = useState(false);
  const { countryCode } = useCountryCode();
  const [billableFeatures, setBillableFeatures] = useState<
    BillableFeature[] | undefined
  >();

  const productId = siteId ? "product-site" : "product-revvenu";

  let currentSubscription: Subscription | undefined =
    customer?.getActiveTrials()[0];
  let currentPlan = currentSubscription?.plan;
  if (!currentPlan) {
    currentSubscription = customer?.getActiveSubscriptions()[0];
    currentPlan = currentSubscription?.plan;
  }

  const navigate = useNavigate();

  async function waitForCheckoutToComplete() {
    const stigg = getStiggClient();
    setIsAwaitingCheckout(true);
    try {
      await stigg.waitForCheckoutCompleted();
    } catch (err) {
      console.error("Failed to wait for checkout to complete", err);
      // show an error prompt
    } finally {
      setIsAwaitingCheckout(false);
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const checkoutSuccess = urlParams.get("checkoutSuccess");
    if (checkoutSuccess) {
      setCurrentStep(STEPS.postCheckout);
      void waitForCheckoutToComplete();
    } else if (paywallIsOpen) {
      setCurrentStep(STEPS.paywall);
    }

    const getPlans = async () => {
      const stigg = getStiggClient();
      const [paywall, customer] = await Promise.all([
        stigg.getPaywall({
          productId,
          billingCountryCode: countryCode,
        }),
        stigg.getCustomer(),
      ]);

      const plans = paywall.plans;
      setCustomer(customer);
      const planId = urlParams.get("planId");
      if (planId) {
        setSelectedPlan(plans.find((plan) => plan.id === planId)!);
      }
      const recommendedPlan = plans[0].product.metadata?.recommendedPlan;
      if (recommendedPlan) {
        setRecommendedPlanId(recommendedPlan);
      }
    };

    try {
      getPlans();
    } catch (err: any) {
      console.log(err);
    }
  }, [countryCode, paywallIsOpen, productId]);

  const onClose = (redirect = false) => {
    setPaywallIsOpen(false);

    if (redirectAfterExit && redirect) {
      navigate("/");
    }
  };

  const postCheckout = () => {
    onClose();
    if (onUpgradeSuccess) {
      onUpgradeSuccess();
    }
  };

  const onCancelSubscription = () => {
    setCancelSubOpen(true);
    setPaywallIsOpen(false);
  };
  const cancelSubscription = async () => {
    const subscriptionIdToCancel = currentSubscription?.id;
    await axios.post("/api/cancel", {
      subscriptionIdToCancel,
    });
    setCancelSubOpen(false);
    if (onUpgradeSuccess) {
      onUpgradeSuccess();
    }
  };

  return (
    <>
      <CheckoutDialog
        isOpen={paywallIsOpen && currentStep === STEPS.checkout}
        onClose={() => {
          setCurrentStep(STEPS.paywall);
        }}
        planId={selectedPlan?.id}
        billableFeatures={billableFeatures}
        preferredBillingPeriod={currentBillingPeriod}
        onSuccessProvision={() => {
          setCurrentStep(STEPS.postCheckout);
        }}
        onChangePlan={() => {
          setCurrentStep(STEPS.paywall);
        }}
        siteId={siteId}
      />
      <Dialog
        open={paywallIsOpen && currentStep !== STEPS.checkout}
        fullWidth={true}
        PaperProps={{
          sx: {
            minHeight: 740,
            minWidth: 1100,
            backgroundColor: "#F4F4F4",
          },
        }}
      >
        <DialogTitle
          sx={{
            color: "#001E6c",
            display: "flex",
            justifyContent: "end",
            position: "absolute",
            right: 0,
          }}
        >
          {currentStep !== STEPS.postCheckout && (
            <MuiIconButton onClick={() => onClose(true)}>
              <CloseIcon />
            </MuiIconButton>
          )}
        </DialogTitle>
        {currentStep === STEPS.paywall ? (
          <Box mt={1} paddingX={4} paddingY={2}>
            <Typography
              variant="h2"
              fontWeight="regular"
              mb={2}
              align="center"
              sx={{ color: "rgba(0,0,0,0.75)" }}
            >
              {paywallTitle}
            </Typography>

            <Typography
              variant="h6"
              fontWeight="regular"
              mb={4}
              align="center"
              sx={{ color: "rgba(0,0,0,0.75)" }}
            >
              {paywallSubtitle}
            </Typography>

            <StiggContext>
              <InlinePaywall
                recommendedPlanId={recommendedPlanId}
                setCurrentBillingPeriod={setCurrentBillingPeriod}
                setCurrentStep={setCurrentStep}
                setProcessingUpgrade={setProcessingUpgrade}
                setSelectedPlan={setSelectedPlan}
                setTrialSubscription={setTrialSubscription}
                showAllPlans={showAllPlans}
                showRecommendedPlan={showRecommendedPlan}
                siteId={siteId}
                setBillableFeatures={setBillableFeatures}
              />
            </StiggContext>

            {showCancelPlan && (
              <Grid mt={2} ml={14} display="flex">
                <Typography>I'd like to </Typography>
                <Link
                  sx={{ cursor: "pointer" }}
                  ml={1}
                  onClick={() => {
                    void onCancelSubscription();
                  }}
                >
                  cancel my plan
                </Link>
              </Grid>
            )}
          </Box>
        ) : isAwaitingCheckout ? (
          <Box
            display="flex"
            height={"100%"}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h4" pb={8}>
              Provisioning your subscription...
            </Typography>
            <CircularProgress />
          </Box>
        ) : (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            display="flex"
            sx={{ py: "86px", height: "100%" }}
          >
            <Confetti
              width={1000}
              height={615}
              numberOfPieces={500}
              recycle={false}
            />
            <Typography
              variant="h1"
              align="center"
              pb={8}
              data-testid="davai-zikukim"
            >
              🎉
            </Typography>
            <Typography variant="h4" align="center" pb={8}>
              {trialSubscription
                ? `Welcome to the trial of ${selectedPlan?.displayName} plan`
                : `You're now on the ${selectedPlan?.displayName} plan!`}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={postCheckout}
              data-testid="button-modal-upgrade-done-continue"
            >
              Continue
            </Button>
          </Grid>
        )}
      </Dialog>
      <Dialog
        open={cancelSubOpen}
        PaperProps={{
          sx: {
            padding: 8,
            minWidth: 460,
            backgroundColor: "#F4F4F4",
          },
        }}
      >
        <DialogTitle
          sx={{
            pt: 0,
            fontSize: 24,
            display: "flex",
            justifyContent: "center",
          }}
        >
          Cancel plan
        </DialogTitle>
        <Grid
          container
          flexDirection="column"
          display="flex"
          alignContent="space-around"
        >
          <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
            Are you sure that you want to cancel your current plan?
          </Typography>
        </Grid>
        <DialogActions>
          <Grid container display="flex" justifyContent="center" mt={8}>
            <Grid item mr={2}>
              <Button
                onClick={() => setCancelSubOpen(false)}
                variant="outlined"
                color="primary"
              >
                Dismiss
              </Button>
            </Grid>
            <Button
              sx={{ cursor: "pointer" }}
              onClick={cancelSubscription}
              variant="contained"
              color="error"
            >
              Cancel my plan
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Paywall;
