import * as React from "react";
import { Navigate, useNavigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import Loader from "../Loader";

interface AuthGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
  const navigate = useNavigate();
  const { isAuthenticated, isInitialized, invalidConfiguration } = useAuth();

  React.useEffect(() => {
    if (invalidConfiguration) {
      navigate("/invalid-configuration");
    }
  }, [navigate, invalidConfiguration]);

  if (!isInitialized) {
    return <Loader />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/sign-in" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
