import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Divider as MuiDivider,
  Grid,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import getStiggClient from "../../stigg";
import Paywall from "../../components/paywall/Paywall";
import { green, red } from "@mui/material/colors";

import Actions from "./Actions";
import BarChart from "./BarChart";
import DoughnutChart from "./DoughnutChart";
import LanguagesTable from "./LanguagesTable";
import Stats from "./Stats";
import Table from "./Table";
import WorldMap from "./WorldMap";

const Divider = styled(MuiDivider)(spacing);

interface TypographyProps extends SpacingProps {
  component?: string;
}

const Typography = styled(MuiTypography)<TypographyProps>(spacing);

function Analytics() {
  const [paywallIsOpen, setPaywallIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isGranted, setIsGranted] = useState(false);
  const stigg = getStiggClient();

  useEffect(() => {
    const getFeature = async () => {
      const feature = stigg.getBooleanEntitlement({
        featureId: "feature-04-analytics",
        options: {
          shouldTrack: true,
          fallback: {
            hasAccess: true,
          },
        },
      });
      setIsLoading(false);
      setIsGranted(feature.hasAccess);
      setPaywallIsOpen(!feature.hasAccess);

      // open paywall for checkout success message
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get("checkoutSuccess")) {
        setPaywallIsOpen(true);
      }
    };
    getFeature();
  }, [stigg]);

  return (
    <React.Fragment>
      <Paywall
        paywallIsOpen={paywallIsOpen}
        setPaywallIsOpen={setPaywallIsOpen}
        onUpgradeSuccess={() => {
          window.location.href = window.location.pathname;
        }}
        redirectAfterExit={true}
        paywallTitle="Upgrade your plan"
        paywallSubtitle="Learn what products your audience is interested in using data from analytics"
        showAllPlans
      />
      {!isLoading ? (
        <React.Fragment>
          {!isGranted && (
            <div
              style={{
                backdropFilter: "blur(10px)",
                width: "100%",
                position: "absolute",
                top: "0",
                bottom: "0",
                left: "0",
                right: "0",
                zIndex: "999",
              }}
            />
          )}
          <Helmet title="Analytics" />
          <Grid justifyContent="space-between" container spacing={6}>
            <Grid item>
              <Typography variant="h3" gutterBottom>
                Analytics
              </Typography>
            </Grid>

            <Grid item>
              <Actions />
            </Grid>
          </Grid>

          <Divider my={6} />

          <Grid container spacing={6}>
            <Grid item xs={12} lg={5}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={6}>
                  <Stats
                    title="Visitors"
                    amount="245"
                    chip="Today"
                    percentagetext="+14%"
                    percentagecolor={green[500]}
                    illustration="/static/img/working.png"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Stats
                    title="Activity"
                    amount="63"
                    chip="Annual"
                    percentagetext="-12%"
                    percentagecolor={red[500]}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Stats
                    title="Real-Time"
                    amount="15"
                    chip="Monthly"
                    percentagetext="-18%"
                    percentagecolor={red[500]}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Stats
                    title="Bounce"
                    amount="53"
                    chip="Yearly"
                    percentagetext="+27%"
                    percentagecolor={green[500]}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={7}>
              <BarChart />
            </Grid>
          </Grid>

          <Grid container spacing={6}>
            <Grid item xs={12} lg={8}>
              <WorldMap />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DoughnutChart />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={4}>
              <LanguagesTable />
            </Grid>
            <Grid item xs={12} lg={8}>
              <Table />
            </Grid>
          </Grid>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
}

export default Analytics;
