import styled from "styled-components/macro";
// @ts-ignore
import Root from "./root.css";
// @ts-ignore
import Boost from "./bootstrap.min.css";

export const Style = styled.div`
  ${Root}
  ${Boost}
  * {
    margin: 0;
    padding: 0;
  }
  body {
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
  }
  [tabindex="-1"]:focus {
    outline: 0 !important;
  }
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  abbr[data-original-title],
  abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
  }
  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }
  dl,
  ol,
  ul {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-bottom: 0;
  }
  dt {
    font-weight: 700;
  }
  dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
  }
  blockquote {
    margin: 0 0 1rem;
  }
  dfn {
    font-style: italic;
  }
  b,
  strong {
    font-weight: bolder;
  }
  small {
    font-size: 80%;
  }
  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }
  a:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
  }
  a:not([href]):not([tabindex]):focus,
  a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none;
  }
  a:not([href]):not([tabindex]):focus {
    outline: 0;
  }
  code,
  kbd,
  pre,
  samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
      "Courier New", monospace;
    font-size: 1em;
  }
  pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
  }
  figure {
    margin: 0 0 1rem;
  }
  img {
    vertical-align: middle;
    border-style: none;
  }
  svg {
    overflow: hidden;
    vertical-align: middle;
  }
  table {
    border-collapse: collapse;
  }
  caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom;
  }
  th {
    text-align: inherit;
  }
  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
  button {
    border-radius: 0;
  }
  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  button,
  input {
    overflow: visible;
  }
  button,
  select {
    text-transform: none;
  }
  [type="reset"],
  [type="submit"],
  button,
  html [type="button"] {
    -webkit-appearance: button;
  }
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner,
  button::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }
  input[type="checkbox"],
  input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }
  input[type="date"],
  input[type="datetime-local"],
  input[type="month"],
  input[type="time"] {
    -webkit-appearance: listbox;
  }
  textarea {
    overflow: auto;
    resize: vertical;
  }
  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }
  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
  }
  progress {
    vertical-align: baseline;
  }
  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }
  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }
  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }
  output {
    display: inline-block;
  }
  summary {
    display: list-item;
    cursor: pointer;
  }
  template {
    display: none;
  }
  [hidden] {
    display: none !important;
  }
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
  }
  .h1,
  h1 {
    font-size: 2.5rem;
  }
  .h2,
  h2 {
    font-size: 2rem;
  }
  .h3,
  h3 {
    font-size: 1.75rem;
  }
  .h4,
  h4 {
    font-size: 1.5rem;
  }
  .h5,
  h5 {
    font-size: 1.25rem;
  }
  .h6,
  h6 {
    font-size: 1rem;
  }

  code {
    font-size: 87.5%;
    color: #e83e8c;
    word-break: break-word;
  }
  a > code {
    color: inherit;
  }
  kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: 0.2rem;
  }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
  }
  pre {
    display: block;
    font-size: 87.5%;
    color: #212529;
  }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
  }
  /* --- TABLE OF CONTENT

01. GENERAL LAYOUT
02. MENU-NAV
03. FOOTER
04. HOME
05. TEAM
06. FEATURES
07. PRICING
08. BLOG
09. CONTACT
10. OTHER ELEMENTS
		a. Newsletter
		b. Buttons
		c. Gallery PopUp
		d. Testimonials
		e. Accordion Tabs
		f. Carousel
		g. Features List
		h) Dropcaps Features
		i) Partners
11. MEDIA QUERIES

--- */

  /* ========================================================== */
  /* 			               01. GENERAL LAYOUT                 */
  /* ========================================================== */

  * {
    font-family: "Lato", sans-serif;
  }

  body {
    scroll-behavior: smooth;
  }

  ul {
    padding: 0;
  }

  ul,
  ol {
    list-style: none;
    list-style-image: none;
    margin: 0;
    padding: 0;
    color: 67ac5b;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  ul li,
  ol li {
    font-size: 17px;
    line-height: 28px;
    list-style-type: none;
  }

  ul li i {
    vertical-align: middle;
    padding-right: 5px;
  }

  p {
    font-size: 17px;
    line-height: 28px;
    font-weight: 400;
    color: #7c8595;
    margin-bottom: 15px;
  }

  a,
  a:hover,
  a:focus,
  a:active,
  a:visited {
    text-decoration: none;
    outline: 0;
    color: #67ac5b;
  }

  a:hover {
    color: var(--dark-main-color);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 20px;
    color: var(--black);
    font-weight: 600;
  }

  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 26px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 18px;
  }

  h2.section-title {
    margin-bottom: 10px;
  }

  p.section-subtitle {
    font-size: 18px;
  }

  p.section-subtitle.white {
    color: #ffffff;
  }

  .iframe-youtube {
    position: relative;
    margin-top: 30px;
    z-index: 100;
  }

  .iframe-youtube iframe {
    padding: 10px 10px;
    box-shadow: 0 15px 36px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    webkit-border-radius: 8px 8px;
    -moz-border-radius: 8px 8px;
    border-radius: 8px 8px;
    z-index: 100;
  }

  .iframe-youtube:after {
    background-image: radial-gradient(#dee1e0 15%, transparent 25%);
    background-size: 20px 20px;
    content: "";
    position: absolute;
    width: 204px;
    height: 200px;
    right: 55px;
    top: -40px;
    z-index: -1;
  }

  .video-popup-wrapper {
    position: relative;
  }

  .video-popup-wrapper:before {
    background-image: radial-gradient(#dee1e0 15%, transparent 25%);
    background-size: 20px 20px;
    content: "";
    position: absolute;
    width: 204px;
    height: 200px;
    right: -64px;
    top: -40px;
    z-index: -1;
  }

  .video-popup-wrapper:after {
    background-image: radial-gradient(#dee1e0 15%, transparent 25%);
    background-size: 20px 20px;
    content: "";
    position: absolute;
    width: 204px;
    height: 200px;
    left: -45px;
    bottom: -60px;
    z-index: -1;
  }

  .video-popup-wrapper img.hero-images {
    padding: 10px 10px;
    box-shadow: 0 20px 42px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    webkit-border-radius: 8px 8px;
    -moz-border-radius: 8px 8px;
    border-radius: 8px 8px;
  }

  .video-popup-wrapper:hover img.hero-images {
    opacity: 0.99;
  }

  .popup-gallery:hover .video-popup-image {
    opacity: 1;
  }

  .white-text {
    color: #fff;
  }

  .blue {
    color: var(--dark-main-color);
  }

  .section-top-border {
    border-top: 1px solid #efefef;
  }

  .section-bottom-border {
    border-bottom: 1px solid #efefef;
  }

  .section-white {
    background: #fff;
    padding: 90px 0 100px 0;
  }

  .section-grey {
    background: #fafbfc;
    padding: 90px 0 100px 0;
  }

  .section-blue {
    background-color: var(--main-color);
    padding: 90px 0;
  }

  .section-white.no-padding,
  .section-grey.no-padding,
  .section-blue.no-padding {
    padding: 0;
  }

  .section-white.medium-paddings,
  .section-grey.medium-paddings,
  .section-blue.medium-paddings {
    padding: 60px 0;
  }

  .section-white.small-paddings,
  .section-grey.small-paddings,
  .section-blue.small-paddings {
    padding: 30px 0;
  }

  .section-white.xsmall-paddings,
  .section-grey.xsmall-paddings,
  .section-blue.xsmall-paddings {
    padding: 10px 0;
  }

  .section-white.small-padding-top,
  .section-grey.small-padding-top,
  .section-blue.small-padding-top {
    padding: 30px 0 90px 0;
  }

  .section-white.small-padding-bottom,
  .section-grey.small-padding-bottom,
  .section-blue.small-padding-bottom {
    padding: 90px 0 70px 0;
  }

  .section-white.no-padding-bottom,
  .section-grey.no-padding-bottom,
  .section-blue.no-padding-bottom {
    padding: 90px 0 0 0;
  }

  .small-col-inside {
    max-width: 575px;
    padding-left: 55px;
  }

  .width-100 {
    width: 100%;
  }

  .box-shadow {
    border-radius: 8px;
    box-shadow: 0px 8px 40px 0 rgba(0, 0, 0, 0.15);
  }

  /* margin and padding classes */

  .margin-right-15 {
    margin-right: 15px !important;
  }

  .margin-right-25 {
    margin-right: 25px !important;
  }

  .margin-left-20 {
    margin-left: 20px !important;
  }

  .margin-top-110 {
    margin-top: 110px !important;
  }

  .margin-top-100 {
    margin-top: 100px !important;
  }

  .margin-top-90 {
    margin-top: 90px !important;
  }

  .margin-top-80 {
    margin-top: 80px !important;
  }

  .margin-top-70 {
    margin-top: 70px !important;
  }

  .margin-top-60 {
    margin-top: 60px !important;
  }

  .margin-top-50 {
    margin-top: 50px !important;
  }

  .margin-top-40 {
    margin-top: 40px !important;
  }

  .margin-top-35 {
    margin-top: 35px !important;
  }

  .margin-top-30 {
    margin-top: 30px !important;
  }

  .margin-top-20 {
    margin-top: 20px !important;
  }

  .margin-top-10 {
    margin-top: 10px !important;
  }

  .margin-top-5 {
    margin-top: 5px !important;
  }

  .margin-bottom-0 {
    margin-bottom: 0px !important;
  }

  .margin-bottom-5 {
    margin-bottom: 5px;
  }

  .margin-bottom-10 {
    margin-bottom: 10px !important;
  }

  .margin-bottom-20 {
    margin-bottom: 20px !important;
  }

  .margin-bottom-30 {
    margin-bottom: 30px !important;
  }

  .margin-bottom-40 {
    margin-bottom: 40px !important;
  }

  .margin-bottom-50 {
    margin-bottom: 50px !important;
  }

  .margin-bottom-60 {
    margin-bottom: 60px !important;
  }

  .margin-bottom-70 {
    margin-bottom: 70px !important;
  }

  .margin-bottom-80 {
    margin-bottom: 80px !important;
  }

  .margin-bottom-100 {
    margin-bottom: 100px !important;
  }

  .margin-bottom-120 {
    margin-bottom: 120px !important;
  }

  .margin-bottom-130 {
    margin-bottom: 120px !important;
  }

  .margin-bottom-140 {
    margin-bottom: 140px !important;
  }

  .padding-top-0 {
    padding-top: 0px !important;
  }

  .padding-top-10 {
    padding-top: 10px !important;
  }

  .padding-top-20 {
    padding-top: 20px !important;
  }
  .padding-top-25 {
    padding-top: 25px !important;
  }

  .padding-top-30 {
    padding-top: 30px !important;
  }

  .padding-top-35 {
    padding-top: 35px !important;
  }

  .padding-top-40 {
    padding-top: 40px !important;
  }

  .padding-top-45 {
    padding-top: 45px !important;
  }

  .padding-top-50 {
    padding-top: 50px !important;
  }
  .padding-top-60 {
    padding-top: 60px !important;
  }

  .padding-top-70 {
    padding-top: 70px !important;
  }

  .padding-top-80 {
    padding-top: 80px !important;
  }

  .padding-top-100 {
    padding-top: 100px !important;
  }

  .padding-top-120 {
    padding-top: 120px !important;
  }

  .padding-top-150 {
    padding-top: 150px !important;
  }

  .padding-bottom-70 {
    padding-bottom: 70px !important;
  }

  .padding-bottom-60 {
    padding-bottom: 60px !important;
  }

  .padding-bottom-50 {
    padding-bottom: 50px !important;
  }

  .padding-bottom-40 {
    padding-bottom: 40px !important;
  }

  .padding-bottom-30 {
    padding-bottom: 30px !important;
  }

  .padding-bottom-20 {
    padding-bottom: 20px !important;
  }

  .padding-bottom-10 {
    padding-bottom: 10px !important;
  }

  .padding-bottom-0 {
    padding-bottom: 0px !important;
  }

  .margin-top-0 {
    margin-top: 0px !important;
  }

  .padding-left-20 {
    padding-left: 20px;
  }

  /* ========================================================== */
  /* 			               02. MENU-NAV                       */
  /* ========================================================== */

  .navbar-brand {
    font-size: 28px;
    font-weight: 600;
    color: var(--dark-main-color) !important;
  }

  .navbar {
    width: 100%;
    z-index: 9999;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0;
    padding-right: 0;

    ul {
      margin-bottom: 0px;
    }
  }

  .navbar-toggler {
    border: 1px solid var(--main-color);
  }

  .navbar-toggler-icon {
    width: 1.3em;
    height: 1.3em;
  }

  .navbar-toggler-icon i {
    color: var(--main-color);
    vertical-align: middle;
  }

  .navbar-fixed-top {
    position: absolute !important;
    background-color: #fff !important;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04) !important;
    border-bottom: none;
    transition: background-color 0.25s ease 0s;
  }

  .navbar-fixed-top.opaque {
    position: fixed !important;
    background-color: #fff !important;
    border-bottom: 1px solid #efefef;
    transition: all 0.25s ease-in-out 0s;
  }

  .navbar-fixed-top .navbar-nav > li > a,
  .navbar-fixed-top .navbar-nav > li > a:hover {
    color: #67ac5b !important;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .navbar-fixed-top .navbar-nav > li > a {
    color: #405263 !important;
    font-size: 18px !important;
    line-height: 30px !important;
    padding: 10px 20px !important;
    font-weight: 400;
  }

  .navbar-fixed-top .navbar-nav .current a {
    color: var(--main-color) !important;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .navbar-fixed-top.opaque .navbar-nav .current a {
    color: var(--main-color) !important;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .navbar-fixed-top .navbar-nav > li > a.discover-btn {
    border: 1px solid var(--main-color);
    color: #fff !important;
    background: var(--main-color);
    border-radius: 8px 8px;
    padding: 12px 18px !important;
    margin-left: 10px;
    margin-right: 15px;
  }

  .navbar-fixed-top .navbar-nav > li > a.discover-btn:hover {
    color: #67ac5b !important;
    background: transparent;
    border: 1px solid #67ac5b;
  }

  /* ========================================================== */
  /* 			                03. FOOTER                        */
  /* ========================================================== */

  .footer {
    position: relative;
    overflow: hidden;
    display: block;
    background-color: #fff;
  }

  .footer-bottom {
    padding: 60px 0;
  }

  .footer-white-box,
  .footer-grey-box,
  .footer-blue-box,
  .footer-dark-grey-box {
    padding: 55px 40px 65px 40px !important;
  }

  .footer-white-box img,
  .footer-grey-box img,
  .footer-blue-box img,
  .footer-dark-grey-box img {
    margin-bottom: 10px;
    fill: var(--main-color);
    width: 65px;
    height: 65px;
    object-fit: contain;
  }

  .footer-white-box {
    background-color: #fff;
  }

  .footer-grey-box {
    background-color: #f8f9f9;
  }

  .footer-blue-box {
    background-color: var(--main-color);
  }

  .footer-dark-grey-box {
    background-color: #f0f1f2;
  }

  .footer-blue-box h5,
  .footer-blue-box p,
  .footer-blue-box a,
  .footer-blue-box i {
    color: #fff !important;
  }

  .footer p {
    margin-bottom: 5px;
    line-height: 24px;
    color: #454545;
  }

  .footer p > a {
    font-size: 17px;
    color: #454545;
    text-decoration: underline;
  }

  a.footer-links {
    display: block;
    font-size: 17px;
    margin-bottom: 2px;
    color: #454545;
  }

  /* Footer Social Icons */
  ul.footer_social {
    width: 100%;
    max-width: 1140px;
    display: block;
    margin: 0 auto;
    padding-top: 15px;
    text-align: center;
  }

  ul.footer_social li {
    display: inline-block;
    margin: 0;
    line-height: 100% !important;
  }

  ul.footer_social li a i {
    display: inline-block;
    margin: 0 8px;
    padding: 0;
    font-size: 26px !important;
    line-height: 26px !important;
    color: var(--main-color);
    transition: all 0.5s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
  }

  ul.footer_social li a i.fa-twitter {
    color: #4099ff;
  }

  ul.footer_social li a i.fa-pinterest {
    color: #c92228;
  }

  ul.footer_social li a i.fa-facebook-square {
    color: #3b5998;
  }

  ul.footer_social li a i.fa-instagram {
    color: #8a3ab9;
  }

  ul.footer_social li a i.fa-skipe {
    color: #00aff0;
  }

  ul.footer_social li a i.fa-dribble {
    color: #ea4c89;
  }

  ul.footer_social li a i:hover {
    color: #abd3f9 !important;
  }

  /* Footer Contact */
  p.contact-info {
    margin-bottom: 8px;
  }

  p.contact-info a {
    margin-bottom: 8px;
    color: #7c8595;
  }

  p.contact-info a:hover {
    text-decoration: underline;
  }

  p.contact-info i {
    font-size: 18px;
    padding-right: 5px;
    vertical-align: middle;
    color: var(--main-color);
  }

  /* ========================================================== */
  /* 			               04. HOME                           */
  /* ========================================================== */

  .home-section {
    position: relative;
    z-index: 999;
    overflow: hidden;
    position: relative;
    padding-top: 155px;
    padding-bottom: 75px;
    background-color: #67ac5b;
  }

  .home-section h1 {
    margin-bottom: 15px;
    color: #fff;
  }
  .row .col-md-6.padding-top-60 h1 {
  }

  .home-section p {
    margin-bottom: 30px;
    max-width: 600px;
    font-size: 17px;
    line-height: 26px;
    font-weight: 400;
    color: #fff;
  }

  ul.home-benefits {
    margin: 10px 0 35px 0;
    letter-spacing: 1px;
    color: #fff;
  }

  ul.home-benefits li {
    font-size: 17px;
    line-height: 28px;
    margin-bottom: 8px;
    color: #fff;
  }

  ul.home-benefits li i {
    font-size: 26px;
    line-height: 28px;
    margin-right: 5px;
    color: #fff;
  }

  /* -----  Newsletter Hero Section ----- */

  .newsletter_hero_form_box {
    margin: 0 auto;
    padding-top: 5px;
    max-width: 730px;
  }

  #newsletter-hero-form #email_hero_newsletter {
    width: 100%;
    max-width: 340px;
    height: 59px;
    padding: 0 20px;
    border: 1px solid #fff;
    background-color: transparent;
    moz-border-radius: 30px 30px;
    -webkit-border-radius: 30px 30px;
    border-radius: 30px 30px;
    color: #fff;
    font-size: 17px;
    margin: 0 10px 20px 0;
  }

  #newsletter-hero-form #submit-button-hero-newsletter {
    width: 125px;
    height: 59px;
    background: #fff;
    border: none;
    color: var(--main-color);
    display: inline;
    margin: 0 0 0 -5px;
    moz-border-radius: 30px 30px;
    -webkit-border-radius: 30px 30px;
    border-radius: 30px 30px;
    font-size: 14px;
    font-weight: 700;
    vertical-align: top;
    cursor: pointer;
    transition: background-color 0.25s ease-in-out;
    -moz-transition: background-color 0.25s ease-in-out;
    -webkit-transition: background-color 0.25s ease-in-out;
  }

  #newsletter-hero-form #submit-button-newsletter:hover {
    cursor: pointer;
    background-color: #eaeaea;
    transition: background-color 0.25s ease-in-out;
    -moz-transition: background-color 0.25s ease-in-out;
    -webkit-transition: background-color 0.25s ease-in-out;
  }

  input#email_hero_newsletter::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #999;
  }
  input#email_hero_newsletter:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #999;
  }
  input#email_hero_newsletter::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #999;
  }
  input#email_hero_newsletter:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #999;
  }

  input#email_hero_newsletter[type="text"]::placeholder,
  input#email_hero_newsletter[type="email"]::placeholder {
    /* Firefox, Chrome, Opera */
    color: #fff !important;
  }

  input#email_hero_newsletter[type="text"]:-ms-input-placeholder,
  input#email_hero_newsletter[type="email"]:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff !important;
  }

  input#email_hero_newsletter[type="text"]::-ms-input-placeholder,
  input#email_hero_newsletter[type="email"]::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #fff !important;
  }

  .newsletter_hero_success_box {
    display: inline-block;
    color: var(--main-color) !important;
    background-color: #fff;
    border: 1px solid #dedede;
    padding: 25px 25px;
    font-size: 19px;
    text-shadow: none;
    font-style: italic;
    border-radius: 8px 8px;
    margin: 15px 0;
  }

  /* -----  Integration ----- */
  .integration-platforms {
    margin: 25px 0 0 5px;
  }

  .integration-platforms h5 {
    display: inline-block;
    margin: 10px 10px 0 0;
  }

  ul.integration-mentions {
    display: inline-block;
  }

  ul.integration-mentions li {
    display: inline-block;
    margin: 7px 15px;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }

  ul.integration-mentions li img {
    max-height: 32px;
  }
  /* ========================================================== */
  /* 			                06. FEATURES                      */
  /* ========================================================== */

  .services-wrapper {
    position: relative;
    z-index: 999;
    overflow: hidden;
  }

  .services-wrapper::before {
    background-image: radial-gradient(
      var(--light-main-color) 15%,
      transparent 25%
    );
    background-size: 20px 20px;
    position: absolute;
    content: "";
    width: 800px;
    height: 225px;
    left: 0;
    margin-left: -412px;
    top: 0;
    margin-top: 5px;
    z-index: -1;
  }

  .services-wrapper::after {
    background-image: radial-gradient(
      var(--light-main-color) 15%,
      transparent 25%
    );
    background-size: 20px 20px;
    position: absolute;
    content: "";
    width: 800px;
    height: 200px;
    left: 50%;
    margin-left: 195px;
    bottom: 0;
    margin-bottom: 50px;
    z-index: -1;
  }

  .main-services {
    position: relative;
    margin: 50px 10px 40px 10px;
    padding: 50px 15px 32px 15px;
    overflow: hidden;
    text-align: center;
    border: 1px solid var(--light-main-color);
    background-color: #fff;
    border-radius: 8px 8px;
    box-shadow: 12px 14px 0 var(--super-light-main-color);
    transition: all 1s;
  }

  .main-services:hover {
    border: 3px solid var(--light-main-color);
    transform: scale(1.025);
    transition: all 1s;
  }

  .main-services img {
    width: 80px;
    margin-bottom: 25px;
  }

  .main-services h3 {
    font-size: 26px;
    color: #292929;
    margin-bottom: 15px;
  }

  .main-services p {
    padding: 0 12px;
    margin-bottom: 25px;
  }

  blockquote {
    font-size: 16px;
    color: #4285f4;
    border-left: 2px solid #4285f4;
  }

  /* Features */
  .feature-box {
    text-align: center;
    margin: 30px 0 0 0;
    transition: all 1s;
  }

  .faq-box {
    margin: 30px 0 0 0;
    transition: all 1s;
  }

  .feature-box svg {
    margin-bottom: 10px;
    font-size: 46px;
    color: var(--main-color);
  }

  .feature-box:hover svg {
    transform: scale(1.15);
    transition: all 1s;
  }

  .feature-box h4,
  .faq-box h4 {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .faq-box h5 {
    margin-bottom: 7px;
  }

  .feature-box p,
  .faq-box p {
    color: #7c8595;
  }

  /* ========================================================== */
  /* 			               07. PRICING                        */
  /* ========================================================== */

  .pricing-wrapper {
    position: relative;
    z-index: 999;
    overflow: hidden;

    button {
      text-transform: none;
      font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol";
      font-weight: 500;
      font-size: 0.8125rem;
      line-height: 1.75;
      border-radius: 4px;
      padding: 11px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      padding-left: 24px;
      padding-right: 24px;
      height: 36px;
      font-weight: 600;
    }

    p {
      margin: 0px;
      font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 1.5;
      font-weight: 600;
      color: rgb(46, 125, 50);
      font-style: italic;
      font-size: 12px;
      position: absolute;
      right: -100px;
      top: 8px;
    }

    .row {
      margin-top: 16px;
    }

    .selected-plan {
      .price-box {
        border: 3px solid var(--main-color);
        position: relative;

        .selected-plan-header {
          top: 24px;
          left: 0;
          right: 0;
          margin: auto;
          position: absolute;
          font-size: 14px;
          color: var(--main-color);
        }
      }
    }
  }

  .pricing-wrapper::before {
    background-image: radial-gradient(
      var(--light-main-color) 15%,
      transparent 25%
    );
    background-size: 20px 20px;
    position: absolute;
    content: "";
    width: 800px;
    height: 245px;
    left: 0;
    margin-left: -412px;
    top: 0;
    margin-top: -40px;
    z-index: -1;
  }

  .pricing-wrapper::after {
    background-image: radial-gradient(
      var(--light-main-color) 15%,
      transparent 25%
    );
    background-size: 20px 20px;
    position: absolute;
    content: "";
    width: 800px;
    height: 200px;
    left: 50%;
    margin-left: 195px;
    bottom: 0;
    margin-bottom: 50px;
    z-index: -1;
  }

  .price-box {
    min-height: 550px;
    height: 550px;
    position: relative;
    margin: 40px 10px 20px 5px;
    padding: 50px 10px 32px 10px;
    overflow: hidden;
    text-align: center;
    border: 1px solid var(--light-main-color);
    background-color: #fff;
    border-radius: 8px 8px;
    box-shadow: 12px 14px 0 var(--super-light-main-color);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
  }

  .price-box.grey-price-box {
    background-color: #fcfcfc;
  }

  .price-box:hover {
    transform: scale(1.025);
    transition: all 1s;
  }

  .price-box .price-subtitle,
  .price-box .price-text {
    color: #6a6a6a;
  }

  ul.pricing-list {
    padding: 0 20px;
    min-height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .pricing-title-list {
    height: 150px;
    margin-bottom: 30px;
  }

  ul.pricing-list li.price-title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: 1px;
    color: var(--black);
  }

  ul.pricing-list li.price-value {
    font-size: 60px;
    line-height: 60px;
    display: block;
    margin-top: 20px;
    margin-bottom: 10px;
    color: var(--black);

    &.price-value-custom {
      font-size: 40px;
    }
  }

  ul.pricing-list li.price-subtitle {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    text-transform: capitalize;
    color: #6a6a6a;
  }

  ul.pricing-list li.price-text {
    display: block;
    text-align: left;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 5px;
    text-align: center;
  }

  .price-text-before {
    color: var(--main-color);
    margin-bottom: 10px;
    height: 30px;
  }

  ul.pricing-list li.price-tag a {
    color: #fff;
    background: var(--main-color);
    border: 1px solid var(--main-color);
    border-radius: 5px 5px;
    padding: 15px 30px;
    display: inline-block;
    font-size: 15px;
    line-height: 24px;
    font-weight: 600;
    margin: 30px 0 5px 0;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
  }
  ul.pricing-list li.price-tag a:hover {
    background: var(--dark-main-color);
  }

  ul.pricing-list li.price-tag-line a {
    color: var(--main-color);
    background: transparent;
    border: 1px solid var(--main-color);
    border-radius: 5px 5px;
    padding: 15px 30px;
    display: inline-block;
    font-size: 15px;
    line-height: 24px;
    font-weight: 600;
    margin: 30px 0 5px 0;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    align-self: flex-end;
  }

  ul.pricing-list li.price-tag-line a:hover {
    color: #fff;
    background: var(--dark-main-color);
    border: 1px solid var(--dark-main-color);
  }

  /* ========================================================== */
  /* 						    09. CONTACT                       */
  /* ========================================================== */

  iframe.contact-maps {
    padding-right: 20px;
  }

  p.contact-info {
    margin-bottom: 8px;
  }

  p.contact-info a {
    margin-bottom: 8px;
    color: #7c8595;
  }

  p.contact-info a:hover {
    text-decoration: underline;
  }

  p.contact-info i {
    font-size: 18px;
    padding-right: 5px;
    vertical-align: middle;
    color: var(--main-color);
  }

  #contact-form {
    margin-top: 30px;
  }

  input.contact-input {
    width: 96%;
    height: 50px;
    padding-left: 2%;
    padding-right: 2%;
    margin-bottom: 12px;
    color: #727272;
    background-color: #f6f7f8;
    border: 1px solid #ececec !important;
    font-size: 14px;
    -webkit-border-radius: 5px 5px 5px 5px;
    -moz-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
  }

  textarea {
    height: 100px;
    margin-bottom: 12px;
    width: 96%;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #727272;
    background-color: #f6f7f8;
    border: 1px solid #ececec !important;
    font-size: 14px;
    -webkit-border-radius: 5px 5px 5px 5px;
    -moz-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
  }

  input.contact-submit {
    color: #fff;
    background: var(--main-color);
    border: none;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    padding: 16px 30px;
    position: relative;
    text-transform: uppercase;
    -webkit-border-radius: 5px 5px;
    -moz-border-radius: 5px 5px;
    border-radius: 5px 5px;
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
  }

  input.contact-submit:hover {
    color: #fff;
    background: var(--dark-main-color);
  }

  input.white-input::-webkit-input-placeholder,
  textarea.white-input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #454545;
  }
  input.white-input:-moz-placeholder,
  textarea.white-input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #454545;
  }
  input.white-input::-moz-placeholder,
  textarea.white-input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #454545;
  }
  input.white-input:-ms-input-placeholder,
  textarea.white-input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #454545;
  }

  #contact-form input.contact-input:focus,
  #contact-form textarea.contact-commnent:focus {
    border-color: #fff;
    outline: none;
  }

  p.contact_success_box {
    display: inline-block;
    color: #0c75fb;
    font-size: 20px;
    font-style: italic;
  }

  /* ========================================================== */
  /* 			               10. OTHER ELEMENTS                 */
  /* ========================================================== */

  /* -----  a) Newsletter ----- */
  .newsletter_form_wrapper {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }

  .newsletter_form_box {
    margin: 0 auto;
    max-width: 730px;
  }

  #newsletter-form #email_newsletter {
    width: 100%;
    max-width: 450px;
    height: 65px;
    padding: 0 20px;
    border: 2px solid #fff;
    background-color: transparent;
    -moz-border-radius: 5px 0 0 5px;
    -webkit-border-radius: 5px 0 0 5px;
    border-radius: 5px 0 0 5px;
    color: #fff;
    font-size: 16px;
    margin: 0;
  }

  #newsletter-form #submit-button-newsletter {
    width: 165px;
    height: 65px;
    background: #fff;
    border: none;
    color: var(--main-color);
    display: inline;
    margin: 0 0 0 -5px;
    -moz-border-radius: 0 5px 5px 0;
    -webkit-border-radius: 0 5px 5px 0;
    border-radius: 0 5px 5px 0;
    font-size: 16px;
    font-weight: 700;
    vertical-align: top;
    transition: background-color 0.25s ease-in-out;
    -moz-transition: background-color 0.25s ease-in-out;
    -webkit-transition: background-color 0.25s ease-in-out;
  }

  #newsletter-form #submit-button-newsletter:hover {
    cursor: pointer;
    color: #0370ec;
    background-color: #fff;
    transition: background-color 0.25s ease-in-out;
    -moz-transition: background-color 0.25s ease-in-out;
    -webkit-transition: background-color 0.25s ease-in-out;
  }

  input#email_newsletter::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #fff;
  }
  input#email_newsletter:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #fff;
  }
  input#email_newsletter::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #fff;
  }
  input#email_newsletter:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #fff;
  }

  .newsletter_success_box {
    font-size: 18px;
    font-style: italic;
    color: #fff;
    margin: 5px 0;
  }

  /* b) Buttons */

  .btn-white {
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    color: var(--main-color);
    background-color: #ffffff;
    border: 1px solid #fff;
    box-shadow: 0px 8px 40px 0 rgba(0, 0, 0, 0.37);
    border-radius: 5px 5px;
    letter-spacing: 1px;
    margin: 10px 10px 0 10px 0;
    padding: 16px 26px;
    display: inline-block;
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
  }

  .btn-white:hover {
    box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.5);
    color: #67ac5b !important;
    background: #f9f9f9;
  }

  .btn-white-border {
    color: #fff !important;
    background: transparent;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    border: 1px solid #fff;
    border-radius: 5px 5px;
    margin: 10px 10px;
    padding: 16px 26px;
    display: inline-block;
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
  }

  .btn-white-border:hover {
    box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.5);
    color: var(--main-color) !important;
    background: #f9f9f9;
  }

  .btn-white i,
  .btn-white-border i {
    font-size: 22px;
    line-height: 22px;
    margin-right: 10px;
    vertical-align: text-top;
  }

  .btn-blue {
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    color: #fff;
    background-color: var(--main-color);
    border: 1px solid var(--main-color);
    box-shadow: 0px 8px 40px 0 rgba(0, 0, 0, 0.37);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
    letter-spacing: 1px;
    border-radius: 5px 5px;
    margin: 10px 10px 0 10px 0;
    padding: 20px 30px;
    display: inline-block;
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
  }

  .btn-blue:hover {
    box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.5);
    color: #fff !important;
    background: #3498db;
  }

  .btn-blue.small,
  .btn-blue-line.small {
    font-size: 16px;
    padding: 14px 20px;
  }

  .btn-blue-line {
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    color: var(--dark-main-color);
    background-color: #fff;
    border: 1px solid var(--main-color);
    border-radius: 5px 5px;
    margin: 20px 10px 0 10px 0;
    padding: 20px 30px;
    display: inline-block;
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
  }

  .btn-blue-line:hover {
    color: #fff !important;
    background: #3498db;
  }

  a.btn-blue:visited,
  a.btn-blue:focus {
    color: #fff !important;
  }

  /* -----  c) Gallery PopUp ----- */

  .popup-wrapper {
    display: block;
    position: relative;
    overflow: hidden;
  }

  .popup-gallery {
    display: inline-block;
    position: relative;
  }

  .first-gallery {
    margin-bottom: 15px;
  }

  .popup-gallery img {
    position: relative;
    z-index: 10;
    width: 100%;
    height: auto;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
  }

  .popup-gallery:hover img {
    opacity: 0.25;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
  }

  .popup-gallery a span.eye-wrapper,
  .popup-gallery a span.eye-wrapper2 {
    background-color: #000;
    position: absolute;
    display: block;
    overflow: hidden;
    z-index: 2;
    height: 100% !important;
    top: 0%;
    margin-top: 0px;
    left: 0%;
    right: 0%;
    font-size: 22px;
    color: #fff;
    text-align: center;
    font-weight: 300;
    opacity: 0;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
  }

  .popup-gallery-rounded a span.eye-wrapper,
  .popup-gallery-rounded a span.eye-wrapper2 {
    -webkit-border-radius: 5px 5px 5px 5px;
    -moz-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
  }

  .popup-gallery a span.eye-wrapper i.eye-icon,
  .popup-gallery a span.eye-wrapper2 i.eye-icon {
    position: absolute;
    display: inline-block;
    font-size: 36px;
    z-index: 3;
    top: 50% !important;
    margin-top: -18px !important;
    left: 0%;
    right: 0%;
  }

  .popup-gallery a:hover span {
    opacity: 1;
  }

  .popup-gallery img,
  .popup-gallery a span {
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }

  .gallery-item {
    margin: 0 15px 37px 10px;
  }

  .portfolio-box {
    text-align: center;
    background-color: #f6f7f8;
    border-bottom: 2px solid #e0e0e0;
    margin-bottom: 20px;
    padding: 1px 20px 12px 20px;
    -webkit-border-radius: 0 0 5px 5px;
    -moz-border-radius: 0 0 5px 5px;
    border-radius: 0 0 5px 5px;
  }

  .portfolio-box h3 {
    margin: 10px 0 1px 0 !important;
    line-height: 20px;
  }

  .portfolio-box h3 a {
    color: #454545;
    font-size: 18px;
    transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
  }

  .portfolio-item h3 a:hover {
    color: #3498db;
  }

  .portfolio-box p.portfolio-info {
    font-size: 15px;
    font-style: italic;
    margin-bottom: 1px;
  }

  .carousel-showcase {
    padding-right: 15px;
    padding-left: 15px;
  }

  .showcase-wrapper {
    position: relative;
    z-index: 999;
    overflow: hidden;
    padding: 25px 30px 0 30px;
  }

  .showcase-wrapper::before {
    background-image: radial-gradient(
      var(--light-main-color) 15%,
      transparent 25%
    );
    background-size: 20px 20px;
    position: absolute;
    content: "";
    width: 800px;
    height: 245px;
    left: 0;
    margin-left: -410px;
    top: 0;
    margin-top: -25px;
    z-index: -1;
  }

  .showcase-wrapper::after {
    background-image: radial-gradient(
      var(--light-main-color) 15%,
      transparent 25%
    );
    background-size: 20px 20px;
    position: absolute;
    content: "";
    width: 800px;
    height: 200px;
    left: 50%;
    margin-left: 245px;
    bottom: 0;
    margin-bottom: -25px;
    z-index: -1;
  }

  /* d) Testimonials */
  .testim-inner {
    margin-top: 10px;
    max-width: 800px;
    text-align: center;
  }

  .testim-inner img {
    max-width: 130px;
    border: 1px solid #efefef;
    border-radius: 50% 50%;
    padding: 5px 5px;
    background-color: #fff;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
    display: inline-block !important;
  }

  .testim-inner h6 {
    margin-bottom: 5px;
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
    color: var(--main-color);
  }

  .testim-inner span.job-text {
    font-size: 19px;
    font-weight: 400;
  }

  .testim-inner p {
    font-size: 20px;
    line-height: 32px;
    font-style: italic;
    margin-top: 20px;
  }

  /* ----- Testimonial Single ----- */

  p.testimonial-single {
    margin-bottom: 25px;
    font-size: 28px;
    line-height: 38px;
    font-weight: 300;
    font-style: italic;
  }

  /* e) Accordion Tabs */

  .card {
    border: none;
  }

  .card-header {
    padding: 6px 0 9px 0;
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: 1px solid #f4f5f6;
  }

  .card-header h5 .btn-link {
    padding: 0;
    font-size: 19px;
    font-weight: 600;
    color: var(--black);
    background-color: transparent;
  }

  .card-header h5 .btn-link:hover,
  .card-header h5 .btn-link:focus {
    text-decoration: none;
  }

  .card-body {
    padding: 10px 0;
    border-top: 2px solid var(--main-color);
    font-size: 17px;
    color: #7c8595;
  }

  /* f) Carousel */

  .carousel-indicators {
    bottom: -15px;
  }

  .carousel-indicators li {
    position: relative;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 25px;
    height: 7px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 5px 5px;
  }

  .carousel-indicators.testimonials-indicators {
    bottom: -40px;
  }

  .carousel-indicators.testimonials-indicators li {
    background-color: #e0e0e0;
  }

  .carousel-indicators.testimonials-indicators .active {
    background-color: var(--main-color);
  }

  .gallery-show {
    margin: 20px 0 40px 0;
    max-width: 100%;
    border-radius: 8px 8px;
    border: 8px solid rgba(255, 255, 255, 0.8);
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
  }

  /* g) Features List */

  ul.benefits {
    margin-bottom: 30px;
  }

  ul.benefits li {
    font-size: 17px;
    line-height: 26px;
    margin-bottom: 8px;
  }

  ul.benefits li i {
    font-size: 18px;
    line-height: 18px;
    margin-right: 8px;
    color: var(--main-color);
  }

  .no-padding-bottom {
    padding-bottom: 0;
  }

  /* ----- h) Dropcaps Features ----- */
  .nav-pills .nav-link {
    margin-bottom: 10px;
  }

  .nav-pills .nav-link,
  .nav-pills .show > .nav-link {
    border: none !important;
    transition: all 1s;
  }

  .nav-pills .nav-link:hover {
    color: #fff;
    background-color: #fff;
    border: none !important;
    border-radius: 8px !important;
    box-shadow: 0 12px 55px 0 rgba(0, 0, 0, 0.05) !important;
    -moz-box-shadow: 0 12px 55px 0 rgba(0, 0, 0, 0.05) !important;
    -webkit-box-shadow: 0 12px 55px 0 rgba(0, 0, 0, 0.05) !important;
    transition: all 1s;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #fff;
    border: 1px solid #f4f5f6 !important;
    border-radius: 8px !important;
    box-shadow: 0 12px 55px 0 rgba(0, 0, 0, 0.05) !important;
    -moz-box-shadow: 0 12px 55px 0 rgba(0, 0, 0, 0.05) !important;
    -webkit-box-shadow: 0 12px 55px 0 rgba(0, 0, 0, 0.05) !important;
    transition: all 1s;
  }

  .features-second {
    margin: 10px 0;
  }

  .features-second h4 {
    margin-top: 10px;
    font-size: 21px;
  }

  .features-second p {
    margin-bottom: 10px;
  }

  .dropcaps-circle {
    float: left;
    margin: 1px 25px 10px 0;
    background-color: #fcfcfc;
    border: 1px solid #eaeaea;
    width: 80px;
    height: 80px;
    text-align: center;
    vertical-align: middle;
    color: var(--dark-main-color);
    font-size: 40px;
    line-height: 80px;
    position: relative;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
  }

  /* ----- i) Partners ----- */

  .partners-paddings {
    padding: 50px 0 25px 0;
  }

  ul.partners-mentions li {
    display: inline-block;
    margin: 15px 30px;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }

  ul.partners-mentions li img {
    max-height: 62px;
    opacity: 0.4;
  }

  ul.partners-mentions li img:hover {
    opacity: 0.8;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }

  /* ========================================================== */
  /* 			             14. MEDIA QUERIES                    */
  /* ========================================================== */

  @media (max-width: 991px) {
    .navbar-fixed-top {
      background-color: #fff !important;
    }

    .navbar-fixed-top.opaque {
      padding: 0;
    }

    .navbar-fixed-top {
      border-bottom: 1px solid #efefef;
    }

    .navbar-fixed-top .navbar-nav > li.discover-link {
      margin-top: 10px;
    }

    .navbar-fixed-top .navbar-nav > li > a.discover-btn {
      padding: 8px 12px !important;
      margin-left: 20px;
      font-size: 17px !important;
    }
  }

  @media (max-width: 768px) {
    .home-section {
      padding-top: 120px;
      padding-bottom: 55px;
    }

    #newsletter-hero-form #email_hero_newsletter {
      width: 80%;
      max-width: 320px;
    }

    #newsletter-form #email_newsletter {
      width: 80%;
      max-width: 320px;
    }

    .hero-image {
      margin-top: 70px;
    }

    .padding-top-120 {
      padding-top: 30px !important;
    }
  }

  @media (max-width: 375px) {
    .navbar-toggle {
      margin-bottom: 0;
      border: 1px solid #9f9f9f !important;
    }

    #newsletter-hero-form #email_hero_newsletter {
      width: 64%;
      max-width: 230px;
      padding: 0 7px;
      margin: 0 5px 20px 0;
      -moz-border-radius: 5px 5px 5px 5px !important;
      -webkit-border-radius: 5px 5px 5px 5px !important;
      border-radius: 5px 5px 5px 5px !important;
    }

    #newsletter-hero-form #submit-button-hero-newsletter {
      display: inline-block;
      width: 115px;
      -moz-border-radius: 5px 5px 5px 5px !important;
      -webkit-border-radius: 5px 5px 5px 5px !important;
      border-radius: 5px 5px 5px 5px !important;
    }

    #newsletter-section .padding-top-60 {
      padding-top: 0 !important;
    }

    #newsletter-section h3 {
      font-size: 24px !important;
    }

    #newsletter-form #email_newsletter {
      -moz-border-radius: 5px 5px 5px 5px !important;
      -webkit-border-radius: 5px 5px 5px 5px !important;
      border-radius: 5px 5px 5px 5px !important;
    }

    #newsletter-form #submit-button-newsletter {
      display: inline-block;
      margin-top: 10px !important;
      -moz-border-radius: 5px 5px 5px 5px !important;
      -webkit-border-radius: 5px 5px 5px 5px !important;
      border-radius: 5px 5px 5px 5px !important;
    }

    #newsletter-section .padding-top-60 {
      padding-top: 0 !important;
    }

    #newsletter-section h3 {
      font-size: 24px !important;
    }

    .home-section h1 {
      font-size: 38px;
      line-height: 44px;
    }

    .feature-box {
      text-align: center;
    }

    .feature-box i {
      display: inline-block;
      float: none;
      margin-bottom: 25px;
    }

    ul.partners-mentions li img {
      max-height: 36px;
    }

    .section-white {
      padding: 60px 0 70px 0 !important;
    }

    .section-white.small-padding-bottom,
    .section-grey.small-padding-bottom {
      padding: 60px 0 50px 0 !important;
    }

    .margin-left-20 {
      margin-left: 1px !important;
    }

    .register-form-terms {
      padding-bottom: 30px;
    }

    ul.integration-mentions li {
      display: inline-block;
      margin: 10px 7px;
      -webkit-transition: all 0.25s ease-in-out;
      -moz-transition: all 0.25s ease-in-out;
      -ms-transition: all 0.25s ease-in-out;
      -o-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out;
    }

    ul.integration-mentions li img {
      max-height: 30px;
    }

    .services-wrapper {
      margin-top: 30px;
    }

    .small-col-inside {
      padding-right: 25px;
      padding-left: 25px;
    }

    .footer-white-box {
      border-top: 1px solid #dedede;
    }

    iframe.contact-maps {
      padding-right: 1px;
    }
  }

  @media (max-width: 320px) {
  }
`;
