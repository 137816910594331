import axios from "axios";

const axiosInstance = axios.create();

function getValue(keyName: string) {
  return window.localStorage.getItem(keyName);
}

axiosInstance.interceptors.request.use((request) => {
  request.headers["SERVER-SDK-KEY"] = getValue("serverSdkKey");
  request.headers["SIGNING-TOKEN"] = getValue("signingToken");
  request.headers["CUSTOMER-ID"] = getValue("customerId");
  return request;
});

export default axiosInstance;
